import { FunctionComponent, ReactNode } from "react";

import { makeStyles } from "@mui/styles";

import { View } from "./View";

type Props = {
  children: ReactNode;
};

const Screen: FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      {/* <StatusBar barStyle="dark-content" /> */}
      <View className={classes.container}>{children}</View>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    backgroundColor: "whitesmoke",
  },
});

export { Screen };
