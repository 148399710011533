import { useContext } from "react";
import { ReactFCC } from "types/react";
import { UserContext } from "modules/context";
import { Loading } from "views/auth";

const RestoringSession: ReactFCC<{}> = ({ children }) => {
  const { isIndeterminate } = useContext(UserContext);

  // TODO: Create a global progress overlay (discuss during PR)
  return (
    <>{isIndeterminate ? <Loading identifier="loading-user" /> : children}</>
  );
};

export { RestoringSession };
