import { FC, PropsWithChildren, useContext } from "react";
import { RouteComponentProps } from "react-router";

import Paper from "@mui/material/Paper";

import {
  View,
  Screen,
  withAuthenticatedUser,
  WithAuthenticatedUserProps,
} from "components/common";
import { BottomNavigation } from "views/BottomNavigation";
import { StatusBarContext } from "components/common/StatusBar";

const AuthLayoutComponent: FC<
  PropsWithChildren<RouteComponentProps & WithAuthenticatedUserProps>
> = ({ children, location }) => {
  const { color } = useContext(StatusBarContext);
  return (
    <View
      style={{
        paddingTop: "env(safe-area-inset-top)",
        backgroundColor: color,
      }}
      sx={{ height: "100vh", paddingBottom: 7 }}
    >
      <Screen>{children}</Screen>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation location={location} />
      </Paper>
    </View>
  );
};

export const AuthLayout = withAuthenticatedUser(AuthLayoutComponent);
