import { CapacitorHttp } from "@capacitor/core";
import {
  PostRideProgressRequest,
  PostRideProgressResponse,
} from "poola-commons";
import { PUBLIC_URL } from "modules/config";

/* HTTP requests initiated within the WebView are not sent reliably in the background.
To fix this, we use a native HTTP plugin. */
export const postRideProgress = (
  progress: PostRideProgressRequest
): Promise<{ data: PostRideProgressResponse }> =>
  CapacitorHttp.request({
    url: `${PUBLIC_URL}/progress`,
    data: JSON.stringify(progress),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
