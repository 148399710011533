import { ComponentProps } from "react";

import { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

import { useStateDelayed } from "modules/hooks";
import { Autocomplete } from "components/community/Common";
import { TextInput } from "./TextInput";
import { Colors, Inputs, Spacing, Typography } from "modules/styles";

type Option = {
  value: string;
  inputValue: string;
};

const filter = createFilterOptions<Option>();

type Props = {
  options: string[];
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  color?: string;
} & Omit<
  ComponentProps<typeof Autocomplete<Option, undefined, undefined, true>>,
  "onChange" | "renderInput" | "options"
> & { placeholder?: string };

const CreatableAutocomplete = ({
  options,
  value,
  onChange,
  placeholder,
  loading,
  color = Colors.accent,
  ...props
}: Props) => {
  const parsedOptions: Option[] = options.sort().map((option) => ({
    value: option,
    inputValue: option,
  }));
  const [select, setSelect] = useStateDelayed(false);

  return (
    <Autocomplete
      size="small"
      open={select}
      onOpen={setSelect(true)}
      onClose={setSelect(false)}
      value={value}
      options={parsedOptions}
      clearOnBlur
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      loading={loading}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          onChange(newValue.inputValue);
        } else {
          onChange("");
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.value
        );
        if (inputValue !== "" && !isExisting)
          filtered.push({
            value: `Add "${inputValue}"`,
            inputValue,
          });

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.value;
      }}
      renderOption={(props, option) => (
        <li {...props} style={Typography.regular}>
          {option.value}
        </li>
      )}
      renderInput={({ size, ...params }) => (
        <div ref={params.InputProps.ref} style={{ position: "relative" }}>
          <TextInput
            {...params.inputProps}
            placeholder={placeholder}
            borderColor={color}
            style={{ ...Inputs.light, width: "100%" }}
          />
          {loading && (
            <SpinnerContainer color={color}>
              <CircularProgress color="inherit" size={20} />
            </SpinnerContainer>
          )}
        </div>
      )}
      {...props}
    />
  );
};

const SpinnerContainer = styled(Box)({
  display: "flex",
  position: "absolute",
  top: "50%",
  right: `${Spacing.medium}px`,
  transform: "translateY(-50%)",
});

export { CreatableAutocomplete };
