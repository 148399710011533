import { useEffect, useState } from "react";
import { Observable, Subscription } from "rxjs";

export const useSubscription = (): Subscription => {
  const subscription = new Subscription();

  useEffect(() => {
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return subscription;
};

export const useObservable = <T>(
  observable: Observable<T>,
  key: unknown[] | undefined
): T | undefined => {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    const subscription = observable.subscribe((value) => {
      setValue(value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, key);

  return value;
};
