import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  Column,
  useGlobalFilter,
} from "react-table";
import { useTranslation } from "react-i18next";

import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import omit from "lodash/omit";
import keys from "lodash/keys";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import { TableProps } from "@mui/material/Table";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import IconButton from "@mui/material/IconButton";

import { Map } from "poola-commons/types";

import {
  TableCell,
  TableHead,
  Table,
  TablePagination,
  BaseTableTrans,
  AbsoluteProgress,
  EmptyRow,
  FillMissingRows,
  RelativeTableBox,
  StateTableView,
  TableContainer,
} from "components/common/table/TableComponents";
import { EnhancedHeader, EnhancedHeaderCSV } from "./EnhancedHeader";
import { MemoizedEnhancedRow, TableCellArrow } from "./EnhancedRow";

export type EnhancedTableTrans = BaseTableTrans & {
  labelNoRecordsFound?: string;
};

type EnhancedTableProps<MainData, SubData> = {
  columns: Column[];
  subColumns: Column[];
  data: EnhancedTableData<MainData, SubData>[];
  composeSubData: (subData: SubData) => object[];
  hideSubheader?: boolean;
  headerActions?: React.ReactNode[];
  csv?: EnhancedHeaderCSV;
  isLoading?: boolean;
  size?: TableProps["size"];
  fixHeight?: boolean;
  autoResetSortBy?: boolean;
  scrollable?: boolean;
  autoResetPage?: boolean;
  filter?: boolean;
  // defines max amount filtered records to expand them all
  expandOnRowsCount?: number;
  tableLayout?: React.CSSProperties["tableLayout"];
  translations?: EnhancedTableTrans;
};

type EnhancedTableData<MainData, SubData> = MainData & {
  subData: SubData;
};

const EnhancedTable = <MainData, SubData>({
  columns,
  subColumns,
  data,
  composeSubData,
  csv,
  hideSubheader = false,
  size = "medium",
  fixHeight = true,
  isLoading = false,
  autoResetSortBy = true,
  scrollable = false,
  autoResetPage = true,
  filter = true,
  expandOnRowsCount,
  headerActions,
  translations,
}: EnhancedTableProps<MainData, SubData>) => {
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "component",
  });

  const defaultEnhancedTableTrans: EnhancedTableTrans = translate("table", {
    returnObjects: true,
  });

  const t = { ...defaultEnhancedTableTrans, ...translations };

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy,
      autoResetPage,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [openRows, setOpenRows] = React.useState<{
    [rowIndex in number | string]: true;
  }>({});
  const [expandAll, setExpandAll] = React.useState(false);

  React.useEffect(() => {
    if (page.length <= (expandOnRowsCount || 0) || expandAll) {
      setOpenRows(page.reduce((acc, row) => ({ ...acc, [row.id]: true }), {}));
    }
  }, [page.length, pageIndex]);

  React.useEffect(() => {
    if (isEmpty(openRows) || page.length !== values(openRows).length) {
      setExpandAll(false);
    } else if (page.length === values(openRows).length) {
      setExpandAll(true);
    }
  }, [keys(openRows), pageIndex]);

  const toggleExpand = (rowId: string) => {
    setOpenRows((prevRows) => {
      return prevRows[rowId]
        ? omit(prevRows, rowId)
        : {
            ...prevRows,
            [rowId]: true,
          };
    });
  };

  const toggleExpandAll = () => {
    setExpandAll((prevState) => !prevState);
    expandAll
      ? setOpenRows({})
      : setOpenRows(
          page.reduce((acc, row) => {
            acc[row.id] = true;
            return acc;
          }, {} as Map<true>)
        );
  };

  const stateTableText = isLoading
    ? t.labelDataLoading
    : isEmpty(data)
    ? t.labelNoData
    : isEmpty(page)
    ? t.labelNoRecordsFound
    : null;

  return (
    <RelativeTableBox scrollable={scrollable}>
      {isLoading && <AbsoluteProgress />}
      <EnhancedHeader
        filterProps={
          filter
            ? {
                globalFilter,
                setGlobalFilter,
                filterPlaceholder: t.filterPlaceholder,
              }
            : undefined
        }
        csv={csv}
        actions={headerActions}
      />
      <TableContainer scrollable={scrollable}>
        <Table {...getTableProps()} size={size} stickyHeader={scrollable}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCellArrow>
                  {!isEmpty(page) && (
                    <IconButton size="small" onClick={toggleExpandAll}>
                      {expandAll ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                  )}
                </TableCellArrow>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(
                        column.canSort
                          ? column.getSortByToggleProps()
                          : undefined
                      )}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <MemoizedEnhancedRow<SubData>
                  key={row.id}
                  row={row}
                  expand={openRows[row.id]}
                  columns={subColumns}
                  data={
                    (row.original as EnhancedTableData<MainData, SubData>)
                      .subData
                  }
                  composeData={composeSubData}
                  onExpand={toggleExpand}
                  hideSubheader={hideSubheader}
                />
              );
            })}
            {fixHeight && (
              <FillMissingRows
                rowsCount={pageSize - page.length}
                EmptyRow={EmptyRow}
              />
            )}
          </TableBody>
        </Table>
        {!!stateTableText && (
          <StateTableView stateText={stateTableText} isOverlay={fixHeight} />
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          {
            label: t.allRowsPerPageOption!,
            value: data.length,
          },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        SelectProps={{
          native: true,
        }}
        labelRowsPerPage={t.labelRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          translate("table.labelDisplayedRows", { from, to, count })
        }
        getItemAriaLabel={() => ""}
        onPageChange={(_event, pageNumber) => gotoPage(pageNumber)}
        onRowsPerPageChange={({ target: { value } }) => {
          setPageSize(Number(value));
        }}
      />
    </RelativeTableBox>
  );
};

export { EnhancedTable, type EnhancedTableData };
