import React from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

import { is } from "poola-commons/utils";

import { extract12FormatAndTime } from "modules/date";
import { Colors, Typography } from "modules/styles";
import { Text } from "./Text";
import { rowGap } from "modules/styles/helper";

type Props = {
  Icon?: React.ReactElement;
  time?: number;
  fontSize?: number;
  bold?: boolean;
};

export const TimeLabel: React.FC<Props> = ({
  Icon,
  time,
  fontSize = 14,
  bold = true,
}) => {
  const [time12Format, hourFormat] = is(time)
    ? extract12FormatAndTime(time)
    : ["--:--", ""];
  return (
    <Box
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      sx={rowGap(4)}
    >
      {Icon && <IconWrapper fontSize={fontSize}>{Icon}</IconWrapper>}
      <Stack
        flexDirection="row"
        alignItems="baseline"
        sx={rowGap(2)}
        whiteSpace="nowrap"
      >
        <Text
          sx={{
            fontSize: fontSize,
            ...(bold ? Typography.bold : Typography.regular),
          }}
        >
          {time12Format}
        </Text>
        <Text
          sx={{
            fontSize: fontSize - 4,
            ...(bold ? Typography.bold : Typography.regular),
          }}
        >
          {hourFormat}
        </Text>
      </Stack>
    </Box>
  );
};

const IconWrapper = styled("div")<Pick<Props, "fontSize">>(({ fontSize }) => ({
  lineHeight: "100%",
  svg: {
    color: Colors.primaryLight40,
    width: fontSize,
    height: fontSize,
  },
}));
