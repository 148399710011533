import React, { CSSProperties, FunctionComponent, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

import {
  Colors,
  Containers,
  Dimens,
  Inputs,
  Spacing,
} from "modules/styles";
import { TextInput } from "./TextInput";
import { View } from "./View";
import { Touchable } from "./Touchable";

type Props = {
  placeholder: string;
  style?: CSSProperties;
  onSearch: (query: string) => void;
  onQueryChanged?: (query: string) => void;
};

const SearchBar: FunctionComponent<Props> = ({
  placeholder,
  style,
  onSearch,
  onQueryChanged,
}) => {
  const [query, setQuery] = useState("");

  const _search = () => onSearch(query);

  const classes = useClasses();

  return (
    <View className={classes.container} style={style}>
      <TextInput
        placeholder={placeholder}
        value={query}
        // returnKeyType="search"
        onChangeText={(query) => {
          setQuery(query);

          if (onQueryChanged) {
            onQueryChanged(query);
          }
        }}
        onSubmitEditing={_search}
        className={classes.input}
      />

      <Touchable className={classes.icon} onClick={_search}>
        <SearchIcon sx={{ color: Colors.primary }} />
      </Touchable>
    </View>
  );
};

const useClasses = makeStyles({
  container: {
    ...Containers.shadowed,
    backgroundColor: Colors.primaryNegative,
    paddingLeft: Spacing.small,
    paddingRight: Spacing.small,
    borderRadius: Dimens.small,
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },
  input: {
    ...Inputs.base,
    width: "90%",
  },
  icon: {
    width: Dimens.extraLarge,
    alignItems: "center",
    justifyContent: "center",
  },
});

export { SearchBar };
