// https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api

declare global {
  interface Window {
    clarity?: Function;
  }
}

const CLARITY_ID = "g4aktlarhw";

const clarityInit = (id: string) => {
  (function (c: any, l: any, a: any, r: any, i: any, t?: any, y?: any) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", id);
};

function clarity(...params: any[]) {
  if (!window.clarity) {
    throw new Error("Clarity is not initialized");
  }

  window.clarity(...params);
}

const init = () => {
  clarityInit(CLARITY_ID);
};

const hasStarted = () => {
  return typeof window.clarity === "function";
};

/**
 * https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api#customize-your-identifiers
 */
const customizeIdentifier = (
  userId: string,
  sessionId?: string,
  pageId?: string
) => {
  clarity("identify", userId, sessionId, pageId);
};

/**
 * https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api#prioritize-specific-sessions-for-recording
 */
const prioritizeSessionForRecording = (reason: string) => {
  clarity("upgrade", reason);
};

/**
 * https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api#add-custom-tags
 */
const addCustomTag = (key: string, value: string | string[]) => {
  clarity("set", key, value);
};

export const Clarity = {
  init,
  hasStarted,
  customizeIdentifier,
  prioritizeSessionForRecording,
  addCustomTag,
};
