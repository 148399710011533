import React, { ComponentProps, KeyboardEvent } from "react";

import { Colors, Helper } from "modules/styles";
import { styled, SxProps, Theme } from "@mui/material/styles";

type InputProps = ComponentProps<typeof StyledTextInput>;

type TextInputProps = InputProps & {
  sx?: SxProps<Theme>;
  shouldBlurOnEnter?: boolean;
  onChangeText?: (text: string) => void;
  onSubmitEditing?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      onChange,
      onChangeText,
      onSubmitEditing,
      shouldBlurOnEnter = true,
      ...props
    },
    ref
  ) => {
    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onSubmitEditing) {
        onSubmitEditing(event);
        shouldBlurOnEnter && event.currentTarget.blur();
      }
    };

    return (
      <StyledTextInput
        ref={ref}
        onChange={(e) => {
          onChangeText && onChangeText(e.target.value);
          onChange && onChange(e);
        }}
        onKeyUp={handleKeyPress}
        {...props}
      />
    );
  }
);

type Props = {
  borderColor?: string;
};

const StyledTextInput = styled(
  "input",
  Helper.shouldForwardProp<Props>(["borderColor"])
)<Props>(({ borderColor = Colors.accent, disabled }) => ({
  appearance: "none",
  backgroundColor: "rgba(0, 0, 0, 0)",
  borderRadius: "0px",
  border: "none",
  boxSizing: "border-box",
  margin: "0px",
  padding: "0px",
  resize: "none",
  outline: "0px solid", // Remove default focus outline on safari that is displayed incorrectly
  ":focus": {
    boxShadow: `0 0 0 2px inset ${borderColor}, 0 0 0 1px #fff`, // Simulate outline with box-shadow: ;
  },
  ...(disabled && {
    "::placeholder": {
      opacity: 0.5,
    },
  }),
}));

export { TextInput, type TextInputProps };
