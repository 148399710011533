import { CSSProperties, forwardRef } from "react";

import { styled } from "@mui/material/styles";
import MuiCheckbox, {
  CheckboxProps,
  checkboxClasses,
} from "@mui/material/Checkbox";

type Props = {
  htmlColor: CSSProperties["color"];
};

const CheckboxWrapper = forwardRef<HTMLButtonElement, Props & CheckboxProps>(
  function ViewWrapper({ htmlColor, ...other }: Props, ref) {
    return <MuiCheckbox {...other} ref={ref} />;
  }
);

export const Checkbox = styled(CheckboxWrapper)<Props>(({ htmlColor }) => ({
  [`&.${checkboxClasses.checked}`]: {
    color: htmlColor,
  },
}));
