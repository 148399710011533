export const extraSmall = 4;

export const small = 8;

export const medium = 16;

export const large = 24;

export const extraLarge = 32;

export const huge = 48;

export const extraHuge = 64;
