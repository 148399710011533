import React, { Ref } from "react";
import isEmpty from "lodash/isEmpty";

import { styled } from "@mui/material/styles";

import { View } from "./View";

type Props<T> = {
  data: T[];
  contentContainerStyle?: string;
  ListEmptyComponent?: React.FC;
  ItemSeparatorComponent?: React.FC;
  renderItem: (entry: { item: T }) => React.ReactNode;
  keyExtractor: (t: T, index: number) => string;
  scrollRef?: Ref<HTMLDivElement>;
};

const FlatList = <T extends unknown>({
  data,
  contentContainerStyle,
  ListEmptyComponent = () => null,
  ItemSeparatorComponent = () => null,
  renderItem,
  keyExtractor,
  scrollRef,
}: Props<T>) => {
  return (
    <FlatListContainer className={contentContainerStyle} ref={scrollRef}>
      {isEmpty(data) ? (
        <ListEmptyComponent />
      ) : (
        data.map((item, index) => (
          <View key={keyExtractor(item, index)}>
            {renderItem({ item })}
            {index < data.length - 1 && <ItemSeparatorComponent />}
          </View>
        ))
      )}
    </FlatListContainer>
  );
};

const FlatListContainer = styled(View)({
  transform: "translateZ(0px)",
  overflowY: "auto",
  flexShrink: 1,
  flexGrow: 1,
  flexDirection: "column",
  overflowX: "hidden",
});

export { FlatList };
