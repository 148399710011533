import {
  CSSProperties,
  FunctionComponent,
  PropsWithChildren,
  useMemo,
} from "react";
import { renderToString } from "react-dom/server";

import clx from "classnames";

import { Marker, MarkerProps, Popup } from "react-leaflet";
import Leaflet from "leaflet";

import { makeStyles } from "@mui/styles";

import { Colors, Containers, Dimens } from "modules/styles";
import { View } from "components/common";

export enum Animation {
  NONE = "none",
  PULSATE = "pulsate",
}

type Props = {
  style?: CSSProperties;
  popup?: React.ReactNode;
  disabled?: boolean;
  animation?: Animation;
};

const getIcon = ({
  style,
  children,
  className,
}: PropsWithChildren<Props & { className: string }>) =>
  Leaflet.divIcon({
    html: renderToString(
      <View className={className} style={style}>
        {children}
      </View>
    ),
  });

const PinMarker: FunctionComponent<PropsWithChildren<Props & MarkerProps>> = ({
  children,
  style,
  popup,
  disabled,
  animation = Animation.NONE,
  ...otherProps
}) => {
  const classes = useClasses();
  const icon = useMemo(
    () =>
      getIcon({
        style,
        children,
        className: clx(classes.container, {
          [classes.disabled]: disabled,
          [classes.pulsate]: animation === Animation.PULSATE,
        }),
      }),
    [classes.container]
  );
  return (
    <Marker icon={icon} {...otherProps}>
      {popup && <Popup>{popup}</Popup>}
    </Marker>
  );
};

const useClasses = makeStyles({
  container: {
    ...Containers.shadowed,
    borderColor: Colors.primaryLight,
    borderWidth: 2,
    backgroundColor: Colors.primaryNegative,
    width: Dimens.extraLarge,
    height: Dimens.extraLarge,
    borderRadius: Dimens.medium,
    borderBottomLeftRadius: Dimens.extraSmall,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-50%",
    left: "50%",
    transform: "translate(0, -50%)",
    margin: "-2px",
  },
  disabled: {
    opacity: 0.5,
  },
  pulsate: {
    animation: "$pulsate 2s ease-out infinite",
  },

  "@keyframes pulsate": {
    "0%": { transform: "scale(0.9)" },
    "25%": { transform: "scale(1.1)" },
    "60%": { transform: "scale(0.9)" },
    "100%": { transform: "scale(0.9)" },
  },
});

export { PinMarker };
