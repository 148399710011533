const API_URL = process.env.REACT_APP_API_URL as string;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_API_URL as string;
const POOLA_WEB_URL = process.env.REACT_APP_POOLA_WEB_URL as string;
const AMPLITUDE_API_KEY = "1425e834424f928f1e186e380ddb671d";
const SERVICES_DELAY = 0;

export {
  API_URL,
  PUBLIC_URL,
  AMPLITUDE_API_KEY,
  POOLA_WEB_URL,
  SERVICES_DELAY,
};
