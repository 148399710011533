import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { RouteComponentProps } from "react-router";
import { Colors } from "modules/styles";
import { onboardingRoutes } from "views/routesMap";
import { FlatHeader, View } from "components/common";

export const OnboardingLayout: FC<PropsWithChildren & RouteComponentProps> = ({
  children,
  location,
  history,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "view.onboarding" });
  return (
    <View sx={{ height: "100vh" }}>
      {location.pathname !== onboardingRoutes.WELCOME && (
        <FlatHeader
          backRouteIconColor={Colors.primaryNegative}
          center
          title={t("header")}
          sx={{
            backgroundColor: Colors.accent,
            color: Colors.primaryNegative,
            borderBottom: `none`,
            zIndex: 1,
          }}
          onClick={() => {
            history.goBack();
          }}
        />
      )}
      {children}
    </View>
  );
};
