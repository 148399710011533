import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Capacitor } from "@capacitor/core";

import { rgbToHex } from "@mui/system/colorManipulator";

import { Alert, Screen } from "components/common";
import { SelectSite } from "components/auth";
import { useAsyncPromise } from "modules/hooks";
import { NoAuthLayout } from "components/layout";
import { Loading } from "./AuthLoadingConnected";
import { reQuerySite } from "services/auth/site/service";
import { getStoredSite } from "services/auth/site";
import { authRoutes } from "views/routesMap";

import { updateStatusBar } from "capacitor/StatusBar";
import { Colors } from "modules/styles";

const SelectSiteView: FunctionComponent<{ autoRedirect: boolean }> = ({
  autoRedirect,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(undefined, {
    keyPrefix: "view.auth.select-site",
  });

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      updateStatusBar(rgbToHex(Colors.primary));
    }
  }, []);

  const [alertOpen, setAlertOpen] = useState(false);

  const [loading, error, _logIn] = useAsyncPromise(
    ({ site }: { site: string }) => {
      return reQuerySite(site)
        .then((site) => {
          window.location.href = site.path;
        })
        .catch(() => {
          enqueueSnackbar(t("snackbar-error"), { variant: "error" });
        });
    }
  );

  const site = getStoredSite();

  useEffect(() => {
    if (site && autoRedirect) {
      window.location.href = site!.path;
    }
  }, [site?.name, site?.path, autoRedirect]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleCreateSite = async () => {
    const site = await reQuerySite("carpool");
    window.location.href = `/${site.path}${authRoutes.REGISTER}`;
  };

  return (
    <>
      {site && autoRedirect ? (
        <Loading identifier="loading-site-id" />
      ) : (
        <NoAuthLayout>
          <Screen>
            <SelectSite
              loading={loading}
              error={error}
              onSiteSelect={_logIn}
              onForgotPassword={() => {
                setAlertOpen(true);
              }}
              onCreateSite={handleCreateSite}
            />
            <Alert
              open={alertOpen}
              title={t("forgot-site-alert-title")}
              text={t("forgot-site-alert-text")}
              buttons={[
                {
                  text: t("forgot-site-alert-ok"),
                  onClick: handleCloseAlert,
                },
              ]}
              handleClose={handleCloseAlert}
            />
          </Screen>
        </NoAuthLayout>
      )}
    </>
  );
};

export { SelectSiteView };
