import { FunctionComponent, useEffect } from "react";

import { useSnackbar } from "notistack";

import { useTranslation } from "react-i18next";

type ApiError = {
  status: number;
  code: string;
  message: string;
};

type Message = string | ApiError;
type Seconds = number;
type Indefinite = -1;

type Props = {
  message?: Message;
  timeout?: Seconds | Indefinite;
};

const Error: FunctionComponent<Props> = ({ message, timeout = 5 }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "view.auth.session" });
  const { enqueueSnackbar } = useSnackbar();

  const _toUserMessage = (message: string | ApiError): string => {
    if (typeof message === "string") {
      return message;
    } else if (typeof message === "object" && message.code) {
      return t(message.code);
    } else if (message instanceof TypeError) {
      if (message.message === "Network request failed") {
        return t("network-request-failed");
      }
    }

    console.warn("Non recognised type of error message: ", message);

    return t("something-went-wrong");
  };

  useEffect(() => {
    if (message) {
      enqueueSnackbar(_toUserMessage(message), { autoHideDuration: 2000 });
    }
  }, [message]);

  // const [counter, setCounter] = useState<number>(timeout);

  // useEffect(() => {
  //   let timer: any | null;

  //   if (message) {
  //     if (counter > 0) {
  //       timer = setInterval(
  //         () => setCounter(counter - 1),
  //         1000
  //       );
  //     } else if (counter === 0) {
  //       fadeOut();
  //     }
  //   }

  //   return () => {
  //     if (timer) {
  //       clearInterval(timer);
  //     }
  //   }
  // }, [message, counter]);

  // useEffect(
  //   () => {
  //     setCounter(timeout); // reset timeout on message change

  //     fadeIn();
  //   },
  //   [message]
  // );

  // if (!message || counter < 0) {
  //   return null;
  // }

  return null;
};

// const styles = StyleSheet.create({
//   container: {
//     flexDirection: "row",
//     alignItems: "center",
//     backgroundColor: Colors.error,
//     marginTop: Spacing.medium,
//     padding: Spacing.small,
//     paddingHorizontal: Spacing.large,
//     borderRadius: Dimens.large,
//     borderTopLeftRadius: 0,
//     opacity: 0.8,
//     alignSelf: "center",
//   },
//   message: {
//     ...Typography.error,
//     ...Typography.center,
//     fontSize: Typography.sizeSmallMedium,
//     color: Colors.primaryNegative,
//     marginStart: Spacing.medium,
//   },
// });

export { Error };
