import reduce from 'lodash/reduce';

import { Dictionary } from 'd';

export * from './uuid';

export const isEmpty = (text?: string | number): boolean =>
  text === null || text === undefined || `${text}`.trim().length === 0;

export const isNotEmpty = (text?: string | number): boolean =>
  !isEmpty(text);

/*
* Flattens object to make it GA compatibile:
*
* Input:
* {
*   screen: {
*     name: 'dupa',
*     flow: 'onboarding'
*   },
*   meta: {
*     ride: {
*       from: 'krakow',
*       to: 'lodz'
*     }
*   }
* }
*
* Output:
* {
*   screen_name: 'dupa',
*   screen_flow: 'onboarding',
*   meta_ride_from: 'krakow',
*   meta_ride_to: 'lodz'
* }
*/
export const flatten = (params: any, keyPrefix: string = '', flattened: Dictionary = {}): Dictionary =>
  reduce(
    params,
    (acc, value, key) => {
      const prefixedKey = (keyPrefix.length > 0) ? `${keyPrefix}_${key}` : key;

      if (typeof value === 'string' || typeof value === 'number') {
        acc[prefixedKey] = value;
      } else if (typeof value === 'boolean') {
        acc[prefixedKey] = value ? 'true' : 'false';
      } else if (typeof value === 'object') {
        flatten(value, prefixedKey, acc);
      }

      return acc;
    },
    flattened
  );

export type ValueOf<T> = T[keyof T];
