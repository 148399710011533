import { FC } from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import BottomNavigationMui from "@mui/material/BottomNavigation";
import BottomNavigationActionMui, {
  bottomNavigationActionClasses,
} from "@mui/material/BottomNavigationAction";

import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { styled } from "@mui/material/styles";

import { Colors } from "modules/styles";
import { routesMap } from "./routesMap";

export const BottomNavigation: FC<Pick<RouteComponentProps, "location">> = ({
  location,
}) => {
  return (
    <BottomNavigationMui value={"/" + location.pathname.split("/")[1]}>
      <BottomNavigationAction
        component={Link}
        to={routesMap.DAYS_LIST}
        value={routesMap.DAYS_LIST}
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to={routesMap.REPORTS}
        value={routesMap.REPORTS}
        icon={<AssessmentIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to={routesMap.COMMUNITY}
        value={routesMap.COMMUNITY}
        icon={<GroupsIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to={routesMap.PROFILE}
        value={routesMap.PROFILE}
        icon={<PersonIcon />}
      />
    </BottomNavigationMui>
  );
};

const BottomNavigationAction = styled(BottomNavigationActionMui<typeof Link>)({
  color: Colors.primaryLight40,
  [`&.${bottomNavigationActionClasses.selected}`]: {
    color: Colors.primary,
  },
});
