import { Observable, combineLatest, Subject, BehaviorSubject } from "rxjs";
import { map, scan, switchMap } from "rxjs/operators";

import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import values from "lodash/values";

import {
  Map,
  CreateRideMessageRequest,
  CreateRideMessageResponse,
} from "poola-commons/types";
import { getChannelIdentifier } from "poola-commons/utils";

import { Message, MessageStatus } from "d";
import { post, put } from "services/api";
import { nowMillis } from "modules/date";
import { uuid } from "modules/utils";
import firebase, {
  chatLastReadPath,
  chatMessagesPath,
  chatMetaPath,
  chatPath,
  mapDbChat,
  observeVal,
} from "poola-commons/db";
import { DbChat, DbMessage } from "poola-commons/types/messages/internal";

type LocalMessage = Message & {
  localId: string;
};

let localMessages$: Subject<LocalMessage | undefined>;

export const unreadMessagesCount$ = (
  ride: string,
  uid: string
): Observable<number> => {
  const ref = firebase.ref();

  return observeVal<number>(ref.child(`${chatLastReadPath(ride)}/${uid}`)).pipe(
    switchMap((lastRead) =>
      observeVal<DbMessage[]>(
        ref
          .child(chatMessagesPath(ride))
          .orderByChild("createdAt")
          .startAt(lastRead ?? 0)
      ).pipe(
        map((items) =>
          reduce(items, (acc, item) => (item.author === uid ? acc : acc + 1), 0)
        )
      )
    )
  );
};

export const readersData$ = (ride: string) =>
  observeVal<DbChat["read"]>(firebase.ref().child(chatLastReadPath(ride)));

export const chatMeta$ = (ride: string) =>
  observeVal<DbChat["meta"]>(firebase.ref().child(chatMetaPath(ride)));

export const messages$ = (ride: string) => {
  const messagesRef = firebase.ref().child(chatMessagesPath(ride));
  return combineLatest([
    chatMeta$(ride),
    observeVal<DbChat["items"]>(messagesRef),
  ]).pipe(
    map(([meta, items]) => mapDbChat({ meta, items })),
    map((messages) => sortBy(messages, "createdAt"))
  );
};

export const rideMessages$ = (ride: string): Observable<Message[]> => {
  localMessages$ = new BehaviorSubject<LocalMessage | undefined>(undefined);

  const { uid: driverUid } = getChannelIdentifier(ride);

  const remote$: Observable<Message[]> = messages$(ride).pipe(
    map((messages) =>
      messages.map((message) => {
        const isDriver = driverUid === message.author.uid;
        const messageCopy: Message = {
          ...message,
          author: { ...message.author, role: isDriver ? "driver" : "eco" },
          status: "remote",
        };
        return messageCopy;
      })
    )
  );

  const local$: Observable<LocalMessage[]> = localMessages$.pipe(
    scan<LocalMessage | undefined, Map<LocalMessage>>((acc, message) => {
      if (message) {
        acc[message.localId] = message;
      }
      return acc;
    }, {}),
    map(values)
  );

  return combineLatest([remote$, local$]).pipe(
    map(([remote, local]) => uniqBy([...remote, ...local], ({ id }) => id)),
    map((messages) =>
      messages.sort((first, second) => first.createdAt - second.createdAt)
    )
  );
};

export const sendGroupConversationMessage = async ({
  conversationKey,
  message,
  uid,
}: {
  conversationKey: string;
  message: string;
  uid: string;
}) => {
  const localId = uuid();
  const { uid: driverUid } = getChannelIdentifier(conversationKey);
  const isDriver = driverUid === uid;

  const updateStatus = (status: MessageStatus, id: string = localId) =>
    localMessages$.next({
      id,
      localId,
      message,
      author: {
        uid,
        role: isDriver ? "driver" : "eco",
        name: "Me",
      },
      createdAt: nowMillis(),
      status,
    });

  updateStatus("sending");

  const payload: CreateRideMessageRequest = {
    message,
  };

  return post<CreateRideMessageResponse>(chatPath(conversationKey), payload)
    .then(({ id }) => updateStatus("success", id))
    .catch(() => updateStatus("error"));
};

export const markAsRead = (conversationKey: string) => {
  return put<CreateRideMessageResponse>(chatLastReadPath(conversationKey), {});
};
