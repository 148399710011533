import { styled } from "@mui/material/styles";

import { Colors, Typography } from "modules/styles";

const Text = styled("span")<{ numberOfLines?: number }>(
  ({ numberOfLines }) => ({
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxSizing: "border-box",
    color: "inherit",
    display: "inline",
    font: "inherit",
    listStyle: "none",
    margin: "0px",
    padding: "0px",
    textAlign: "inherit",
    textDecoration: "none",
    whiteSpace: "inherit",
    overflowWrap: "break-word",
    ...Typography.regular,
    ...(numberOfLines && {
      ...(numberOfLines > 1
        ? {
            WebkitLineClamp: numberOfLines,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            display: "-webkit-box",
            overflow: "hidden",
          }
        : {
            overflowWrap: "normal",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            overflow: "hidden",
          }),
    }),
  })
);

const ErrorLabel = styled(Text)({
  ...Typography.body,
  fontSize: Typography.sizeSmallMedium,
  color: Colors.error,
});

export { Text, ErrorLabel };
