import { useState, useEffect, FunctionComponent } from "react";
import isEmpty from "lodash/isEmpty";
import { BehaviorSubject } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";

import { is } from "poola-commons/utils";

import AuthLoading from "./AuthLoading";

type LoadingTask = {
  text: string | undefined;
};

type Tasks = { [taskKey: string]: LoadingTask };

const waitingFor = new BehaviorSubject<Tasks | undefined>(undefined);

const AuthLoadingConnected: FunctionComponent = () => {
  const [tasks, setTasks] = useState<Tasks>();
  useEffect(() => {
    const subscription = waitingFor
      .pipe(
        filter(is),
        throttleTime(2000, undefined, { leading: true, trailing: true })
      )
      .subscribe(setTasks);
    return () => subscription.unsubscribe();
  }, []);

  return !isEmpty(tasks) ? <AuthLoading /> : null;
};

const waitFor = (key: string, text?: string) => {
  const newTasks = { ...waitingFor.value, [key]: { text } };
  waitingFor.next(newTasks);
};

const finishFor = (key: string) => {
  const { [key]: toRemove, ...toKeep } = waitingFor.value!;
  waitingFor.next(toKeep);
};

const Loading: FunctionComponent<{ identifier: string }> = ({ identifier }) => {
  useEffect(() => {
    waitFor(identifier);
    return () => finishFor(identifier);
  }, []);
  return null;
};

export { AuthLoadingConnected, Loading };
