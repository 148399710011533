import {
  GetUserRidesRequest,
  GetUserRidesResponse,
  PutRideRequest,
  PutRideStatusResponse,
  UpdateRidesRequest,
  UpdateJoinerPassengersStatusRequest,
  UpdateJoinerDriversStatusRequest,
  GetStatusesRequest,
  GetStatusesResponse,
  PutJoinerLocationRequest,
  PutUserGroupSettingsRequest,
  GetRidesDurationMultiplierResponse,
} from "poola-commons/types";

import { get, put } from "./../api";

const changeRideStatus = (
  request: Omit<PutRideRequest, "uid" | "departure" | "arrival">
) => {
  const { day, groupId, direction, status } = request;
  const url = `/rides-new/${groupId}/me/${day}/${direction}/status`;
  return put<PutRideStatusResponse>(url, { status });
};

const updateRides = (
  request: Omit<UpdateRidesRequest, "uid" | "departure" | "arrival">
) => {
  const { day, groupId, updates } = request;
  const url = `/rides-new/${groupId}/me/${day}`;
  return put<void>(url, { updates });
};

const getMyRides = (request: Omit<GetUserRidesRequest, "uid">) => {
  const { groupId } = request;
  const url = `/rides-new/${groupId}/me`;
  return get<GetUserRidesResponse>(url);
};

const getMyRidesDurationMultiplier = (request: { groupId: string }) => {
  const { groupId } = request;
  const url = `/rides-new/duration-multiplier/${groupId}/me`;
  return get<GetRidesDurationMultiplierResponse>(url);
};

const putUserGroupRideMultiplier = (request: {
  rideDurationMultiplier: number;
  groupId: string;
}) => {
  const { groupId, rideDurationMultiplier } = request;
  const url = `/users-new/group-settings/${groupId}/me`;

  return put(url, {
    groupSettings: {
      rideDurationMultiplier,
    } satisfies PutUserGroupSettingsRequest["groupSettings"],
  });
};

const updateJoinerPassengersStatus = (
  request: UpdateJoinerPassengersStatusRequest
) => {
  const { day, groupId, direction, status, driverUid, passengerUid } = request;
  const url = `/rides-new/${groupId}/${driverUid}/${day}/${direction}/passengers/${passengerUid}/passengers-status`;
  return put<void>(url, { status });
};

const updateJoinerDriversStatus = (
  request: UpdateJoinerDriversStatusRequest
) => {
  const { day, groupId, direction, status, driverUid, passengerUid } = request;
  const url = `/rides-new/${groupId}/${driverUid}/${day}/${direction}/passengers/${passengerUid}/drivers-status`;
  return put<void>(url, { status });
};

const putJoinerLocation = (request: PutJoinerLocationRequest) => {
  const { day, groupId, direction, driverUid, passengerUid, location } =
    request;
  const url = `/ride-locations/${groupId}/${driverUid}/${day}/${direction}/${passengerUid}`;
  return put<void>(url, { location });
};

const getJoinersStatuses = (request: GetStatusesRequest) => {
  const { day, groupId, direction, uid } = request;
  const url = `/rides-new/${groupId}/${uid}/${day}/${direction}/statuses`;
  return get<GetStatusesResponse>(url);
};

export {
  getMyRides,
  getMyRidesDurationMultiplier,
  changeRideStatus,
  updateRides,
  getJoinersStatuses,
  updateJoinerPassengersStatus,
  updateJoinerDriversStatus,
  putJoinerLocation,
  putUserGroupRideMultiplier,
};
