import capitalize from "lodash/capitalize";

export const extractInitials = (string: string): string => {
  const sanitizedString = string.replace(/\s+/g, " ").trim();
  const names = sanitizedString.split(" ");
  const [nameFirst, nameLast] = [
    names[0],
    names.length > 1 ? names[names.length - 1] : undefined,
  ];
  return nameLast
    ? `${capitalize(nameFirst[0])}${capitalize(nameLast[0])}`
    : capitalize(nameFirst[0]);
};
