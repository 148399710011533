import React, { FunctionComponent, lazy, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import range from "lodash/range";

import { is } from "poola-commons/utils";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  Shadows,
} from "@mui/material";

import { WaitForTranslations } from "./i18n/WaitForTranslations";
import { initializeTranslations } from "./i18n/initializeTranslations";
import { Routes } from "./views/Routes";
import { initializeApp } from "./services/firebase/firebase";
import { getStoredSite, getSiteId } from "./services/auth/site";
import { Colors, Containers, Typography } from "./modules/styles";
import { preloadImage } from "./modules/utils/image";
import { UserContextProvider } from "./modules/context/user";
import { Clarity } from "./modules/analytics/clarity";
import { initializeOta } from "./capacitor/Updater";
import { RestoringSession } from "./components/auth";
import { AuthLoadingConnected, Loading } from "./views/auth";
import { ReactQueryPersistentClientProvider } from "./modules/cache/ReactQueryPersistentClientProvider";
import { PlatformContexts } from "./components/common/PlatformContexts";
import { ReQuerySite } from "views/auth/ReQuerySite";
import SelectSiteWithRedirect from "./views/auth/SelectSiteWithRedirect";
import { GeolocationNotifier } from "./views/geolocation";
import { routesMap } from "./views/routesMap";
import { ClarityNotifier } from "./views/clarity";
import { isPoolaStaffRoute } from "helpers/isPoolaStaffRoute";

import poolaLogo from "img/poola.png";

const LazySubscriptionSuccessPage = lazy(
  () => import("./views/subscription/SubscriptionSuccessPage")
);
const LazySubscriptionHttpsPage = lazy(
  () => import("./views/subscription/SubscriptionHttpsPage")
);
const LazyCreateSiteView = lazy(
  () => import("./views/management/CreateSiteView")
);
const LazySelfSignup = lazy(() => import("./views/auth/SelfSignup"));
const LazySelfSignupConfirm = lazy(
  () => import("./views/auth/SelfSignupComplete")
);
// if (__DEV__) {
//   import('./reactotronConfig').then(() => console.log('Reactotron Configured'));
// }

export const checkOvernightAllowedInGroup = (groupId: string): boolean => {
  const groupIds: Record<string, true> = {
    "-Ns92Q62kQ82xb2n9ENv": true, // nestle-konolfingen
  };

  return Boolean(groupIds[groupId]);
};

initializeOta();
Clarity.init();
initializeTranslations();
preloadImage(poolaLogo);

const theme = createTheme({
  palette: {
    primary: { main: Colors.accent, contrastText: Colors.white },
    secondary: { main: Colors.quaternary, contrastText: Colors.white },
  },
  typography: {
    button: {
      fontFamily: Typography.semiBold.fontFamily,
      lineHeight: "normal",
    },
  },
  shadows: range(0, 25).map((elevation) =>
    Containers.shadowCss(elevation)
  ) as Shadows,
});

const App: FunctionComponent<{}> = () => {
  // const navigation = useRef<NavigationContainerComponent>(null);

  // let uglyTimeoutHack: number;

  // const _navigateTo = (route: string, params?: NavigationParams) => {
  //   const navigator = navigation.current;

  //   if (navigator) {
  //     navigator.dispatch(
  //       SwitchActions.jumpTo({ routeName: route, params }),
  //     );
  //   } else {
  //     // We can be on AuthLoading state forever (when navigator is not set yet...)
  //     uglyTimeoutHack = setTimeout(() => {
  //       _navigateTo(route, params);
  //     }, 200);
  //   }
  // };

  // useEffect(() => {
  //   locale.setUp();

  //   return () => clearTimeout(uglyTimeoutHack)
  // }, []);

  // useEffect(() => {
  //   const uid = user?.firebaseUser.uid;

  //   let unsubscribe: () => void;

  //   if (uid) {
  //     registerToPushNotifications()
  //       .then(
  //         async (token) => {
  //           await registerUserToken(uid, token);

  //           unsubscribe = listenToForeground(
  //             (data) => {
  //               // TODO real implementation goes here

  //               console.log('on foreground push notification with data: ', data);
  //             }
  //           );
  //         }
  //       )
  //       .catch(
  //         (e) => console.log('Error while registering to push notifications: ', e)
  //       );
  //   }

  //   return () => {
  //     if (unsubscribe !== undefined) {
  //       unsubscribe();
  //     }
  //   }
  // }, [user && user.firebaseUser && user.firebaseUser.uid]);

  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const organisationId = getSiteId();

  useEffect(() => {
    if (organisationId && !isPoolaStaffRoute(location.pathname)) {
      const site = getStoredSite();
      if (site && site.path === organisationId) {
        const firebaseApp = initializeApp(organisationId, site.tenantId);
        setIsInitialised(is(firebaseApp));
        if (site.logoUrl) {
          preloadImage(site.logoUrl);
        }
      } else {
        window.location.href = routesMap.ENTER_GROUP;
      }
    }
  }, [organisationId, location.pathname]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AuthLoadingConnected />
        <SnackbarProvider>
          <BrowserRouter>
            <Switch>
              <Route path={routesMap.POOLA_MANAGEMENT} exact>
                <LazyCreateSiteView />
              </Route>
              <Route path={routesMap.POOLA_SUBSCRIPTION} exact>
                <LazySubscriptionHttpsPage />
              </Route>
              <Route path={routesMap.POOLA_SUBSCRIPTION_SUCCESS} exact>
                <LazySubscriptionSuccessPage />
              </Route>
              <Route path={routesMap.POOLA_SELF_SIGNUP} exact>
                <LazySelfSignup />
              </Route>
              <Route path={routesMap.POOLA_SELF_SIGNUP_CONFIRM} exact>
                <LazySelfSignupConfirm />
              </Route>
              <Route path="/:organisationId">
                {isInitialised && organisationId ? (
                  <ReactQueryPersistentClientProvider
                    organisationId={organisationId}
                  >
                    <ReQuerySite />
                    <UserContextProvider>
                      <PlatformContexts>
                        <WaitForTranslations>
                          <RestoringSession>
                            <GeolocationNotifier />
                            <ClarityNotifier />
                            <BrowserRouter basename={organisationId}>
                              <React.Suspense fallback={null}>
                                <Routes />
                              </React.Suspense>
                            </BrowserRouter>
                          </RestoringSession>
                        </WaitForTranslations>
                      </PlatformContexts>
                    </UserContextProvider>
                  </ReactQueryPersistentClientProvider>
                ) : (
                  <Loading identifier="loading-organisation" />
                )}
              </Route>
              <Route path={routesMap.ENTER_GROUP}>
                <SelectSiteWithRedirect />
              </Route>
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
