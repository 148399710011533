import { registerPlugin } from "@capacitor/core";
import type {
  BackgroundGeolocationPlugin,
  CallbackError,
  Location,
  WatcherOptions,
} from "@capacitor-community/background-geolocation";

const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>(
  "BackgroundGeolocation"
);

const DEFAULT_PERMISSIONS_PROMPT = `This app needs your location, but does not have permission.

Allow in settings to continue.`;

async function addWatcher(
  successCallback: (location: Location) => void,
  errorCallback: (error: CallbackError) => void = console.error,
  options?: WatcherOptions & { permissionsPrompt?: string }
) {
  const watcherId = await BackgroundGeolocation.addWatcher(
    {
      // If the "backgroundMessage" option is defined, the watcher will
      // provide location updates whether the app is in the background or the
      // foreground. If it is not defined, location updates are only
      // guaranteed in the foreground. This is true on both platforms.
      distanceFilter: 10,
      ...options,
    },
    async function (location, error) {
      if (error) {
        if (error.code === "NOT_AUTHORIZED") {
          if (
            window.confirm(
              options?.permissionsPrompt ?? DEFAULT_PERMISSIONS_PROMPT
            )
          ) {
            BackgroundGeolocation.openSettings();
          }
        }
        errorCallback(error);
      }

      if (location) {
        successCallback(location);
      }
    }
  );

  return watcherId;
}

async function removeWatcher(watcherId: Promise<string> | string) {
  const id = typeof watcherId === "string" ? watcherId : await watcherId;
  return BackgroundGeolocation.removeWatcher({
    id,
  });
}

function guessLocation(
  callback: (location: Location | undefined) => void,
  timeout: number
) {
  let lastLocation: Location | undefined = undefined;
  BackgroundGeolocation.addWatcher({}, function (location) {
    lastLocation = location || undefined;
  }).then(function (id) {
    setTimeout(function () {
      callback(lastLocation);
      BackgroundGeolocation.removeWatcher({ id });
    }, timeout);
  });
}

export { guessLocation, removeWatcher, addWatcher, type Location };
export default BackgroundGeolocation;
