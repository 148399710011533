import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import "firebase/storage";
import utils from "poola-commons/db";
import { Database } from "poola-commons/db/core/d";

const firebaseConfig = require("./poola-firebase-config.json");

let firebaseApp: firebase.app.App;
const initializeApp = (name: string, tenantId: string | undefined) => {
  firebaseApp =
    firebase.apps.find((app) => app.name === name) ||
    firebase.initializeApp(firebaseConfig, name);
  const auth = firebaseApp.auth();
  utils.init(firebaseApp.database() as unknown as Database);
  auth.tenantId = tenantId ?? null;
  return firebaseApp;
};

const getFirebaseApp = () => firebaseApp;

export { getFirebaseApp, initializeApp };
