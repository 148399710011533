import React, { FC } from "react";

import range from "lodash/range";

import LinearProgress from "@mui/material/LinearProgress";
import Box, { BoxProps } from "@mui/material/Box";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell, { tableCellClasses } from "@mui/material/TableCell";
import MuiTableHead, { tableHeadClasses } from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import MuiTable, { TableProps } from "@mui/material/Table";
import { styled, lighten, CSSObject } from "@mui/material/styles";

import MuiTablePagination, {
  TablePaginationProps,
  tablePaginationClasses,
} from "@mui/material/TablePagination";
import MUITableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer";

import { Colors, Helper, Typography } from "modules/styles";
import { Text } from "components/common";

const tableHeadHeight: Record<NonNullable<TableProps["size"]>, number> = {
  small: 50,
  medium: 70,
};

const subElementsBG = (): CSSObject => ({
  backgroundColor: `${lighten(Colors.white, 0.03)} !important`,
});

const TableCell = styled(MuiTableCell)({
  ...Typography.medium,
  fontSize: Typography.sizeSmallMedium,
  borderBottom: `1px solid ${Colors.primary10}`,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
});

const TableSubCell = styled(TableCell)({
  fontSize: `14px !important`,
  ...subElementsBG(),
});

const TableCellNoBorder = styled(TableCell)`
  border: transparent !important;
`;

const TableSubBody = styled(MuiTableBody)({
  [`.${tableRowClasses.root}`]: {
    "&:last-child": {
      [`.${tableCellClasses.root}`]: {
        borderWidth: 2,
      },
    },
  },
});

const Table = styled(
  MuiTable,
  Helper.shouldForwardProp(["tableLayout"])
)<{
  tableLayout?: React.CSSProperties["tableLayout"];
}>(({ theme, size = "medium", tableLayout = "unset" }) => ({
  tableLayout: tableLayout,
  [`.${tableCellClasses.root}`]: {
    paddingLeft: size === "small" && theme.spacing(1),
    paddingRight: size === "small" && theme.spacing(1),
  },
  [`.${tableHeadClasses.root}`]: {
    height: tableHeadHeight[size],
  },
}));

const TableHead = styled(MuiTableHead)({
  [`.${tableCellClasses.root}`]: {
    ...Typography.semiBold,
    fontSize: Typography.sizeSmallMedium,
    backgroundColor: Colors.white,
    color: Colors.primaryLight,
  },
});

const TableSubHeader = styled(TableHead)({
  height: "unset !important",
  ...subElementsBG(),
});

const TableContainer: React.FC<TableContainerProps & { scrollable: boolean }> =
  styled(
    MUITableContainer,
    Helper.shouldForwardProp(["scrollable"])
  )(({ scrollable }: { scrollable: boolean }) => ({
    flex: scrollable ? 1 : "unset",
  }));

const TablePagination = styled(MuiTablePagination)(({ theme }) => ({
  display: "block",
  overflow: "unset",
  borderTop: `1px solid ${Colors.primary10}`,
  [`& .${tablePaginationClasses.selectLabel}`]: {
    ...Typography.regular,
    margin: "0 !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,
    whiteSpace: "nowrap",
    textAlign: "right",
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    margin: "0 !important",
    ...Typography.regular,
  },
  [`& .${tablePaginationClasses.spacer}`]: {
    display: "none",
    ...Typography.regular,
  },
  [`& .${tablePaginationClasses.actions}`]: {
    marginLeft: theme.spacing(1),
    ...Typography.regular,
  },
  [`& .${tablePaginationClasses.select}`]: {
    marginRight: theme.spacing(1),
    ...Typography.regular,
  },
})) as typeof MuiTablePagination;

type BaseTableTrans = {
  allRowsPerPageOption?: string;
  labelNoData?: string;
  labelDataLoading?: string;
  filterPlaceholder?: string;
} & Pick<TablePaginationProps, "labelRowsPerPage" | "labelDisplayedRows">;

const AbsoluteProgress = styled(LinearProgress)({
  position: "absolute",
  width: "100%",
});

const EmptyRow: FC = () => (
  <TableRow>
    <TableCellNoBorder>&nbsp;</TableCellNoBorder>
  </TableRow>
);

const NO_DATA_MINIMAL_HEIGHT = "56px";
const StateViewBox = styled("div")(
  ({ isAbsolute }: { isAbsolute: boolean }) => ({
    position: isAbsolute ? "absolute" : "inherit",
    pointerEvents: "none",
    top: 0,
    display: "grid",
    width: "100%",
    height: isAbsolute ? "100%" : NO_DATA_MINIMAL_HEIGHT,
    bottom: 0,
    alignContent: "center",
  })
);

const StateTableView: FC<{
  stateText: string | null | undefined;
  isOverlay: boolean;
}> = ({ stateText, isOverlay }) => (
  <StateViewBox isAbsolute={isOverlay}>
    <Text sx={{ fontSize: Typography.sizeSmall, textAlign: "center" }}>
      {stateText}
    </Text>
  </StateViewBox>
);

const FillMissingRows = ({
  rowsCount,
  EmptyRow,
}: {
  rowsCount: number;
  EmptyRow: FC;
}) => (
  <>
    {range(0, rowsCount).map((index) => (
      <EmptyRow key={index} />
    ))}
  </>
);

const RelativeTableBox: React.FC<BoxProps & { scrollable: boolean }> = styled(
  Box,
  Helper.shouldForwardProp(["scrollable"])
)(({ scrollable }: { scrollable: boolean }) => ({
  position: "relative",
  height: "100%",
  display: scrollable ? "flex" : "block",
  flexDirection: scrollable ? "column" : "unset",
}));

export {
  TableCell,
  TableSubCell,
  TableCellNoBorder,
  TableHead,
  TableSubHeader,
  TableSubBody,
  TableContainer,
  TablePagination,
  Table,
  AbsoluteProgress,
  RelativeTableBox,
  StateTableView,
  EmptyRow,
  FillMissingRows,
  type BaseTableTrans,
};
