import { useCallback, useRef } from "react";

type Props = {
  action: () => void;
  delay?: number;
};

export const useLongPress = ({ action, delay = 1000 }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const start = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      action();
    }, delay);
  }, [delay]);

  const cancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return {
    onPointerDown: start,
    onPointerUp: cancel,
  };
};

export default useLongPress;
