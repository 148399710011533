import { forwardRef, useState } from "react";

import { styled } from "@mui/material/styles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Colors, Spacing } from "modules/styles";
import { TextInput, type TextInputProps } from "./TextInput";
import TouchableIcon from "./TouchableIcon";
import { View } from "./View";

export const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const [visiblePassword, setVisiblePassword] = useState(false);

    const PasswordIcon = visiblePassword ? VisibilityOffIcon : VisibilityIcon;

    return (
      <View>
        <TextInput
          {...props}
          type={visiblePassword ? "text" : "password"}
          ref={ref}
        />
        <PasswordTouchableIcon
          rippleColor={Colors.accent}
          onClick={() => {
            setVisiblePassword((visibility) => !visibility);
          }}
        >
          <PasswordIcon fontSize="small" color="primary" />
        </PasswordTouchableIcon>
      </View>
    );
  }
);

const PasswordTouchableIcon = styled(TouchableIcon)({
  position: "absolute",
  right: Spacing.small,
  top: "50%",
  transform: "translateY(-50%)",
  padding: Spacing.small,
  borderRadius: "50%",
});
