import { useState } from "react";
import { Observable, Observer } from "rxjs";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import firebase from "firebase";
import minBy from "lodash/minBy";
import find from "lodash/find";

import {
  GetMeResponse,
  GroupAdmin,
  UserGroupSettings,
  UserRole,
  GetUserGroupSettingsResponse,
  Group,
} from "poola-commons/types";
import { is, millisPerDay } from "poola-commons/utils";

import { User, GroupWithHabits } from "d";
import { get } from "services/api";
import { getFirebaseApp } from "services/firebase/firebase";
import { useSubscription } from "modules/hooks";

const PROFILE_CACHE_DURATION = 10 * millisPerDay;

export const authStateChanged$: Observable<firebase.User | null> =
  Observable.create((observer: Observer<firebase.User | null>) =>
    getFirebaseApp()
      .auth()
      .onAuthStateChanged((user) => observer.next(user))
  );

const getUserGroupSettingsDefault = (groupId: string, uid: string) => {
  return { groupId: groupId!, uid: uid!, rideDurationMultiplier: 1 };
};

export const useGetUserGroupSettings = ({
  groupId,
  uid,
}: Partial<{
  groupId: string;
  uid: string;
}>) => {
  return useQuery(
    ["group-settings", uid, groupId],
    () =>
      get<GetUserGroupSettingsResponse | {}>(
        `/users-new/group-settings/${groupId}/me`
      ).then((userGroupSettingsResponse) => ({
        ...getUserGroupSettingsDefault(groupId!, uid!),
        ...userGroupSettingsResponse,
      })),
    { enabled: is(groupId) && is(uid), refetchOnMount: false }
  );
};

export const getGroupFromStorageOrDefault = (
  storedGroupId: string | null,
  groups: Group[] | undefined
): Group | undefined => {
  return (
    find(groups, (group) => group.id === storedGroupId) ||
    minBy(groups, (group) => group.id)
  );
};

export const useMe = () => {
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);

  const subscription = useSubscription();

  subscription.add(
    authStateChanged$.subscribe((user) => {
      setEnabled(Boolean(user));
      if (!user) {
        queryClient.removeQueries();
      }
    })
  );

  const meQuery = useQuery(["me"], () => get<GetMeResponse>("/users-new/me"), {
    refetchOnMount: false,
    cacheTime: PROFILE_CACHE_DURATION,
    networkMode: "offlineFirst",
    enabled: enabled,
  });
  const isIdeterminate =
    enabled === undefined || (enabled === true && meQuery.isLoading);

  return [meQuery, isIdeterminate] as const;
};

export const toUserMeta = (
  user: GetMeResponse,
  currentGroupSettings: UserGroupSettings
): User => {
  const { habit: habits, groups, photoUrl, groupAdmins } = user;
  const groupsWithHabits: GroupWithHabits[] = groups.map((group) => {
    const groupHabits = habits.find(({ groupId }) => groupId === group.id);
    return {
      ...group,
      habits: groupHabits && {
        ...groupHabits,
        group: {
          id: group.id,
          name: group.name,
        },
      },
    };
  });

  const currentGroup = currentGroupSettings.groupId;
  const currentRole = currentGroup
    ? getRole(groupAdmins, currentGroup)
    : undefined;

  return {
    // TODO: we probably should get userMeta and currentGroup from firebase?
    firebaseUser: {
      uid: user.uid,
      name: user.name,
      email: user.email,
    },
    ...(currentGroup && {
      userMeta: {
        photoUrl,
        car: user.car,
        name: user.name,
        currentRole,
        currentGroupSettings,
        currentGroup,
        groups: user.groups.map(({ id }) => id),
        isPasswordUpdated: true,
      },
    }),
    currentGroup: groupsWithHabits.find((group) => group.id === currentGroup),
    groups: groupsWithHabits,
  };
};

const getRole = (groupAdmins: GroupAdmin[], groupId: string): UserRole =>
  groupAdmins.find((admin) => admin.groupId === groupId)?.role || "user";
