import { FC, PropsWithChildren } from "react";


import { View } from "components/common";


export const NoAuthLayout: FC<PropsWithChildren> = ({ children }) => {

  return <View sx={{ height: "100vh", overflow: "hidden" }}>{children}</View>;
};
