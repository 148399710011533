import {
  CustomTokenRequest,
  CustomTokenResponse,
  Credentials,
} from "poola-commons/types";
import { post } from "services/api";

const getImpersonateToken = (request: CustomTokenRequest) => {
  const url = "/token";
  return post<CustomTokenResponse>(url, request, {}, false);
};

const authenticatePoolaStaff = async ({ username, password }: Credentials) => {
  const url = "/token/auth";
  return post(url, { username, password }, {}, false);
};

export { getImpersonateToken, authenticatePoolaStaff };
