import { CSSProperties, FunctionComponent, useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import TouchableIcon from "./TouchableIcon";
import { Colors, Dimens, Spacing, Typography } from "modules/styles";
import { View } from "./View";
import { Text } from "./Text";

type Props = {
  value: number;
  min?: number;
  max?: number;
  style?: CSSProperties;
  renderValue?: (value: number) => string;
  onChanged: (value: number) => void;
};

const NumericInput: FunctionComponent<Props> = ({
  min = Number.MIN_VALUE,
  max = Number.MAX_VALUE,
  style,
  onChanged,
  ...otherProps
}) => {
  const classes = useClasses();
  const [value, setValue] = useState<number>(otherProps.value);

  const _updateValue = (newValue: number) => {
    if (newValue >= min && newValue <= max) {
      setValue(newValue);

      onChanged(newValue);
    }
  };

  useEffect(() => {
    setValue(otherProps.value);
  }, [otherProps.value]);

  const renderValue = otherProps.renderValue
    ? otherProps.renderValue(value)
    : value;

  return (
    <View className={classes.container} style={style}>
      <TouchableIcon
        style={{ ...styles.action, ...styles.left }}
        onClick={() => _updateValue(value - 1)}
      >
        <RemoveIcon sx={styles.icon} />
      </TouchableIcon>

      <Text className={classes.value}>{renderValue}</Text>

      <TouchableIcon
        style={{ ...styles.action, ...styles.right }}
        onClick={() => _updateValue(value + 1)}
      >
        <AddIcon sx={styles.icon} />
      </TouchableIcon>
    </View>
  );
};

const styles: Record<string, CSSProperties> = {
  action: {
    padding: Spacing.small,
    backgroundColor: "whitesmoke",
  },
  left: {
    borderTopLeftRadius: Spacing.small,
    borderBottomLeftRadius: Spacing.small,
  },
  right: {
    borderTopRightRadius: Spacing.small,
    borderBottomRightRadius: Spacing.small,
  },
  icon: {
    color: Colors.primary,
    fontSize: Dimens.iconSmall,
  },
};

const useClasses = makeStyles({
  container: {
    flexDirection: "row",
    alignItems: "stretch",
  },

  value: {
    ...Typography.body,
    color: Colors.primary,
    minWidth: Spacing.extraLarge,
    textAlign: "center",
    backgroundColor: "whitesmoke",
    lineHeight: `${Dimens.iconSmall + 2 * Spacing.small}px`,
  },
});

export { NumericInput };
