import { useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { skip } from "rxjs/operators";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";

import { is } from "poola-commons/utils";
import { RideNewIdentifier } from "poola-commons/types";
import { observeStatusesUpdates, toRideId } from "poola-commons/db";

import {
  changeRideStatus,
  getMyRides,
  updateRides,
  updateJoinerDriversStatus,
  updateJoinerPassengersStatus,
  getJoinersStatuses,
  getMyRidesDurationMultiplier,
} from "./service";
import { RideWithJoinersAndMultiplier } from "components/rides/d";

const useChangeRideStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(changeRideStatus, {
    onSuccess: (archivedRide) => {
      queryClient.invalidateQueries(["my-rides"]);
      if (!isEmpty(archivedRide)) {
        queryClient.invalidateQueries(["my-ride-reports"]);
      }
    },
  });
};

const useUpdateJoinerPassengersStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateJoinerPassengersStatus, {
    onSuccess: () => queryClient.invalidateQueries(["ride-joiner-statuses"]),
  });
};

const useUpdateJoinerDriversStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateJoinerDriversStatus, {
    onSuccess: () => queryClient.invalidateQueries(["ride-joiner-statuses"]),
  });
};

const useGetJoinersStatuses = (ride: RideNewIdentifier) => {
  const queryClient = useQueryClient();
  const rideId = toRideId(ride);

  // This approach can be ineffective if this hook is used in multiple places simultaneously.
  useEffect(() => {
    const subscription = observeStatusesUpdates(ride)
      .pipe(skip(1))
      .subscribe(() =>
        queryClient.invalidateQueries(["ride-joiner-statuses", rideId])
      );
    return () => subscription.unsubscribe();
  }, [rideId]);

  return useQuery(
    ["ride-joiner-statuses", rideId],
    () => getJoinersStatuses(ride),
    {
      enabled: is(ride),
      refetchOnMount: false,
    }
  );
};

const useUpdateRides = ({ groupId }: { groupId: string }) => {
  const queryClient = useQueryClient();

  return useMutation(updateRides, {
    onSuccess: () => {
      queryClient.invalidateQueries(["my-rides"]);
      queryClient.invalidateQueries(["my-rides-with-multiplier", groupId]);
      queryClient.invalidateQueries(["matching-requests"]);
      queryClient.invalidateQueries(["group-settings"]);
    },
  });
};

const useGetRides = (groupId: string) => {
  return useQuery(["my-rides", groupId], () => getMyRides({ groupId }), {
    enabled: is(groupId),
    refetchOnMount: false,
  });
};

const useGetRidesWithDurationMultiplier = (groupId: string) => {
  return useQuery(["my-rides-with-multiplier", groupId], () =>
    Promise.all([
      getMyRides({ groupId }),
      getMyRidesDurationMultiplier({ groupId }),
    ]).then(([myRides, myRidesDurationMultiplier]) => {
      const duartionMultiplierByDay = keyBy(
        myRidesDurationMultiplier,
        ({ day }) => day
      );
      return myRides.map((ride) => ({
        ...ride,
        durationMultiplier:
          duartionMultiplierByDay[ride.day].durationMultiplier ?? 1,
      })) satisfies RideWithJoinersAndMultiplier[];
    })
  );
};

export {
  useGetRides,
  useGetJoinersStatuses,
  useChangeRideStatus,
  useUpdateRides,
  useUpdateJoinerPassengersStatus,
  useUpdateJoinerDriversStatus,
  useGetRidesWithDurationMultiplier,
};
