import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";
import capitalize from "lodash/capitalize";

import { versionName } from "buildInfo";
import { SUPPORTED_LANGUAGES } from "poola-commons/utils";

const initializeTranslations = () =>
  // @ts-ignore ChainedBackend typings aren't applied to the init function
  i18n
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
      detection: {
        // Navigator first, because we do not allow the user to change the language
        // order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
        order: [
          "querystring",
          "navigator",
          "htmlTag",
          "cookie",
          "localStorage",
          "sessionStorage",
        ],
      },
      backend: {
        // Disable LocalStorageBackend because we didn't implement invalidation strategy.
        // backends: [LocalStorageBackend, HttpBackend],
        backends: [HttpBackend],
        backendOptions: [
          // { defaultVersion: versionName },
          {
            loadPath: "/locales/{{lng}}.json",
          },
        ],
      },
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
          if (format === "uppercase") return value.toUpperCase();
          if (format === "lowercase") return value.toLowerCase();
          if (format === "capitalize") return capitalize(value);
          return value;
        },
      },
      allowMultiLoading: false,
      react: {
        useSuspense: false,
      },
      supportedLngs: SUPPORTED_LANGUAGES,
      nonExplicitSupportedLngs: true,
    });

export { initializeTranslations };
