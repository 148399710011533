import { FC } from "react";

import { alpha, darken, lighten, styled } from "@mui/material/styles";
import Tab, { TabProps, tabClasses } from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import { Colors, Typography } from "modules/styles";
import { columnGap, rowGap } from "modules/styles/helper";

export const ToggleButtonGroup = styled(Tabs)(
  ({ theme, variant, color = Colors.accent, orientation }) => ({
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px rgba(149, 149, 149, 0.4)",
    borderRadius: 4,
    color,
    width: variant === "standard" ? "fit-content" : "unset",
    minHeight: "unset",
    [`& .${tabsClasses.indicator}`]: {
      zIndex: 1,
      backgroundColor: alpha(color, 0.2),
      border: `1px solid ${color}`,
      boxSizing: "border-box",
      borderRadius: 4,
      height: "100%",
    },
    [`&  .${tabsClasses.scroller}`]: {
      margin: `${theme.spacing(0.5)} !important`,
    },
    [`& .${tabsClasses.flexContainer}`]: {
      ...(orientation === "vertical"
        ? columnGap(theme.spacing(0.5))
        : rowGap(theme.spacing(0.5))),
      height: "100%",
    },
  })
);

export const ToggleButton: FC<TabProps> = (props) => (
  <StyledToggleButton
    disableTouchRipple
    disableRipple
    disableFocusRipple
    {...props}
  />
);

const StyledToggleButton = styled(Tab)(({ theme }) => ({
  zIndex: 2,
  fontSize: 14,
  color: "inherit",
  minHeight: "unset",
  borderRadius: 4,
  textTransform: "lowercase",
  minWidth: "unset",
  padding: `0 ${theme.spacing(1)}`,
  transition: theme.transitions.create(["color", "background-color"]),
  backgroundColor: "unset",
  ...Typography.regular,
  [`&.${tabClasses.root}.${tabClasses.selected}`]: {
    color: "inherit",
    ...Typography.semiBold,
  },
  "&:hover": {
    color:
      theme.palette.mode === "light"
        ? darken(theme.palette.text.secondary, 0.5)
        : lighten(theme.palette.text.secondary, 0.5),
  },
}));
