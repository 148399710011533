import {
  millisPerHour,
  beginOfDay,
  millisPerMinute,
  trueMod,
} from "poola-commons";
import moment from "moment/moment";

import { TimeParams } from "./d";
import { maxBy } from "lodash";

const ONE_SECOND_IN_MILLIS = 1000;
const ONE_MINUTE_IN_SECONDS = 60;

export const WORKING_HOURS = {
  START: {
    hour: 8,
    minute: 0,
    second: 0,
  },
  END: {
    hour: 16,
    minute: 0,
    second: 0,
  },
};

export const dateOf = (params: TimeParams): Date =>
  moment().set(params).toDate();

export const startOfWorkingDay = (): Date => dateOf(WORKING_HOURS.START);

export const endOfWorkingDay = (): Date => dateOf(WORKING_HOURS.END);

export const representativeWorkingDay = (time: Date): Date =>
  moment(time).add(1, "weeks").isoWeekday(3).toDate();

export const formatTime = (date: Date | number): string =>
  moment(date).format("LT");

// should be used only for API calls
export const formatTime24 = (date: Date | number): string =>
  moment(date).format("HH:mm");

export const dayMilliFromDate = (date: Date | number): number => {
  const [hh, mm] = formatTime24(date).split(":");
  return Number(hh) * millisPerHour + Number(mm) * millisPerMinute;
};

export const formatMonthDay = (date: Date | number): string =>
  moment.utc(date).format("D");

export const formatMonthShortName = (date: Date | number): string =>
  moment.utc(date).format("MMM");

export const formatWeekDayName = (date: Date | number): string =>
  moment.utc(date).format("dddd");

export const formatWeekDayShortName = (date: Date | number): string =>
  moment.utc(date).format("ddd");

export const formatDateAndWeekName = (date: Date | number): string =>
  moment.utc(date).format("D MMM, dddd");

export const formatMonthDayAndMonthShortName = (date: Date | number): string =>
  moment.utc(date).format("D MMM");

export const partOfDayKey = (date: Date | number) => {
  const hour = moment.utc(date).hour();
  if (hour < 13) {
    return "morning";
  } else if (hour < 18) {
    return "afternoon";
  } else if (hour < 20) {
    return "evening";
  }
  return "night";
};

/**
 *
 * @param date Date | number
 * @returns [time in "08:45" format, time format - AM or PM]
 */
export const extract12FormatAndTime = (date: Date | number) => [
  moment.utc(date).format("hh:mm"),
  moment.utc(date).format("A"),
];

export const formatShortDate = (
  date: Date | number,
  now: Date | number
): string => {
  const momentDate = moment.utc(date);
  const formatter = momentDate.isSame(now, "day") ? "HH:mm" : "MMM Do, HH:mm";
  return moment.utc(date).format(formatter);
};

export const formatMillisAsHoursMinutes = (millis: number) => {
  const hours = millisToHours(millis);
  const minutes = trueMod(millisToMinutes(millis), 60);

  return `${hours ? `${hours} h` : ``} ${`${minutes} min`}`.trim();
};

export const humanizeDuration = (duration: number): string =>
  moment.duration(duration).humanize();

export const formatUtc = (date: Date | number, formatter: string) =>
  moment.utc(date).format(formatter);

export const formatDay = (day: number, formatter: string = "Do MMM"): string =>
  moment.utc(beginOfDay(day)).format(formatter);

export const formatUtcTime = (date: Date | number): string =>
  moment.utc(date).format("LT");

export const parseTime = (HH_colon_mm: string): Date => {
  const [hour, minute] = HH_colon_mm.split(":");
  return dateOf({ hour: +hour, minute: +minute, second: 0 });
};

export const parseDayMillis = (millis: number): Date => {
  const hour = Math.floor(millis / millisPerHour);
  const minute = Math.floor((millis % millisPerHour) / millisPerMinute);
  const millisPerSecond = 1000;
  const second = Math.floor((millis % millisPerMinute) / millisPerSecond);
  return dateOf({
    hour,
    minute,
    second,
  });
};

export const parseDay = (day: number): Date =>
  moment.utc(beginOfDay(day)).toDate();

export const timeOfDay = (date: Date): number => {
  const dateAndTime = moment(date);
  return (
    dateAndTime.hour() * millisPerHour +
    dateAndTime.minute() * ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLIS
  );
};

export const now = (): Date => moment().toDate();

export const nowMillis = (): number => now().valueOf();

export const formatAsIsoString = (date: Date) => moment(date).toISOString();

export const secondsToMillis = (seconds: number): number =>
  seconds * ONE_SECOND_IN_MILLIS;

export const secondsToMinutes = (seconds: number): number =>
  Math.round(seconds / ONE_MINUTE_IN_SECONDS);

export const minutesToMillis = (minutes: number): number =>
  secondsToMillis(minutes * ONE_MINUTE_IN_SECONDS);

export const millisToMinutes = (miliseconds: number) =>
  Math.floor(miliseconds / (ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLIS));

export const millisToHours = (milliseconds: number) =>
  Math.floor(milliseconds / millisPerHour);

export const dayAndHourToTimestamp = (
  day: number,
  time: string,
  timezone?: number
): number => {
  const [hours, minutes] = time.split(":");

  return moment
    .utc(beginOfDay(day, timezone))
    .add(hours, "hours")
    .add(minutes, "minutes")
    .valueOf();
};
export const maxDates = (...dates: (Date | undefined)[]): Date =>
  maxBy(dates, (date) => date?.getTime()) as Date;
