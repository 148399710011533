import { styled } from "@mui/material/styles";

import MuiAutocomplete, {
  autocompleteClasses,
} from "@mui/material/Autocomplete";

import { Colors, Dimens, Spacing, Typography } from "modules/styles";
import { Text } from "components/common";

export const SectionTitle: React.FC<
  React.PropsWithChildren<{ color: string }>
> = ({ color, children }) => {
  return <SectionTitleText color={color}>{children}</SectionTitleText>;
};

const SectionTitleText = styled(Text)<{ color: string }>(({ color }) => ({
  ...Typography.regular,
  fontSize: Typography.sizeLarge,
  color: Colors.primary,
  paddingBottom: Spacing.small,
  "&::after": {
    content: '""',
    display: "block",
    width: "20%",
    height: 3,
    transform: "translateY(-4px)",
    backgroundColor: color,
  },
}));

export const Label = styled(Text)({
  ...Typography.regular,
  fontSize: Typography.sizeSmallMedium,
  color: Colors.primaryLight,
});

export const Autocomplete = styled(MuiAutocomplete)({
  [`&, & .${autocompleteClasses.inputRoot}`]: {
    borderRadius: Dimens.large,
    borderBottomRightRadius: 0,
  },
}) as typeof MuiAutocomplete;
