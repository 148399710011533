import { useState } from 'react';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { useSubscription } from './useSubscription';

export const useAsync = <S = void, T = void>(
  action: (arg: S) => Observable<T>,
  onSuccess: (result: T) => void = () => {},
  onError: (error: string) => void = () => {}
): [boolean, string, (s: S) => Promise<T>] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const subscription = useSubscription();


  console.log({loading, error})
  const invoke = (arg: S) => {
    return new Promise<T>((resolve, reject) => {
      setLoading(true);
      setError('');

      const s = action(arg)
        .pipe(take(1))
        .subscribe(
          (result) => {
            setLoading(false);

            onSuccess(result);
            resolve(result)
          },
          (error) => {
            setLoading(false);
            setError(error);

            onError(error);
            reject(error)
          }
        );

      subscription.add(s);
    })
  };

  return [loading, error, invoke];
};

export const useAsyncPromise = <T>(
  action: (...args: any[]) => Promise<T>,
  onSuccess: (result: T) => void = () => {},
  onError: (error: string) => void = () => {}
): [boolean, string, (...args: any[]) => void] => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const invoke = (...args: any[]) => {
    setLoading(true);
    setError('');

    action(...args)
      .then(
        (result) => {
          setLoading(false);

          onSuccess(result);
        }
      )
      .catch(
        (error) => {
          setLoading(false);
          setError(error);

          onError(error);
        }
      );
  };

  return [loading, error, invoke];
};
