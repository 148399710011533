import React from "react";
import { useTranslation } from "react-i18next";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { Capacitor } from "@capacitor/core";

import { JoinersNotificationState } from "poola-commons/types";
import {
  dayAndTimeToMillis,
  localDay,
  millisPerHour,
} from "poola-commons/utils";

import { postRideProgress } from "services/rides/background/postRideProgress";
import { watchPosition$ } from "modules/geo/geolocation-new";
import { useGetRides } from "services/rides-new/query";
import {
  WithAuthenticatedUserProps,
  withRenderIfAuthenticated,
} from "components/common";

const platformSpecificIsoString = (date: Date) => {
  const platform = Capacitor.getPlatform();

  const androidISOString = date.toISOString(); // Android does not support date strings NOT including milliseconds.
  const iosISOString = androidISOString.split(".")[0] + "Z"; // ISO8601DateFormatter does not support date strings including milliseconds. (@capgo/capacitor-updater swift code)

  return platform === "ios" ? iosISOString : androidISOString;
};

const _GeolocationNotifier: React.FC<WithAuthenticatedUserProps> = ({
  firebaseUser,
  currentGroup,
}) => {
  const previousNotificationState = React.useRef<
    JoinersNotificationState | undefined
  >();

  const { t } = useTranslation(undefined, { keyPrefix: "location" });
  const { data: rides } = useGetRides(currentGroup.id);

  const today = localDay(currentGroup.timezone);
  const activeRide = rides?.find(
    (ride) => ride.day === today && ride.status === "active"
  );

  React.useEffect(() => {
    if (activeRide) {
      const isoString = platformSpecificIsoString(
        new Date(
          dayAndTimeToMillis(
            currentGroup.timezone,
            activeRide.day,
            activeRide.arrival + 1.5 * millisPerHour
          )
        )
      );
      CapacitorUpdater.setMultiDelay({
        delayConditions: [
          {
            kind: "date",
            value: isoString,
          },
        ],
      });
      const subscription = watchPosition$({
        backgroundMessage: t("backgroundMessage"),
        backgroundTitle: t("backgroundTitle"),
        permissionsPrompt: t("permissionsPrompt"),
      }).subscribe(({ location }) => {
        const isDriver = activeRide.uid === firebaseUser.uid;
        if (isDriver && location) {
          postRideProgress({
            rideIdentifier: {
              day: activeRide.day,
              direction: activeRide.direction,
              groupId: currentGroup.id,
              uid: firebaseUser.uid,
            },
            joiners: activeRide.joiners,
            location,
            notifications: previousNotificationState.current || {},
            unit: currentGroup.distance.unit,
          }).then(({ data }) => {
            previousNotificationState.current = data.notificationsState;
          });
        }
      });
      return () => {
        subscription.unsubscribe();
        CapacitorUpdater.cancelDelay();
      };
    }
  }, [activeRide]);

  return null;
};

export const GeolocationNotifier =
  withRenderIfAuthenticated(_GeolocationNotifier);
