import { useState } from "react";

/**
 * Useful for event handlers ie, click listerners
 */
const useStateDelayed = <T>(initialState: T): [T, (t: T) => () => void, (t: T) => void] => {
  const [state, setState] = useState<T>(initialState);
  return [state, (t: T) => () => setState(t), setState];
};

export { useStateDelayed };