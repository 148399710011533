import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { alpha } from "@mui/system/colorManipulator";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Credentials } from "poola-commons/types";

import {
  Button,
  Error,
  TextInput,
  View,
  Text,
  PasswordInput,
  ErrorLabel,
} from "components/common";
import { PoolaLogoLayout } from "./PoolaLogoLayout";
import {
  Buttons,
  Containers,
  Inputs,
  Spacing,
  Typography,
  Colors,
} from "modules/styles";
import { routesMap } from "views/routesMap";
import { useLongPress } from "modules/hooks/useLongPress";

const loginSchema: z.ZodType<Credentials> = z.object({
  username: z.string().trim().email({ message: "invalid-email" }),
  password: z.string().trim().min(6, { message: "invalid-password-length" }),
});
type LoginSchema = z.infer<typeof loginSchema>;

type Props = {
  loading: boolean;
  error?: string;
  carpoolingSiteName: string;
  onLogIn: (arg: LoginSchema) => void;
  onForgotPassword: () => void;
  onChangeSite: () => void;
};

const SignIn: FC<Props> = ({
  loading,
  error,
  carpoolingSiteName,
  onLogIn,
  onForgotPassword,
  onChangeSite,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.sign-in",
  });

  const history = useHistory();
  const handleRedirect = () => history.push(routesMap.IMPERSONATE);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<LoginSchema>({
    mode: "onTouched",
    resolver: zodResolver(loginSchema),
  });

  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: passwordFormRef } = register("password");

  const onSubmit = handleSubmit(onLogIn);

  return (
    <PoolaLogoLayout
      title={
        <>
          <And {...useLongPress({ action: handleRedirect, delay: 3000 })}>
            x
          </And>
          <CarpoolingSiteContainer>
            <CarpoolingSite>{carpoolingSiteName}</CarpoolingSite>
            <ChangeSiteLink onClick={onChangeSite}>
              {translate("change-group")}
              <OpenInNewIcon
                sx={{ fontSize: Typography.sizeSmallMedium, marginLeft: 0.5 }}
              />
            </ChangeSiteLink>
          </CarpoolingSiteContainer>
        </>
      }
    >
      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("email-label")}</Text>

        <TextInput
          type="email"
          className={classes.input}
          placeholder={translate("email-placeholder")}
          {...register("username")}
          onSubmitEditing={
            !errors.username
              ? () => passwordInputRef.current?.focus()
              : undefined
          }
        />
        {errors.username && (
          <ErrorLabel>{translate(errors.username.message!)}</ErrorLabel>
        )}
      </View>

      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("password-label")}</Text>

        <PasswordInput
          className={classes.input}
          placeholder={translate("password-placeholder")}
          {...register("password")}
          onSubmitEditing={onSubmit}
          ref={(e) => {
            passwordFormRef(e);
            passwordInputRef.current = e;
          }}
        />
        {errors.password && (
          <ErrorLabel>{translate(errors.password.message!)}</ErrorLabel>
        )}
      </View>

      <Error message={error} />

      <Button
        title={translate("log-in-button")}
        disabled={!isValid || loading}
        sx={{ display: "block" }}
        buttonStyle={styles.button}
        onClick={onSubmit}
      />

      <Button
        title={translate("forgot-password-button")}
        buttonStyle={styles.forgotButton}
        textStyle={styles.forgotButtonText}
        onClick={onForgotPassword}
      />
    </PoolaLogoLayout>
  );
};

const GradientText = styled(Text)(
  Typography.gradient(50, Colors.secondary, Colors.accent)
);

const And = styled(GradientText)({
  fontSize: Typography.sizeMedium,
  ...Typography.bold,
});

const CarpoolingSiteContainer = styled(View)({
  width: "100%",
  alignItems: "center",
});

const CarpoolingSite = styled(GradientText)({
  fontSize: Typography.sizeLarge,
  ...Typography.semiBold,
});

const ChangeSiteLink = styled(Text)({
  cursor: "pointer",
  color: Colors.primaryNegative,
  fontSize: Typography.sizeSmallMedium,
  textDecoration: "underline",
  textDecorationColor: alpha(Colors.primaryNegative, 0.4),
  display: "flex",
  alignItems: "center",
  "&:hover": {
    textDecorationColor: Colors.primaryNegative,
  },
});

const useStyles = makeStyles({
  input: { ...Inputs.light, paddingRight: 52 },
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  fieldContainer: {
    marginTop: Spacing.medium,
  },
});

const styles = {
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
  forgotButton: {
    ...Buttons.linkButtonView,
  },
  forgotButtonText: {
    ...Buttons.linkButtonText,
    fontSize: Typography.sizeSmallMedium,
  },
};

export { SignIn };
