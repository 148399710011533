import amplitude, { AmplitudeClient } from 'amplitude-js';

import { AMPLITUDE_API_KEY } from 'modules/config';

import { Analytics, ActionEvent, ScreenViewEvent } from './d';

const analytics = (instance: AmplitudeClient): Analytics => ({
  init: () => {
    instance.init(AMPLITUDE_API_KEY, undefined, { "useNativeDeviceInfo": true });
  },
  startUserSession: (uid: string, group: string) => {
    instance.setUserId(uid);
    instance.setUserProperties({ group });
  },
  finishUserSession: () => {
    instance.setUserId(null);
  },
  trackEvent: ({ action, category, screen, meta }: ActionEvent) => {
    instance.logEvent(`${category}: ${action}`, { ...screen, ...meta });
  },
  trackScreenView: ({ flow, screen, meta }: ScreenViewEvent) => {
    instance.logEvent(`screen: ${flow}-${screen}`, meta);
  }
});

export default analytics(amplitude.getInstance());
