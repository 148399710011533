import React, { useEffect } from "react";
import { ReactFCC } from "types/react";
import { useTranslation } from "react-i18next";

import * as moment from "moment";
import { Loading } from "views/auth";

const WaitForTranslations: ReactFCC<{ namespace?: string }> = ({
  children,
  namespace: ns,
}) => {
  const { ready, i18n } = useTranslation(ns);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  return ready ? (
    <>{children}</>
  ) : (
    <Loading identifier="loading-translations" />
  );
};
export { WaitForTranslations };
