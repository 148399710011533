export const centerZoom = {
  ".leaflet-top": {
    top: "50%",
    transform: "translateY(-50%)",
    ".leaflet-control": {
      marginTop: 0,
    },
  },
};

export const hideZoom = {
  ".leaflet-top": {
    display: "none",
  },
};
