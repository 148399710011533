import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { styled } from "@mui/material/styles";

import { Credentials } from "poola-commons/types";
import {
  Button,
  Error,
  TextInput,
  View,
  Text,
  PasswordInput,
  ErrorLabel,
} from "components/common";
import {
  Containers,
  Inputs,
  Spacing,
  Typography,
  Colors,
  Buttons,
} from "modules/styles";
import { isValidPassword } from "services/auth";
import { PoolaLogoLayout } from "./PoolaLogoLayout";

const signUpSchema: z.ZodType<Credentials> = z
  .object({
    username: z.string().trim().email({ message: "invalid-email" }),
    password: z.string().nonempty(),
  })
  .refine((data) => isValidPassword(data.password), {
    message: "password-requirements",
    path: ["password"],
  });
type SignUpSchema = z.infer<typeof signUpSchema>;

type Props = {
  loading: boolean;
  error?: string;
  onRegister: (arg: SignUpSchema) => void;
  onEnterGroup: () => void;
};

const SignUp: FunctionComponent<Props> = ({
  loading,
  error,
  onRegister,
  onEnterGroup,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.sign-up",
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<SignUpSchema>({
    mode: "onChange",
    resolver: zodResolver(signUpSchema),
  });

  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: passwordFormRef } = register("password");

  const onSubmit = handleSubmit(onRegister);

  return (
    <PoolaLogoLayout title={translate("header")}>
      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("email-label")}</Text>

        <TextInput
          type="email"
          className={classes.input}
          placeholder={translate("email-placeholder")}
          {...register("username")}
          onSubmitEditing={
            !errors.username
              ? () => passwordInputRef.current?.focus()
              : undefined
          }
        />
        {errors.username && (
          <ErrorLabel>{translate(errors.username.message!)}</ErrorLabel>
        )}
      </View>

      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("password-label")}</Text>

        <PasswordInput
          className={classes.input}
          placeholder={translate("password-placeholder")}
          {...register("password")}
          ref={(e) => {
            passwordFormRef(e);
            passwordInputRef.current = e;
          }}
          onSubmitEditing={onSubmit}
        />
        {errors.password && (
          <ErrorLabel>{translate(errors.password.message!)}</ErrorLabel>
        )}
      </View>

      <Error message={error} />

      <RegisterButton
        title={translate("register-button")}
        disabled={!isValid || loading}
        sx={{ display: "block" }}
        onClick={onSubmit}
      />
      <EnterGroupButton
        title={translate("enter-group")}
        onClick={onEnterGroup}
      />
    </PoolaLogoLayout>
  );
};

const useStyles = makeStyles({
  input: { ...Inputs.light, paddingRight: 52 },
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  fieldContainer: {
    marginTop: Spacing.medium,
  },
});

const RegisterButton = styled(Button)({
  ...Containers.shadowed,
  marginTop: Spacing.huge,
});

const EnterGroupButton = styled(Button)({
  ...Buttons.linkButtonView,
  ...Buttons.linkButtonText,
  fontSize: Typography.sizeSmallMedium,
});

export { SignUp };
