import React, { CSSProperties, useState } from "react";

import ButtonBase, {
  ButtonBaseProps,
  buttonBaseClasses,
  touchRippleClasses,
} from "@mui/material/ButtonBase";
import { alpha, styled } from "@mui/material/styles";

import { Buttons, Dimens, Helper, Spacing, Typography } from "modules/styles";
import { rowGap } from "modules/styles/helper";

export type ButtonProps = Omit<ButtonBaseProps, "onClick"> & {
  title?: string;
  buttonStyle?: CSSProperties;
  textStyle?: CSSProperties;
  radius?: number;
  onClick?: (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => void | Promise<void>;
};

type OutlineProps = { htmlColor: Exclude<CSSProperties["color"], undefined> };

export type OutlineButtonProps = ButtonProps & OutlineProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { onClick, title, buttonStyle, textStyle, radius, disabled, ...props },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClick: ButtonProps["onClick"] = (event) => {
      if (onClick) {
        const maybePromise = onClick(event);
        if (maybePromise?.then) {
          setIsLoading(true);
          maybePromise
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        }
      }
    };

    return (
      <StyledButton
        ref={ref}
        disabled={disabled || isLoading}
        onClick={handleClick}
        style={{
          ...buttonStyle,
          ...textStyle,
          ...(radius && { borderRadius: radius }),
        }}
        {...props}
      >
        {title || typeof props.children === "string" ? (
          <span>{title || props.children}</span>
        ) : (
          props.children
        )}
      </StyledButton>
    );
  }
);

export const OutlineButton = styled(
  Button,
  Helper.shouldForwardProp<OutlineProps>(["htmlColor"])
)<OutlineProps>(({ htmlColor }) => ({
  boxShadow: `inset 0 0 0 2px ${htmlColor}`,
  color: htmlColor,
  backgroundColor: "transparent",
  borderRadius: 1,
  ...Typography.semiBold,

  "&:hover": {
    [`.${touchRippleClasses.root}`]: {
      backgroundColor: alpha(htmlColor, 0.04),
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const buttonInteractionStyles = {
  [`&.${buttonBaseClasses.disabled}`]: {
    opacity: 0.5,
  },

  [`.${touchRippleClasses.root}`]: {
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },

  [`>*:not(.${touchRippleClasses.root})`]: {
    zIndex: 1,
  },

  "&:hover": {
    [`.${touchRippleClasses.root}`]: {
      backgroundColor: "rgba(0,0,0,0.04)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
} as const;

const StyledButton = styled(ButtonBase)({
  textTransform: "none",
  ...Buttons.primaryButtonView,
  ...Buttons.primaryButtonText,
  ...buttonInteractionStyles,
});

export const ActionButton = styled(OutlineButton)({
  display: "flex",
  flexDirection: "row",
  borderRadius: Dimens.small,
  flex: 1,
  ...rowGap(Spacing.small),
});
