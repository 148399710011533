import { Site } from "poola-commons/types";
import { isNot } from "poola-commons/utils";

const DEFAULT_SITE_KEY = "my_site";

export const getStoredSite = (): Site | null => {
  const carpoolingSiteKey = window.localStorage.getItem(DEFAULT_SITE_KEY);
  if (isNot(carpoolingSiteKey)) {
    return null;
  }
  return JSON.parse(carpoolingSiteKey);
};

export const setStoredSite = (key: Site) => {
  window.localStorage.setItem(DEFAULT_SITE_KEY, JSON.stringify(key));
};
