import React from "react";
import {
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
} from "react-table";
import { CSVLink } from "react-csv";

import debounce from "lodash/debounce";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

import DownloadIcon from "@mui/icons-material/Download";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useProgress } from "modules/hooks/useProgress";
import { TextInput } from "components/common/TextInput";
import { Inputs, Spacing } from "modules/styles";
import { rowGap } from "modules/styles/helper";

export type EnhancedHeaderCSV = {
  composeCSV: () => Promise<{
    data: string | object[];
    headers: string[];
    filename: string;
  }>;
};

export type EnhancedHeaderProps = {
  filterProps?: Pick<UseGlobalFiltersInstanceProps<object>, "setGlobalFilter"> &
    UseGlobalFiltersState<object> & {
      filterPlaceholder?: string;
    };
  actions?: React.ReactNode[];
  csv?: EnhancedHeaderCSV;
};

export const EnhancedHeader: React.FC<EnhancedHeaderProps> = ({
  filterProps,
  actions,
  csv,
}) => {
  return (
    <>
      <Stack
        direction="row"
        sx={rowGap(8)}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {filterProps && <GlobalSearch {...filterProps} />}
        <Stack
          direction="row"
          sx={rowGap(8)}
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
        >
          {actions}
          {csv && <CSVDownload {...csv} />}
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

const CSVDownload: React.FC<NonNullable<EnhancedHeaderProps["csv"]>> = (
  props
) => {
  const isSmall = useMediaQuery("(max-width:400px)");

  const [
    composeCSV,
    { result: composedCSV, inProgress: composingDataProgress },
  ] = useProgress(props.composeCSV);
  return (
    <Box flex={1} marginRight={1}>
      <CSVLink
        data={composedCSV?.data ?? []}
        asyncOnClick
        onClick={(_event, done) => composeCSV().finally(() => done(true))}
        headers={composedCSV?.headers ?? []}
        filename={(composedCSV?.filename ?? "file") + ".csv"}
      >
        {isSmall ? (
          <IconButton color="primary" disabled={composingDataProgress}>
            <DownloadIcon />
          </IconButton>
        ) : (
          <Button
            disabled={composingDataProgress}
            startIcon={<DownloadIcon />}
          >{`CSV (Excel)`}</Button>
        )}
      </CSVLink>
    </Box>
  );
};

const GlobalSearch: React.FC<
  NonNullable<EnhancedHeaderProps["filterProps"]>
> = ({ globalFilter, setGlobalFilter, filterPlaceholder }) => {
  const [value, setValue] = React.useState<string>(globalFilter);

  const onChange = React.useCallback(
    debounce<(value: string) => void>(
      (value) => setGlobalFilter(value || undefined),
      200
    ),
    []
  );

  return (
    <StyledInput
      value={value || ""}
      placeholder={filterPlaceholder}
      onChangeText={(text) => {
        setValue(text);
        onChange(text);
      }}
    />
  );
};

const StyledInput = styled(TextInput)({
  ...Inputs.base,
  width: "100%",
  height: "100%",
  marginTop: 0,
  padding: Spacing.small,
});
