import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { SxProps, Theme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import { Colors } from "modules/styles";

export const DirectionArrow: React.FC<{ sx?: SxProps<Theme> }> = ({ sx }) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="nowrap" sx={sx}>
      <MoreHorizIcon
        sx={{ width: 12, height: 12, color: Colors.primaryLight40 }}
      />
      <ArrowForwardIosIcon
        sx={{
          width: 10,
          height: 10,
          color: Colors.primaryLight40,
          marginLeft: "-4px",
        }}
      />
    </Box>
  );
};