import { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import MuiSwitch, { SwitchProps } from "@mui/material/Switch";

type Props = {
  trackColor: { false: string; true: string };
  thumbColor: string;
};

const SwitchWrapper = forwardRef<HTMLButtonElement, Props & SwitchProps>(
  function ViewWrapper({ trackColor, thumbColor, ...other }: Props, ref) {
    return <MuiSwitch {...other} ref={ref} />;
  }
);

export const Switch = styled(SwitchWrapper)<Props>(
  ({ trackColor, thumbColor }) => ({
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: trackColor.true,
          opacity: 1,
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: trackColor.false,
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: thumbColor,
    },
  })
);
