import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";

import { Platform, SupportedLanguage } from "poola-commons/types";

import { registerPushNotifications } from "./service";
import { useRegisterUserToken } from "./query";

import { NotificationChannel } from "./notifications";

export const useAppPushNotifications = (uid: string | undefined) => {
  const history = useHistory();
  const {
    i18n: { resolvedLanguage },
  } = useTranslation();
  const { mutateAsync: registerUserToken } = useRegisterUserToken();

  React.useEffect(() => {
    if (uid && Capacitor.isNativePlatform()) {
      registerPushNotifications();

      // https://capacitorjs.com/docs/apis/push-notifications#channel
      // Why do we need to create a channel? - https://documentation.onesignal.com/docs/android-notification-categories#what-are-these-default-android-categories
      PushNotifications.createChannel(NotificationChannel.GENERAL).catch(
        (error) => console.error("Create channel error", JSON.stringify(error))
      );

      // called when a push notification registration has been successful
      PushNotifications.addListener("registration", async (token) => {
        console.log("Push registration success, token: " + token.value);
        if (resolvedLanguage) {
          await registerUserToken({
            token: token.value,
            platform: Capacitor.getPlatform() as Platform,
            lng: resolvedLanguage as SupportedLanguage,
          });
        }
      });

      // called when a push notification registration error occurs
      PushNotifications.addListener("registrationError", (err) => {
        console.log("Registration error: ", err.error);
      });

      // called when a push notification is received
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push received: " + notification);
        }
      );

      // called when a push notification is performed
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(
            "Push notification action performed",
            notification.actionId,
            notification.inputValue
          );
          if (notification.notification.data.url) {
            history.push(notification.notification.data.url);
          }
        }
      );

      return () => {
        // Remove all the listeners we registered
        PushNotifications.removeAllListeners();
      };
    }
  }, [uid]);
};
