export const extraSmall = 4;

export const small = 8;

export const medium = 16;

export const large = 24;

export const extraLarge = 32;

export const extraExtraLarge = 40;

export const huge = 48;

export const iconExtraSmall = 18;

export const iconSmall = 24;

export const iconMedium = 36;
