import { CSSProperties } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: CSSProperties;
};

type TabPanelsProps = {
  labels: string[];
  panels: JSX.Element[];
  value: number;
  tabStyle?: CSSProperties;
  panelStyle?: CSSProperties;
  onChange: (value: any) => void;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

const TabPanels = (props: TabPanelsProps) => {
  const { panels, labels, value, tabStyle, panelStyle, onChange } = props;

  return (
    <>
      <Tabs
        sx={{ borderBottom: 1, borderColor: "divider" }}
        value={value}
        onChange={(_, newTab) => {
          onChange(newTab);
        }}
      >
        {labels.map((label, index) => (
          <Tab key={index} style={tabStyle} label={label} />
        ))}
      </Tabs>
      {panels.map((content, index) => (
        <TabPanel key={index} value={value} index={index} style={panelStyle}>
          {content}
        </TabPanel>
      ))}
    </>
  );
};

export { TabPanels };
