import { nowMillis } from 'modules/date';

export const uuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const time = nowMillis();

    var r = (time + Math.random() * 16) % 16 | 0;

    // time = Math.floor(time / 16);

    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
