import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

import { Text, View, Button, TextInput } from "components/common";

import {
  Colors,
  Containers,
  Inputs,
  Dimens,
  Spacing,
  Typography,
  Buttons,
} from "modules/styles";
import { isNotEmpty } from "modules/utils";

type Props = {
  loading: boolean;
  error?: string;
  onResetPassword: (email: string) => void;
  onBackToSignIn: () => void;
};

const ForgotPassword: FunctionComponent<Props> = ({
  loading,
  error,
  onResetPassword,
  onBackToSignIn,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.forgot-password",
  });
  const [email, setEmail] = useState("");

  return (
    <View className={classes.container}>
      <Text className={classes.label}>{translate("email-label")}</Text>

      <TextInput
        type="email"
        autoCapitalize="none"
        placeholder={translate("email-placeholder")}
        value={email}
        className={classes.input}
        onChangeText={setEmail}
        onSubmitEditing={() => {
          if (email) {
            onResetPassword(email);
          }
        }}
      />

      {isNotEmpty(error) && (
        <View className={classes.error}>
          <Text className={classes.errorText}>{error}</Text>
        </View>
      )}

      <Button
        title={translate("reset-button")}
        disabled={!email || loading}
        buttonStyle={styles.button}
        onClick={() => onResetPassword(email)}
      />
      <Button
        title={translate("go-to-login")}
        buttonStyle={styles.forgotButton}
        textStyle={styles.forgotButtonText}
        onClick={onBackToSignIn}
      />
    </View>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: Colors.primary,
  },
  description: {
    ...Typography.header,
    color: Colors.primaryNegative,
    fontSize: Typography.sizeRegular,
    marginTop: Spacing.medium,
  },
  error: {
    backgroundColor: Colors.error,
    marginTop: Spacing.medium,
    padding: Spacing.small,
    paddingLeft: Spacing.large,
    paddingRight: Spacing.large,
    borderRadius: Dimens.large,
    opacity: 0.8,
    alignSelf: "center",
  },
  errorText: {
    ...Typography.error,
    ...Typography.center,
    color: Colors.primaryNegative,
  },
  input: Inputs.light,
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
});

const styles = {
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
  forgotButton: {
    ...Buttons.linkButtonView,
  },
  forgotButtonText: {
    ...Buttons.linkButtonText,
    fontSize: Typography.sizeSmallMedium,
  },
};

export { ForgotPassword };
