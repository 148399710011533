import {
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
} from "react";

import { styled } from "@mui/material/styles";

import { View } from "./View";

type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export type ScrollViewProps = {
  horizontal?: boolean;
  contentContainerStyle?: CSSProperties;
  showsHorizontalScrollIndicator?: boolean;
} & DivProps;

export const ScrollView = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ScrollViewProps>
>(
  (
    {
      horizontal,
      showsHorizontalScrollIndicator,
      contentContainerStyle,
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <StyledScollView
        {...props}
        horizontal={horizontal}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
        ref={ref}
        className={className}
      >
        <View
          sx={{ ...(horizontal && { flexDirection: "row" }) }}
          style={contentContainerStyle}
        >
          {children}
        </View>
      </StyledScollView>
    );
  }
);

const ViewWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ScrollViewProps>
>(function ViewWrapper(
  { horizontal, showsHorizontalScrollIndicator, ...other }: ScrollViewProps,
  ref
) {
  return <View {...other} ref={ref} />;
});

const StyledScollView = styled(ViewWrapper)<ScrollViewProps>(
  ({ horizontal, showsHorizontalScrollIndicator }) => ({
    ...(horizontal
      ? {
          overflowX: "auto",
          flexDirection: "row",
          transform: "translateZ(0px)",
          flexShrink: 1,
          flexGrow: 1,
          overflowY: "hidden",
        }
      : {
          transform: "translateZ(0px)",
          overflowY: "auto",
          flexShrink: 1,
          flexGrow: 1,
          flexDirection: "column",
          overflowX: "hidden",
        }),
    ...(showsHorizontalScrollIndicator === false && {
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    }),
  })
);
