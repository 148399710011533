import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { makeStyles } from "@mui/styles";

import {
  Button,
  Error,
  TextInput,
  View,
  Text,
  ErrorLabel,
} from "components/common";
import { PoolaLogoLayout } from "./PoolaLogoLayout";
import {
  Buttons,
  Containers,
  Inputs,
  Spacing,
  Typography,
  Colors,
} from "modules/styles";

const selectSiteSchema = z.object({
  site: z.string().trim().min(3, { message: "invalid-site-name" }),
});
type SelectSiteSchema = z.infer<typeof selectSiteSchema>;

type Props = {
  loading: boolean;
  error?: string;
  onSiteSelect: (arg: SelectSiteSchema) => void;
  onForgotPassword: () => void;
  onCreateSite: () => Promise<void>;
};

const SelectSite: FC<Props> = ({
  loading,
  error,
  onSiteSelect,
  onCreateSite,
  onForgotPassword,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.select-site",
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<SelectSiteSchema>({
    mode: "onTouched",
    resolver: zodResolver(selectSiteSchema),
  });

  const onSubmit = handleSubmit(onSiteSelect);

  return (
    <PoolaLogoLayout title={translate("select-site-title")}>
      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("site-label")}</Text>

        <TextInput
          className={classes.input}
          autoCapitalize="none"
          placeholder={translate("site-placeholder")}
          {...register("site")}
          onSubmitEditing={onSubmit}
        />
        {errors.site && (
          <ErrorLabel>{translate(errors.site.message!)}</ErrorLabel>
        )}
      </View>

      <Error message={error} />

      <Button
        title={translate("go-to-site")}
        disabled={!isValid || loading}
        sx={{ display: "block" }}
        buttonStyle={styles.button}
        onClick={onSubmit}
      />

      <Button
        title={translate("forgot-site-button")}
        buttonStyle={styles.forgotButton}
        textStyle={styles.forgotButtonText}
        onClick={onForgotPassword}
      />
      <Button
        title={translate("create-group")}
        buttonStyle={styles.forgotButton}
        textStyle={styles.forgotButtonText}
        onClick={onCreateSite}
      />
    </PoolaLogoLayout>
  );
};

const useStyles = makeStyles({
  input: Inputs.light,
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  fieldContainer: {
    marginTop: Spacing.medium,
  },
});

const styles = {
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
  forgotButton: {
    ...Buttons.linkButtonView,
  },
  forgotButtonText: {
    ...Buttons.linkButtonText,
    fontSize: Typography.sizeSmallMedium,
  },
};

export { SelectSite };
