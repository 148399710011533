// import I18n from 'react-native-i18n';
// import I18nReact from 'react-i18next';
import I18n from 'i18next';

import * as locale from './setup';
// import en from './en.json';
// import pl from './pl.json';

// I18n.fallbacks = true;

// I18n.translations = {
//   en,
//   pl
// };

const translate = (name: string, params: any = {}): string =>
  I18n.t(name, params);

export {
  translate,
  locale
};

export default I18n;
