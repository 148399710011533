import React from "react";
import { useTable, useSortBy, Column, Row } from "react-table";

import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Collapse from "@mui/material/Collapse";

import { styled } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import IconButton from "@mui/material/IconButton";

import {
  TableCell,
  TableSubCell,
  TableSubHeader,
  TableSubBody,
  Table,
} from "components/common/table/TableComponents";

type EnhancedRowProps<SubData> = {
  row: Row<object>;
  expand: boolean;
  data: SubData;
  columns: Column[];
  hideSubheader?: boolean;
  onExpand: (id: string) => void;
  composeData: (subData: SubData) => object[];
};

const EnhancedRow = <SubData,>({
  row,
  data,
  columns,
  expand,
  onExpand,
  composeData,
  hideSubheader,
}: EnhancedRowProps<SubData>) => {
  const subData = React.useMemo(
    () => composeData(data),
    [JSON.stringify(data)]
  );

  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: subData,
    },
    useSortBy
  );

  return (
    <>
      <TableRow {...row.getRowProps()}>
        <TableCellArrow>
          <IconButton size="small" onClick={() => onExpand(row.id)}>
            {expand ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCellArrow>
        {row.cells.map((cell) => (
          <TableCell
            {...{
              ...cell.column.extraCellProps,
              ...cell?.getCellProps(),
            }}
            sx={{
              width: cell.column.width,
              minWidth: cell.column.minWidth,
              maxWidth: cell.column.maxWidth,
            }}
          >
            {cell.render("Cell")}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
            borderBottom: !expand ? "unset" : undefined,
          }}
          colSpan={6}
        >
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Table size="small" {...getTableProps()}>
              {!hideSubheader && (
                <TableSubHeader>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <TableSubCell
                            {...column.getHeaderProps(
                              column.canSort
                                ? column.getSortByToggleProps()
                                : undefined
                            )}
                            sx={{
                              width: column.width,
                              minWidth: column.minWidth,
                              maxWidth: column.maxWidth,
                            }}
                          >
                            {column.render("Header")}
                            {column.canSort && (
                              <TableSortLabel
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? "desc" : "asc"}
                              />
                            )}
                          </TableSubCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableSubHeader>
              )}
              <TableSubBody>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableSubCell
                            {...cell.getCellProps()}
                            sx={{
                              width: cell.column.width,
                              minWidth: cell.column.minWidth,
                              maxWidth: cell.column.maxWidth,
                            }}
                          >
                            {cell.render("Cell")}
                          </TableSubCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableSubBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TableCellArrow: typeof TableCell = styled(TableCell)`
  max-width: unset;
  width: 50px;
`;

const MemoizedEnhancedRow = React.memo(
  EnhancedRow,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    prevProps.expand === nextProps.expand
) as typeof EnhancedRow;

export { MemoizedEnhancedRow, TableCellArrow };
