import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { Colors } from "modules/styles";
import TouchableIcon from "./TouchableIcon";

export type BackIconButtonProps = {
  backRoute?: string;
  color?: CSSProperties["color"];
  onClick?: () => void;
};

export const BackIconLink: React.FC<BackIconButtonProps> = ({
  backRoute,
  color = Colors.accent,
  onClick,
}) => {
  return (
    <>
      {backRoute ? (
        <Link to={backRoute}>
          <KeyboardBackspaceIcon htmlColor={color} onClick={onClick} />
        </Link>
      ) : onClick ? (
        <TouchableIcon onClick={onClick}>
          <KeyboardBackspaceIcon htmlColor={color} sx={{ cursor: "pointer" }} />
        </TouchableIcon>
      ) : null}
    </>
  );
};
