import { styled } from "@mui/material/styles";

const View = styled("div")({
  alignItems: "stretch",
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: "0px solid black",
  boxSizing: "border-box",
  display: "flex",
  flexBasis: "auto",
  flexDirection: "column",
  flexShrink: "0",
  listStyle: "none",
  margin: "0px",
  minHeight: "0px",
  minWidth: "0px",
  padding: "0px",
  position: "relative",
  textDecoration: "none",
  zIndex: 0,
});

export { View };
