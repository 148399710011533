import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import moment from "moment";

import { styled, alpha } from "@mui/material/styles";
import Button, { buttonClasses } from "@mui/material/Button";
import DialogActions, {
  dialogActionsClasses,
} from "@mui/material/DialogActions";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  MobileTimePicker,
  clockClasses,
  clockPointerClasses,
} from "@mui/x-date-pickers";

import { Colors, Typography } from "modules/styles";

export type DateTimePickerProps = {
  time: Date;
  onTimeChanged: (time: Date) => void;
  max?: Date | undefined;
  min?: Date | undefined;
  confirmLabel: string;
  cancelLabel: string;
  disabled?: boolean;
  color: CSSProperties["color"];
  mode: "date" | "time" | "datetime";
};

const DateTimePicker: FunctionComponent<DateTimePickerProps> = ({
  time,
  onTimeChanged,
  max,
  min,
  disabled,
  color,
  confirmLabel,
  cancelLabel,
  mode = "time",
}) => {
  const [date, setDate] = useState(time);

  useEffect(() => {
    setDate(time);
  }, [time.getTime()]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* TODO: translate Select time label */}
      <MobileTimePicker
        ampm={false}
        orientation="portrait"
        minTime={min && moment(min)}
        maxTime={max && moment(max)}
        value={moment(date)}
        disabled={disabled}
        onAccept={(date) => {
          if (date) {
            setDate(date.toDate());
            onTimeChanged(date.toDate());
          }
        }}
        onChange={(date) => {
          if (date) {
            setDate(
              keepInRange(
                min && moment(min).toDate(),
                max && moment(max).toDate(),
                date.toDate()
              )
            );
          }
        }}
        slots={{
          actionBar: (props) =>
            CustomActionBar({
              ...props,
              confirmLabel,
              cancelLabel,
            }),

          field: ({ defaultValue, onChange, onError, ...params }) => (
            <Container
              ref={params.InputProps?.ref}
              onClick={(params as any).onClick}
            >
              <input
                {...params.inputProps}
                style={{
                  width: "inherit",
                  height: "inherit",
                  ...Typography.input,
                }}
              />
            </Container>
          ),
        }}
        slotProps={{
          mobilePaper: {
            sx: colorStyles(color),
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const keepInRange = (
  min: Date | undefined,
  max: Date | undefined,
  date: Date
) => {
  const time = date.getTime();
  if (min && time <= min.getTime()) {
    return min;
  } else if (max && time >= max.getTime()) {
    return max;
  } else {
    return date;
  }
};

export const colorStyles = (color: CSSProperties["color"] = Colors.accent) =>
  ({
    [`& .${clockPointerClasses.thumb}`]: {
      backgroundColor: color,
      borderColor: color,
    },
    [`& .${clockPointerClasses.root}`]: {
      backgroundColor: color,
    },
    [`& .${clockClasses.pin}`]: {
      backgroundColor: color,
    },
    [`& .${dialogActionsClasses.root} > .${buttonClasses.root}`]: {
      color: color,
      "&:hover": {
        backgroundColor: alpha(color, 0.04),
      },
    },
  } as const);

export const CustomActionBar = (
  props: PickersActionBarProps & {
    cancelLabel: string | undefined;
    confirmLabel: string | undefined;
  }
) => {
  const { onAccept, onCancel, actions, cancelLabel, confirmLabel } = props;
  if (actions == null || actions.length === 0) {
    return null;
  }

  const actionsItems = actions?.map((actionType) => {
    switch (actionType) {
      case "cancel":
        return (
          <Button onClick={onCancel} key={actionType}>
            {cancelLabel}
          </Button>
        );
      case "accept":
        return (
          <Button onClick={onAccept} key={actionType}>
            {confirmLabel}
          </Button>
        );

      default:
        return null;
    }
  });

  return (
    <DialogActions className={props.className}>{actionsItems}</DialogActions>
  );
};

const Container = styled("div")({
  opacity: 0,
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
});

export default DateTimePicker;
