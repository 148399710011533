import { ComponentType } from "react";
import { Redirect } from "react-router-dom";

import { AdminRole } from "poola-commons/types";
import { hasPermissions } from "poola-commons/utils";

import { routesMap } from "views/routesMap";
import { WithAuthenticatedUserProps } from "./withAuthenticatedUser";

const withGroupAdminRole =
  <P extends WithAuthenticatedUserProps>(role: AdminRole = "moderator") =>
  (Component: ComponentType<P>) =>
  (props: P) => {
    const { userMeta } = props;

    if (!hasPermissions({ userRole: userMeta.currentRole, hasRole: role })) {
      return <Redirect to={routesMap.DAYS_LIST} />;
    }

    return <Component {...props} />;
  };

export { withGroupAdminRole };
