import { TextStyle } from "./d";

import * as Colors from "./colors";

export const sizeExtraSmall = 10;

export const sizeSmall = 12;

export const sizeSmallMedium = 14;

export const sizeMedium = 16;

export const sizeRegular = 18;

export const sizeLarge = 24;

export const sizeHuge = 32;

export const black: TextStyle = {
  fontFamily: "Catamaran-Black",
};

export const bold: TextStyle = {
  fontFamily: "Catamaran-Bold",
};

export const extraBold: TextStyle = {
  fontFamily: "Catamaran-ExtraBold",
};

export const extraLight: TextStyle = {
  fontFamily: "Catamaran-ExtraLight",
};

export const light: TextStyle = {
  fontFamily: "Catamaran-Light",
};

export const medium: TextStyle = {
  fontFamily: "Catamaran-Medium",
};

export const regular: TextStyle = {
  fontFamily: "Catamaran-Regular",
};

export const semiBold: TextStyle = {
  fontFamily: "Catamaran-SemiBold",
};

export const thin: TextStyle = {
  fontFamily: "Catamaran-Thin",
};

export const error: TextStyle = {
  ...medium,
  fontSize: sizeRegular,
  color: Colors.error,
};

export const center: TextStyle = {
  textAlign: "center",
};

export const header: TextStyle = {
  ...regular,
  fontSize: sizeHuge,
  color: Colors.primary,
};

export const body: TextStyle = {
  ...regular,
  fontSize: sizeRegular,
  color: Colors.primaryLight,
};

export const secondary: TextStyle = {
  ...regular,
  fontSize: sizeMedium,
  color: Colors.primaryLight40,
};

export const input: TextStyle = {
  ...regular,
  fontSize: sizeRegular,
  color: Colors.primary,
};

export const shadow: TextStyle = {
  textShadow: `${Colors.primaryLight40} 0px 2px 3.84px`,
};

export const gradient: TextStyle = (
  angle: number,
  color1: string,
  color2: string
) => ({
  backgroundImage: `linear-gradient(${angle}deg, ${color1}, ${color2})`,
  backgroundClip: "text",
  color: "transparent",
});

// Onboarding specific

const tutorialBase: TextStyle = {
  ...shadow,
  color: Colors.primaryNegative,
};

export const onboardingBody: TextStyle = {
  ...body,
  ...tutorialBase,
};

export const onboardingHeader: TextStyle = {
  ...header,
  ...tutorialBase,
};

export const onboardingInput: TextStyle = {
  ...input,
  color: Colors.primary,
  lineHeight: "28px",
};

export const ellipsisText: TextStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
