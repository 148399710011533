import { styled } from "@mui/material/styles";
import { Colors, Spacing, Typography } from "modules/styles";
import { Text } from "./Text";

export const Title = styled(Text)({
  ...Typography.medium,
  fontSize: Typography.sizeRegular,
  color: Colors.primary,
});

export const Description = styled(Text)({
  ...Typography.regular,
  fontSize: Typography.sizeMedium,
  color: Colors.primaryLight,
  paddingBottom: Spacing.extraSmall,
});
