import { forwardRef } from "react";

import { styled } from "@mui/material/styles";
import ButtonBase, {
  ButtonBaseProps,
  touchRippleClasses,
} from "@mui/material/ButtonBase";

type Props = {
  onClick?: () => void;
};

export type TouchableProps = ButtonBaseProps & Props

const Touchable = forwardRef<HTMLButtonElement, TouchableProps>(
  ({ onClick, children, ...props }, ref) => {
    return (
      <StyledTouchable ref={ref} onClick={onClick} {...props}>
        {children}
      </StyledTouchable>
    );
  }
);

const StyledTouchable = styled(ButtonBase)({
  alignItems: "stretch",
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: "0px solid black",
  boxSizing: "border-box",
  display: "flex",
  flexBasis: "auto",
  flexDirection: "column",
  flexShrink: "0",
  listStyle: "none",
  margin: "0px",
  minHeight: "0px",
  minWidth: "0px",
  padding: "0px",
  position: "relative",
  textDecoration: "none",
  zIndex: 0,

  textAlign: "left",
  justifyContent: "flex-start",

  [`.${touchRippleClasses.root}`]: {
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },

  [`>*:not(.${touchRippleClasses.root})`]: {
    zIndex: 1,
  },

  "&:hover": {
    [`.${touchRippleClasses.root}`]: {
      backgroundColor: "rgba(0,0,0,0.04)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
});

export { Touchable };
