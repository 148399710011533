import { filter } from "rxjs/operators";
import { BehaviorSubject, Observer, Observable, Subscription } from "rxjs";

const NO_VALUE = {};

const cacheWithTimeout = <T>(
  source: Observable<T>,
  timeout: number
): Observable<T> => {
  let subscribersCount = 0;
  let sourceSubscription = Subscription.EMPTY;
  let unsubscribeHandle: NodeJS.Timeout | undefined;

  const subject: BehaviorSubject<T | {}> = new BehaviorSubject<
    T | typeof NO_VALUE
  >(NO_VALUE);

  return Observable.create((observer: Observer<T>) => {
    clearTimeout(unsubscribeHandle);
    if (sourceSubscription.closed) {
      sourceSubscription = source.subscribe((update) => {
        subject.next(update);
      });
    }
    subscribersCount = subscribersCount + 1;
    const subscription = subject
      .pipe(filter((update) => update !== NO_VALUE))
      .subscribe((update) => {
        observer.next(update as T);
      });

    return () => {
      subscription.unsubscribe();
      subscribersCount = subscribersCount - 1;
      if (subscribersCount === 0 && unsubscribeHandle === undefined) {
        unsubscribeHandle = setTimeout(
          () => sourceSubscription.unsubscribe(),
          timeout
        );
      }
    };
  });
};

export default cacheWithTimeout;
