import { FC, lazy, PropsWithChildren } from "react";
import {
  Redirect,
  Route as ReactRouterRoute,
  Switch,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";

import { routesMap } from "./routesMap";
import SelectSiteWithoutRedirect from "./auth/SelectSiteWithoutRedirect";
import {
  AuthLayout as BottomNavigationLayout,
  NoAuthLayout as MainLayout,
  OnboardingLayout,
  ChatLayout,
} from "components/layout";
import { useStatusBar } from "components/common/StatusBar";
import { Colors } from "modules/styles";

const LazyGroupSettings = lazy(() => import("./admin/GroupSettings"));
const LazySignInImpersonate = lazy(() => import("./auth/SignInImpersonate"));
const LazySignIn = lazy(() => import("./auth/SignIn"));
const LazySignUp = lazy(() => import("./auth/SignUp"));
const LazySiteName = lazy(() => import("./auth/SiteName"));
const LazySiteDetails = lazy(() => import("./auth/SiteDetails"));
const LazyForgotPassword = lazy(() => import("./auth/ForgotPassword"));
const LazyDaysList = lazy(() => import("./rides/DaysList"));
const LazyOnboarding = lazy(() => import("./onboarding/Onboarding"));
const LazyUsers = lazy(() => import("./admin/users/Users"));
const LazyAdminReports = lazy(() => import("./admin/reports/Report"));
const LazyProfile = lazy(() => import("./profile/ProfileRoutes"));
const LazyCommunity = lazy(() => import("./community/CommunityPage"));
const LazySubscription = lazy(
  () => import("./subscription/SubscriptionPageView")
);
const LazyRideChat = lazy(() => import("./ongoing-ride/RideChat"));
const LazyLiftRequestMap = lazy(
  () => import("./ongoing-ride/joiner/LiftRequestMap")
);
const LazyDriverMap = lazy(() => import("./ongoing-ride/driver/DriverMap"));
const LazyReports = lazy(() => import("./reports/Reports"));
const AdminMap = lazy(() => import("./admin/map/AdminMap"));

export const Routes: FC = () => (
  <Switch>
    <Route
      path={routesMap.LOGIN}
      component={LazySignIn}
      statusBarColor={Colors.primary}
      layout={MainLayout}
      exact
    />
    <Route
      path={routesMap.IMPERSONATE}
      component={LazySignInImpersonate}
      statusBarColor={Colors.primary}
      layout={MainLayout}
    />
    <Route
      path={routesMap.FORGOT_PASSWORD}
      component={LazyForgotPassword}
      statusBarColor={Colors.primary}
      layout={MainLayout}
      exact
    />
    <Route
      path={routesMap.ONBOARDING}
      component={LazyOnboarding}
      statusBarColor={Colors.accent}
      layout={OnboardingLayout}
    />
    <Route
      path={routesMap.DAYS_LIST}
      component={LazyDaysList}
      statusBarColor={Colors.background}
      layout={BottomNavigationLayout}
    />
    <Route
      path={routesMap.ADMIN}
      component={LazyUsers}
      statusBarColor={Colors.white}
      layout={BottomNavigationLayout}
      exact
    />
    <Route
      path={routesMap.ADMIN_REPORTS}
      component={LazyAdminReports}
      statusBarColor={Colors.white}
      layout={BottomNavigationLayout}
      exact
    />
    <Route
      path={routesMap.ADMIN_MAP}
      component={AdminMap}
      statusBarColor={Colors.white}
      layout={BottomNavigationLayout}
      exact
    />
    <Route
      path={routesMap.ADMIN_GROUP_SETTINGS}
      component={LazyGroupSettings}
      statusBarColor={Colors.white}
      layout={BottomNavigationLayout}
      exact
    />
    <Route
      path={routesMap.SUBSCRIPTION}
      component={LazySubscription}
      statusBarColor={Colors.white}
      layout={BottomNavigationLayout}
    />
    <Route
      path={routesMap.COMMUNITY}
      component={LazyCommunity}
      statusBarColor={Colors.background}
      layout={BottomNavigationLayout}
    />
    <ReactRouterRoute path={routesMap.PROFILE} component={LazyProfile} />
    <Route
      path={routesMap.REGISTER}
      component={LazySignUp}
      statusBarColor={Colors.primary}
      layout={MainLayout}
      exact
    />
    <Route
      path={routesMap.SITE_NAME}
      component={LazySiteName}
      statusBarColor={Colors.primary}
      layout={MainLayout}
      exact
    />
    <Route
      path={routesMap.SITE_DETAILS}
      component={LazySiteDetails}
      statusBarColor={Colors.primary}
      layout={MainLayout}
      exact
    />
    <Route
      path={routesMap.LIFT_REQUEST_MAP}
      component={LazyLiftRequestMap}
      statusBarColor={Colors.white}
      layout={MainLayout}
    />
    <Route
      path={routesMap.RIDE_DRIVER}
      component={LazyDriverMap}
      statusBarColor={Colors.white}
      layout={MainLayout}
    />
    <Route
      path={routesMap.RIDE_CHAT}
      component={LazyRideChat}
      statusBarColor={Colors.white}
      layout={ChatLayout}
    />
    <Route
      path={routesMap.REPORTS}
      component={LazyReports}
      statusBarColor={Colors.background}
      layout={BottomNavigationLayout}
    />
    <Route
      path={routesMap.CHANGE_GROUP}
      component={SelectSiteWithoutRedirect}
      statusBarColor={Colors.primary}
      layout={MainLayout}
    />
    <Redirect to={routesMap.LOGIN} />
  </Switch>
);

export const Route: FC<
  RouteProps & {
    layout: FC<PropsWithChildren & RouteComponentProps<any, any, any>>;
    statusBarColor: string;
  }
> = ({
  layout: Layout,
  component: Component,
  statusBarColor,
  ...routeProps
}) => {
  useStatusBar(statusBarColor);
  return (
    <ReactRouterRoute
      render={(props) => (
        <Layout {...props}>{Component && <Component {...props} />}</Layout>
      )}
      {...routeProps}
    />
  );
};
