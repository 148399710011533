export const isValidPassword = (password: string): boolean => {
  /**
   * At least:
   * - one lower case
   * - once upper case
   * - one digit
   * - 8 characters
   */
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/;
  return passwordRegex.test(password);
};
