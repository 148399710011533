import { FC, PropsWithChildren, useRef } from "react";
import { useTranslation } from "react-i18next";
import find from "lodash/find";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";

import { makeStyles } from "@mui/styles";

import { Colors, Dimens, Inputs, Spacing, Typography } from "modules/styles";
import { View, Text, PasswordInput, TextInput } from "components/common";
import { ButtonProps } from "components/common/Button";
import { isValidPassword } from "services/auth";

const changePasswordSchema = z
  .object({
    oldPassword: z.string().nonempty(),
    newPassword: z.string({}),
    repeatPassword: z.string({}).nonempty({ message: "password-match" }),
  })
  .refine((data) => isValidPassword(data.newPassword), {
    message: "password-requirements",
    path: ["newPassword"],
  })
  .refine((data) => data.newPassword === data.repeatPassword, {
    message: "password-match",
    path: ["repeatPassword"],
  });
type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

type Props = {
  loading: boolean;
  error?: string;
  Container: FC<PropsWithChildren>;
  ChangePasswordButton: FC<ButtonProps>;
  onChangePassword: (arg: Omit<ChangePasswordSchema, "repeatPassword">) => void;
};

const ChangePassword: FC<Props> = ({
  loading,
  error,
  Container,
  ChangePasswordButton,
  onChangePassword,
}) => {
  const styles = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.profile.change-password",
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<ChangePasswordSchema>({
    mode: "onChange",
    resolver: zodResolver(changePasswordSchema),
  });
  const newPasswordRef = useRef<HTMLInputElement | null>(null);
  const { ref: newPasswordFormRef } = register("newPassword");
  const repeatPasswordRef = useRef<HTMLInputElement | null>(null);
  const { ref: repeatPasswordFormRef } = register("repeatPassword");

  const _changePassword = handleSubmit(onChangePassword);

  const errorsByImportance = [
    errors.newPassword && translate(errors.newPassword.message!),
    errors.repeatPassword && translate(errors.repeatPassword.message!),
    error,
  ];

  const displayedError = find(errorsByImportance, (error) => !!error);

  return (
    <>
      <Container>
        <div style={{ fontSize: 5, padding: "2px" }}>V.1.10</div>
        <TextInput
          type="password"
          className={styles.input}
          {...register("oldPassword")}
          placeholder={translate("old-password-label")}
          onSubmitEditing={
            !errors.oldPassword
              ? () => newPasswordRef.current?.focus()
              : undefined
          }
        />

        <PasswordInput
          className={styles.input}
          placeholder={translate("new-password-label")}
          {...register("newPassword")}
          ref={(e) => {
            newPasswordFormRef(e);
            newPasswordRef.current = e;
          }}
          onSubmitEditing={
            !errors.newPassword
              ? () => repeatPasswordRef.current?.focus()
              : undefined
          }
        />

        <TextInput
          type="password"
          className={styles.input}
          placeholder={translate("new-password-repeat-label")}
          {...register("repeatPassword")}
          ref={(e) => {
            repeatPasswordFormRef(e);
            repeatPasswordRef.current = e;
          }}
          onSubmitEditing={
            !errors.repeatPassword
              ? () => repeatPasswordRef.current?.blur()
              : undefined
          }
        />
        {displayedError && (
          <View className={styles.error}>
            <Text className={styles.errorText}>{displayedError}</Text>
          </View>
        )}
      </Container>
      <ChangePasswordButton
        title={translate("change-password-button")}
        disabled={!isValid || loading}
        // loading={loading}
        radius={Dimens.large}
        onClick={_changePassword}
      />
    </>
  );
};

const useStyles = makeStyles({
  error: {
    backgroundColor: Colors.error,
    marginTop: Spacing.medium,
    padding: Spacing.small,
    paddingLeft: Spacing.large,
    paddingRight: Spacing.large,
    borderRadius: Dimens.large,
    width: "100%",
    opacity: 0.6,
    alignSelf: "center",
  },
  errorText: {
    ...Typography.error,
    ...Typography.center,
    fontSize: Typography.sizeSmall,
    color: Colors.primaryNegative,
  },
  input: {
    ...Inputs.dark,
    padding: Spacing.small,
    backgroundColor: Colors.primaryNegative,
  },
});

export { ChangePassword };
