import { FC, useContext } from "react";

import { is } from "poola-commons/utils";

import { Clarity } from "modules/analytics/clarity";
import { UserContext } from "modules/context";

export const ClarityNotifier: FC = () => {
  const { user } = useContext(UserContext);

  const isUser = is(user);

  if (Clarity.hasStarted() && isUser) {
    Clarity.customizeIdentifier(user.firebaseUser.uid);
  }

  return null;
};
