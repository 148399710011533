import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import { alpha, styled } from "@mui/material/styles";

import { getLocalTimezone } from "poola-commons/utils";
import { Coordinate } from "poola-commons/types";

import { useCreateSite } from "services/auth/site/query";
import { PoolaLogoLayout } from "./PoolaLogoLayout";
import { rowGap } from "modules/styles/helper";
import { routesMap } from "views/routesMap";
import { UserContext } from "modules/context";
import { Alert, Button, Text, View } from "components/common";
import { logOut } from "services/auth";
import { MapView } from "components/map";
import {
  Buttons,
  Colors,
  Containers,
  MapView as MapViewStyle,
  Spacing,
  Typography,
} from "modules/styles";

export const SiteDetails: React.FC = ({}) => {
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.create-site",
  });
  const history = useHistory<{
    siteName: string;
    siteIdentifier: string;
  }>();

  const { user } = React.useContext(UserContext);

  const { mutateAsync: createSite, isLoading } = useCreateSite();
  const [createGroupConfirmation, setCreateGroupConfirmation] =
    React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [commuteDestination, setCommuteDestination] =
    React.useState<Coordinate>([51.5287718, -0.2416815]);

  const locationState = history.location.state;

  const handleChangeSite = () => {
    history.push(routesMap.SITE_NAME, {
      siteName: locationState.siteName,
      siteIdentifier: locationState.siteIdentifier,
    });
  };

  const handleCreateGroup = () => {
    createSite({
      path: locationState.siteIdentifier,
      name: locationState.siteName,
      location: commuteDestination,
      timezone: getLocalTimezone(),
    }).catch(() => {
      enqueueSnackbar(translate("create-site-error-msg"), {
        variant: "error",
      });
    });
  };

  const handleLogout = () => {
    logOut();
  };

  return (
    <PoolaLogoLayout title={translate("create-site-title")}>
      {user && (
        <User>
          <FlexRow>
            <AccountCircleIcon sx={{ color: Colors.white }} />
            <LabelBold>{user.firebaseUser.name}</LabelBold>
          </FlexRow>
          <TextLink onClick={handleLogout} disabled={isLoading}>
            {translate("log-out-button")}
            <LogoutIcon
              sx={{ fontSize: Typography.sizeSmallMedium, marginLeft: 0.5 }}
            />
          </TextLink>
        </User>
      )}
      {locationState && (
        <SiteDetailsGroup>
          <SiteDetailsView>
            <Label>{translate("site-name-label") + ":"}</Label>
            <SiteDetailView>{locationState.siteName}</SiteDetailView>
          </SiteDetailsView>
          <SiteDetailsView>
            <Label>{translate("site-identifier-label") + ":"}</Label>
            <SiteDetailView>{locationState.siteIdentifier}</SiteDetailView>
          </SiteDetailsView>
        </SiteDetailsGroup>
      )}
      <Label>{translate("choose-commute-destination-title")}</Label>
      <View sx={{ ...MapViewStyle.centerZoom, flex: 1, minHeight: 150 }}>
        <MapView
          focus={commuteDestination}
          zoom={0}
          locationPin
          onMoveEnd={({ center }) => setCommuteDestination(center)}
        />
      </View>
      <StyledButton
        title={translate("create-group-btn")}
        disabled={isLoading}
        sx={{ display: "block" }}
        onClick={() => setCreateGroupConfirmation(true)}
      />
      <GoBackButton
        title={translate("go-back-btn")}
        disabled={isLoading}
        onClick={handleChangeSite}
      />
      <Alert
        open={createGroupConfirmation}
        title={translate("confirm-create-group.title")}
        text={
          <Trans
            i18nKey={`view.auth.create-site.confirm-create-group.message`}
            values={{
              siteName: locationState.siteName,
            }}
            components={{
              bold: <strong />,
            }}
          />
        }
        handleClose={() => setCreateGroupConfirmation(false)}
        buttons={[
          {
            text: translate("confirm-create-group.cancel-btn"),
            onClick: () => setCreateGroupConfirmation(false),
          },
          {
            text: translate("confirm-create-group.create-btn"),
            onClick: handleCreateGroup,
          },
        ]}
      />
    </PoolaLogoLayout>
  );
};

const TextLink = styled(Text)<{ disabled?: boolean }>(({ disabled }) => ({
  cursor: "pointer",
  pointerEvents: disabled ? "none" : "auto",
  color: Colors.primaryNegative,
  fontSize: Typography.sizeSmallMedium,
  textDecoration: "underline",
  textDecorationColor: alpha(Colors.primaryNegative, 0.4),
  display: "flex",
  alignItems: "center",
  "&:hover": {
    textDecorationColor: Colors.primaryNegative,
  },
}));

const Label = styled(Text)({
  ...Typography.body,
  fontSize: Typography.sizeMedium,
  color: Colors.primaryNegative,
});

const LabelBold = styled(Text)({
  ...Typography.bold,
  fontSize: Typography.sizeMedium,
  color: Colors.primaryNegative,
});

const SiteDetailsView = styled(View)({
  flexDirection: "row",
  alignItems: "baseline",
  ...rowGap(8),
});

const SiteDetailView = styled(Text)({
  ...Typography.bold,
  fontSize: Typography.sizeRegular,
  color: Colors.primaryNegative,
});

const SiteDetailsGroup = styled(View)({
  marginBottom: Spacing.small,
});

const FlexRow = styled(View)({
  flexDirection: "row",
  ...rowGap(8),
});

const User = styled(View)({
  alignItems: "center",
  marginBottom: Spacing.small,
});

const StyledButton = styled(Button)({
  ...Containers.shadowed,
  marginTop: Spacing.large,
});

const GoBackButton = styled(Button)({
  ...Buttons.linkButtonView,
  ...Buttons.linkButtonText,
  fontSize: Typography.sizeSmallMedium,
});
