import React, { FunctionComponent } from "react";

import WorkIcon from "@mui/icons-material/Work";

import { PinMarker } from "./PinMarker";
import { Colors } from "modules/styles";

type Props = React.ComponentProps<typeof PinMarker>;

const OfficeMarker: FunctionComponent<Props> = ({ ...otherProps }) => (
  <PinMarker {...otherProps}>
    <WorkIcon
      style={{ fill: Colors.primaryLight, height: "18px", width: "18px" }}
    />
  </PinMarker>
);

export { OfficeMarker };
