import { Analytics, ActionEvent, ScreenViewEvent } from './d';

import amplitude from './amplitude';
import analytics from './googleAnalytics';

// TODO that could be improved somehow
const composed = (...trackers: Analytics[]): Analytics => ({
  init: () =>
    trackers.forEach((tracker) => tracker.init()),

  startUserSession: (uid: string, group: string) =>
    trackers.forEach((tracker) => tracker.startUserSession(uid, group)),

  finishUserSession: () =>
    trackers.forEach((tracker) => tracker.finishUserSession()),

  trackEvent: (event: ActionEvent) =>
    trackers.forEach((tracker) => tracker.trackEvent(event)),

  trackScreenView: (event: ScreenViewEvent) =>
    trackers.forEach((tracker) => tracker.trackScreenView(event))
});

export default composed(amplitude, analytics);

export type {
  ActionEvent,
  ScreenViewEvent
};
