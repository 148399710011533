import { Channel } from "@capacitor/push-notifications";

export const NotificationChannel = {
  GENERAL: {
    id: "General",
    name: "General",
    description: "General notifications",
    importance: 4,
    visibility: 1,
    vibration: true,
  } as Channel,
};
