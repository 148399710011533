import { useEffect } from 'react';

import { Dictionary } from 'd';
import analytics, { ScreenViewEvent } from 'modules/analytics';

type OnScreenActionEvent = {
  action: string,
  category?: string,
  meta?: Dictionary
};

export const useAnalytics = (screen: ScreenViewEvent): (action: OnScreenActionEvent) => void => {
  useEffect(() => {
    analytics.trackScreenView(screen);
  }, []);

  return ({ category, ...others }: OnScreenActionEvent) =>
    analytics.trackEvent({
      ...others,
      category: category ? category : 'call-to-action',
      screen
    });
};
