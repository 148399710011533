export const orientation = ({
  orientation,
  maxWidth,
}: {
  orientation: "landscape" | "portrait";
  maxWidth?: number;
}) => {
  const maxWidthQuery = maxWidth ? ` and (max-width: ${maxWidth}px)` : "";
  return `@media only screen and (orientation:${orientation})${maxWidthQuery}`;
};
