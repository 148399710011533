import { FC } from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export type AlertProps = {
  title: string;
  text: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  handleTransitionEnd?: () => void;
  buttons?: ({
    text: string;
    onClick: () => void;
  } & Omit<ButtonProps, "onClick">)[];
};

const Alert: FC<AlertProps> = ({
  title,
  text,
  buttons = [],
  open,
  handleClose,
  handleTransitionEnd,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: () => handleTransitionEnd?.() }}
    >
      <DialogTitle>{title}</DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {buttons.map(({ onClick, text, ...buttonProps }, index) => (
          <Button key={index} onClick={onClick} {...buttonProps}>
            {text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export { Alert };
