import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { makeStyles } from "@mui/styles";

import {
  Button,
  Error,
  TextInput,
  View,
  Text,
  ErrorLabel,
} from "components/common";
import { PoolaLogoLayout } from "./PoolaLogoLayout";
import {
  Buttons,
  Containers,
  Inputs,
  Spacing,
  Typography,
  Colors,
} from "modules/styles";
import { SelfSignupData } from "services/auth/selfSignup";

const selectSiteSchema = (emailDomain: string) =>
  z.object({
    email: z
      .string()
      .trim()
      .email({ message: "invalid-email" })
      .endsWith(emailDomain, "invalid-domain"),
  });
type SelectSiteSchema = z.infer<ReturnType<typeof selectSiteSchema>>;

type Props = {
  loading: boolean;
  error?: string;
  selfSignupData: SelfSignupData;
  onSiteSelect: (arg: SelectSiteSchema) => void;
};

const SelfSignup: FC<Props> = ({
  loading,
  error,
  selfSignupData,
  onSiteSelect,
}) => {
  const classes = useStyles();
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.self-signup",
  });
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<SelectSiteSchema>({
    mode: "onTouched",
    resolver: zodResolver(selectSiteSchema(selfSignupData.emailDomain)),
  });

  const onSubmit = handleSubmit(onSiteSelect);

  return (
    <PoolaLogoLayout
      title={translate("header", { groupName: selfSignupData.groupName })}
    >
      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("email-label")}</Text>

        <TextInput
          className={classes.input}
          autoCapitalize="none"
          placeholder={translate("email-placeholder", {
            domain: selfSignupData.emailDomain,
          })}
          {...register("email")}
          onSubmitEditing={onSubmit}
        />
        {errors.email && (
          <ErrorLabel>
            {translate(errors.email.message!, {
              domain: selfSignupData.emailDomain,
            })}
          </ErrorLabel>
        )}
      </View>

      <Error message={error} />

      <Button
        title={translate("header", { groupName: selfSignupData.groupName })}
        disabled={!isValid || loading}
        sx={{ display: "block" }}
        buttonStyle={styles.button}
        onClick={onSubmit}
      />
    </PoolaLogoLayout>
  );
};

const useStyles = makeStyles({
  input: Inputs.light,
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  fieldContainer: {
    marginTop: Spacing.medium,
  },
});

const styles = {
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
  forgotButton: {
    ...Buttons.linkButtonView,
  },
  forgotButtonText: {
    ...Buttons.linkButtonText,
    fontSize: Typography.sizeSmallMedium,
  },
};

export { SelfSignup };
