import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { is } from "poola-commons/utils";

import { createSite, reQuerySite } from "./service";

const useCreateSite = () => {
  const queryClient = useQueryClient();

  return useMutation(createSite, {
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
};

const useReQuerySite = (path: string | undefined) => {
  return useQuery({
    queryKey: ["site", path],
    queryFn: () => reQuerySite(path!).then(() => null),
    enabled: is(path),
  });
};

export { useCreateSite, useReQuerySite };
