import { poolaStaffPath } from "poola-commons/utils";

export type LocationState = { previousRoute?: string } | undefined;

export const poolaStaffRoutes = {
  POOLA_MANAGEMENT: `/${poolaStaffPath}/management`,
} as const;

export const poolaNoGroupRoutes = {
  POOLA_SUBSCRIPTION: `/${poolaStaffPath}/subscription/:groupId/:planId/:token`,
  POOLA_SUBSCRIPTION_SUCCESS: `/${poolaStaffPath}/subscription/success`,
  POOLA_SELF_SIGNUP: `/${poolaStaffPath}/join/:encodedData`,
  POOLA_SELF_SIGNUP_CONFIRM: `/${poolaStaffPath}/join-confirm/:encodedData`,
} as const;

export const authRoutes = {
  LOGIN: "/login",
  IMPERSONATE: "/impersonate",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_GROUP: "/change-group",
  ENTER_GROUP: "/",
} as const;

export const createRoutes = {
  SITE_NAME: "/setup/name",
  SITE_DETAILS: "/setup/create",
};

export const onboardingRoutes = {
  ONBOARDING: "/onboarding",
  WELCOME: "/onboarding/welcome",
  HOW_TO_COMMUTE: "/onboarding/how-to-commute",
  ADD_CAR: "/onboarding/add-car",
  ADD_CAR_DETAILS: "/onboarding/add-car-details",
  WORKING_HOURS: "/onboarding/working-hours",
  PICK_UP_LOCATION: "/onboarding/pick-up-location",
  SELECT_ROUTE: "/onboarding/select-route",
  SUMMARY: "/onboarding/summary",
} as const;

export const daysListRoutes = {
  DAYS_LIST: "/days-list",
  JOIN_RIDE: "/days-list/join-ride/:day",
  ADD_RIDE: "/days-list/add-ride/:day",
} as const;

export const adminRoutes = {
  ADMIN: "/profile/admin",
  ADMIN_MAP: "/profile/admin-map",
  ADMIN_REPORTS: "/profile/reports",
  ADMIN_GROUP_SETTINGS: "/profile/admin-group-settings"
} as const;

export const profileRoutes = {
  PROFILE: "/profile",
  GROUP_SELECTION: "/profile/group-selection",
  PICK_UP_AREA_SELECTION: "/profile/pick-up-area-selection",
  CARS: "/profile/cars",
  CHANGE_PASSWORD: "/profile/change-password",
};

export const ongoingRoutes = {
  RIDE_CHAT: "/ride-chat/:driverUid/:day",
  LIFT_REQUEST_MAP: "/request/:day/:direction",
  RIDE_DRIVER: "/drive/:day/:direction",
};

export const reportsRotues = {
  REPORTS: "/reports",
};

export const communityRoutes = {
  COMMUNITY: "/community",
};

export const subscriptionRoutes = {
  SUBSCRIPTION: "/profile/subscription",
} as const;

export const routesMap = {
  ...authRoutes,
  ...onboardingRoutes,
  ...daysListRoutes,
  ...adminRoutes,
  ...profileRoutes,
  ...ongoingRoutes,
  ...reportsRotues,
  ...createRoutes,
  ...communityRoutes,
  ...subscriptionRoutes,
  ...poolaStaffRoutes,
  ...poolaNoGroupRoutes,
} as const;
