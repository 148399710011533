import { CSSProperties } from "react";

import { alpha, styled } from "@mui/material/styles";
import { touchRippleClasses } from "@mui/material/ButtonBase";

import { Touchable, TouchableProps } from "./Touchable";
import { Helper } from "modules/styles";

type Props = TouchableProps & {
  rippleColor?: CSSProperties["backgroundColor"];
  style?: CSSProperties;
  onClick: () => void;
};

const TouchableIcon = styled(
  Touchable,
  Helper.shouldForwardProp<Props>(["rippleColor"])
)<Props>(({ rippleColor }) => ({
  ...(rippleColor && {
    [`.${touchRippleClasses.child}`]: {
      backgroundColor: `${rippleColor} !important`,
    },
    "&:hover": {
      [`.${touchRippleClasses.root}`]: {
        backgroundColor: alpha(rippleColor, 0.04),
      },
    },
  }),
}));

export default TouchableIcon;
