import {
  CSSProperties,
  PropsWithChildren,
  useMemo,
  FunctionComponent,
} from "react";
import { renderToString } from "react-dom/server";

import { Marker, MarkerProps } from "react-leaflet";
import Leaflet from "leaflet";

import { makeStyles } from "@mui/styles";

import { Colors, Containers, Dimens } from "modules/styles";
import { View } from "components/common";

type Props = {
  style?: CSSProperties;
};

const getIcon = ({
  style,
  children,
  className,
}: PropsWithChildren<Props & { className: string }>) =>
  Leaflet.divIcon({
    html: renderToString(
      <View className={className} style={style}>
        {children}
      </View>
    ),
  });

const RoundMarker: FunctionComponent<
  PropsWithChildren<Props & MarkerProps>
> = ({ children, style, ...otherProps }) => {
  const classes = useClasses();
  const icon = useMemo(
    () =>
      getIcon({
        style,
        children,
        className: classes.container,
      }),
    [
      classes.container,
      (Array.isArray(otherProps.position) ? otherProps.position : []).join(),
    ]
  );
  return <Marker icon={icon} {...otherProps}></Marker>;
};

const useClasses = makeStyles({
  container: {
    ...Containers.shadowed,
    borderColor: "gray",
    borderWidth: 2,
    backgroundColor: Colors.primaryNegative,
    width: Dimens.extraLarge,
    height: Dimens.extraLarge,
    borderRadius: Dimens.medium,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export default RoundMarker;
