import { Observable } from "rxjs";
import { unreadMessagesCount$ } from ".";
import cacheWithTimeout from "modules/cache/cacheWithTimeout";

const ridesCache: { [rideIdentifier in string]: Observable<number> } = {};

const KEEP_SUBSCRIPTION_ALIVE_MS = 60 * 1000;

const multicastUnread$ = (
  rideIdentifier: string,
  uid: string
): Observable<number> => {
  const key = `${rideIdentifier}_${uid}`;
  const cachedRide = ridesCache[key];
  if (cachedRide) {
    return cachedRide;
  } else {
    const observableRide = cacheWithTimeout(
      unreadMessagesCount$(rideIdentifier, uid),
      KEEP_SUBSCRIPTION_ALIVE_MS
    );
    ridesCache[key] = observableRide;
    return observableRide;
  }
};

export { multicastUnread$ };
