import { FunctionComponent } from "react";
import { Polyline } from "react-leaflet";

import { Colors } from "modules/styles";

type RouteParams = {
  coordinates: [number, number][];
  color?: string;
  lineDashPattern?: number[];
  width?: number;
  tappable?: boolean;
  zIndex?: number;
  onClick?: () => void;
};

const Route: FunctionComponent<RouteParams> = ({
  coordinates,
  color = Colors.primary,
  width = 5,
  tappable,
  zIndex = 1,
  lineDashPattern,
  onClick,
}) => (
  <Polyline
    pathOptions={{
      weight: width,
      color,
      dashArray: lineDashPattern,
    }}
    positions={coordinates}
    eventHandlers={{
      click: (e) => {
        onClick && onClick();
      },
    }}

    // tappable={tappable}
    // zIndex={zIndex}
    // onClick={onClick}
  />
);

export { Route };
