import React, { CSSProperties } from "react";

import SvgIcon from "@mui/material/SvgIcon";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material/styles";

import { BackIconLink } from "./BackRoute";
import { Text } from "./Text";
import { Colors } from "modules/styles";

type Props = React.PropsWithChildren<{
  title?: React.ReactNode;
  backRoute?: string;
  backRouteIconColor?: CSSProperties["color"];
  sx?: SxProps<Theme>;
  rightIcon?: boolean;
  onClick?: () => void;
}>;

type FlatProps = Props & { center?: boolean };

export const Header: React.FC<Props> = ({
  backRoute,
  backRouteIconColor,
  rightIcon,
  title,
  children,
  sx,
  onClick,
}) => {
  return (
    <Paper
      square
      sx={{
        padding: 1,
        paddingTop: "calc(8px + env(safe-area-inset-top))",
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        ...sx,
      }}
    >
      <Box display="flex">
        <BackIconLink
          color={backRouteIconColor}
          backRoute={backRoute}
          onClick={onClick}
        />
        {title}
        {rightIcon && <SvgIcon aria-label="placeholder" />}
      </Box>
      {children}
    </Paper>
  );
};

export const FlatHeader: React.FC<FlatProps> = ({
  title,
  sx,
  center = false,
  ...props
}: FlatProps) => {
  return (
    <Header
      {...props}
      title={
        <Box
          display="flex"
          width="100%"
          sx={center ? { justifyContent: "center" } : { marginLeft: 1 }}
        >
          <Text>{title}</Text>
        </Box>
      }
      rightIcon={center}
      sx={{
        borderRadius: 0,
        boxShadow: "none",
        borderBottom: `1px solid ${Colors.primaryLight40}`,
        ...sx,
      }}
    />
  );
};
