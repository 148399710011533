import { ViewStyle } from "./d";

export const shouldForwardProp = <CustomProps extends Record<string, any>>(
  props: Array<keyof CustomProps>
) => ({
  shouldForwardProp: (prop: PropertyKey) => !props.includes(prop as string),
});

export const hidelLastChild: ViewStyle = {
  ":last-child": {
    display: "none",
  },
};

export const rowGap = (n: number | string) => {
  const marginLeft = typeof n === "string" ? n : n + "px";
  return {
    "& > *+*": { marginLeft: `${marginLeft} !important` },
  };
};

export const columnGap = (n: number | string) => {
  const marginTop = typeof n === "string" ? n : n + "px";
  return {
    "& > *+*": { marginTop: `${marginTop} !important` },
  };
};
