// import googleAnalytics, { FirebaseAnalyticsTypes } from '@react-native-firebase/analytics';

import { Analytics, ActionEvent, ScreenViewEvent } from "./d";
import { flatten } from "modules/utils";

const analytics = (
  instance: /*FirebaseAnalyticsTypes.Module*/ any = {
    setUserId: () => {},
    setUserProperties: () => {},
    logEvent: () => {},
    setCurrentScreen: () => {},
  }
): Analytics => ({
  init: () => {
    // no-op
  },
  startUserSession: (uid: string, group: string) => {
    instance.setUserId(uid);
    instance.setUserProperties({ group });
  },
  finishUserSession: () => {
    instance.setUserId(null);
  },
  trackEvent: ({ action, category, screen, meta }: ActionEvent) => {
    instance.logEvent(action, { category, ...flatten({ screen, meta }) });
  },
  trackScreenView: ({ flow, screen, meta }: ScreenViewEvent) => {
    instance.setCurrentScreen(`${flow}-${screen}`, screen);
    instance.logEvent(screen, { flow, ...flatten({ meta }) });
    instance.logEvent(screen, { flow, ...flatten({ meta }) });
  },
});

export default analytics(/*googleAnalytics()*/);
