import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clx from "classnames";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Button, Text, TextInput, View } from "components/common";
import { rowGap } from "modules/styles/helper";
import { UserContext } from "modules/context";
import { PoolaLogoLayout } from "./PoolaLogoLayout";
import { routesMap } from "views/routesMap";
import { logOut } from "services/auth";
import {
  Colors,
  Containers,
  Inputs,
  Spacing,
  Typography,
} from "modules/styles";

export const SiteName = () => {
  const classes = useStyles();
  const history = useHistory<
    | {
        siteName: string;
        siteIdentifier: string;
      }
    | undefined
  >();
  const locationState = history.location.state;

  const { user } = React.useContext(UserContext);
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "view.auth.create-site",
  });
  const [siteName, setSiteName] = React.useState<string>(
    locationState?.siteName || ""
  );
  const [siteIdentifier, setSiteIdentifier] = React.useState<string>(
    locationState?.siteIdentifier || ""
  );

  const validSiteName = isValidSiteName(siteName);
  const validSiteIdentifier = isValidSiteIdentifier(siteIdentifier);
  const valid = validSiteName && validSiteIdentifier;

  const handleSetupNext = () => {
    if (valid) {
      history.push(routesMap.SITE_DETAILS, {
        siteName,
        siteIdentifier: siteIdentifier.trim(),
      });
    }
  };

  const handleLogout = () => {
    logOut();
  };

  return (
    <PoolaLogoLayout title={translate("create-site-title")}>
      {user && (
        <View className={classes.user}>
          <View className={classes.flexRow}>
            <AccountCircleIcon sx={{ color: Colors.white }} />
            <Text className={classes.labelBold}>{user.firebaseUser.name}</Text>
          </View>
          <TextLink onClick={handleLogout}>
            {translate("log-out-button")}
            <LogoutIcon
              sx={{ fontSize: Typography.sizeSmallMedium, marginLeft: 0.5 }}
            />
          </TextLink>
        </View>
      )}
      <View className={classes.fieldContainer}>
        <Text className={classes.label}>{translate("site-name-label")}</Text>

        <TextInput
          type="group_name"
          className={classes.input}
          autoCapitalize="none"
          value={siteName}
          onChangeText={setSiteName}
          onSubmitEditing={handleSetupNext}
          placeholder={translate("site-name-placeholder")}
        />
        <Text
          className={clx(classes.hint, {
            [classes.hintSuccess]: validSiteName,
          })}
        >
          {translate("site-name-validation", { minCharacters: 6 })}
        </Text>
      </View>

      <View className={classes.fieldContainer}>
        <Text className={classes.label}>
          {translate("site-identifier-label")}
        </Text>

        <TextInput
          type="group_identifier"
          className={classes.input}
          autoCapitalize="none"
          placeholder={translate("site-identifier-placeholder")}
          value={siteIdentifier}
          onChangeText={(text) =>
            setSiteIdentifier(hyphenateSiteIdentifier(text))
          }
          onSubmitEditing={handleSetupNext}
        />
        <Text
          className={clx(classes.hint, {
            [classes.hintSuccess]: validSiteIdentifier,
          })}
        >
          {translate("site-identifier-validation", {
            minCharacters: 6,
            maxCharacters: 20,
          })}
        </Text>
      </View>

      <Button
        title={translate("next-btn")}
        disabled={!valid}
        sx={{ display: "block" }}
        buttonStyle={styles.button}
        onClick={handleSetupNext}
      />
    </PoolaLogoLayout>
  );
};

const TextLink = styled(Text)({
  cursor: "pointer",
  color: Colors.primaryNegative,
  fontSize: Typography.sizeSmallMedium,
  textDecoration: "underline",
  textDecorationColor: alpha(Colors.primaryNegative, 0.4),
  display: "flex",
  alignItems: "center",
  "&:hover": {
    textDecorationColor: Colors.primaryNegative,
  },
});

const isValidSiteName = (siteName: string) => {
  return siteName.length >= 6 && /[a-zA-Z0-9]/.test(siteName);
};

const isValidSiteIdentifier = (siteIdentifier: string) => {
  return (
    siteIdentifier.length >= 6 &&
    siteIdentifier.length <= 20 &&
    /[a-zA-Z0-9]/.test(siteIdentifier)
  );
};

const hyphenateSiteIdentifier = (siteIdentifier: string) => {
  return siteIdentifier.split(" ").join("-").toLowerCase();
};

const useStyles = makeStyles({
  input: { ...Inputs.light, paddingRight: 52 },
  label: {
    ...Typography.body,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  labelBold: {
    ...Typography.bold,
    fontSize: Typography.sizeMedium,
    color: Colors.primaryNegative,
  },
  hint: {
    fontSize: Typography.sizeSmallMedium,
    color: Colors.primaryNegative85,
  },
  hintSuccess: {
    color: Colors.accent,
  },
  flexRow: {
    flexDirection: "row",
    ...rowGap(8),
  },
  fieldContainer: {
    marginTop: Spacing.medium,
  },
  user: {
    alignItems: "center",
  },
});

const styles = {
  button: {
    ...Containers.shadowed,
    marginTop: Spacing.huge,
  },
};
