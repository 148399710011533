import { useEffect, useState } from 'react';

enum Interval {
  SECOND = 1000,
  MINUTE = 60000,
}

const useTime = <T>(interval: Interval) => {
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const now = Date.now();
    const wait = interval - (now % interval);
    const timeout = setTimeout(() => {
      setTime(Date.now());
    }, wait);
    return () => clearTimeout(timeout);
  }, [time]);

  return time;
};

export { useTime, Interval };
