import map from "lodash/map";

import { Map } from "poola-commons/types";
import { isNot } from "poola-commons/utils";

import { API_URL, PUBLIC_URL } from "modules/config";
import { getFirebaseApp } from "./firebase/firebase";

const getAuthToken = async (): Promise<string> => {
  const user = getFirebaseApp().auth().currentUser;

  if (user) {
    return await user.getIdToken();
  }

  return Promise.reject({
    status: 401,
    code: "api/unauthorized",
    message: "User is not authorized",
  });
};

const jsonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const createAuthHeaders = async () => ({
  Authorization: `Bearer ${await getAuthToken()}`,
});

export const mapResponseOrReject = async <T>(
  response: Response
): Promise<T> => {
  if (response.ok) {
    return (await response.json()) as T;
  }

  const { status, statusText } = response;

  return Promise.reject({
    status: status,
    code: "api/unknown",
    message: statusText || `Error ${status}`,
  });
};

const stringify = (params?: Map<any>): string => {
  if (params) {
    const mapped = map(
      params,
      (value: any, key: string) => `${key}=${value}`
    ).join("&");

    return `?${mapped}`;
  }

  return "";
};

const call = async <T>(
  endpoint: string,
  body: any,
  method: string,
  headers: Map<string> = {},
  authorized: boolean = true
): Promise<T> =>
  fetch(`${authorized ? API_URL : PUBLIC_URL}${endpoint}`, {
    body: JSON.stringify(body),
    method,
    headers: {
      ...(authorized && isNot(headers.Authorization)
        ? await createAuthHeaders()
        : {}),
      ...jsonHeaders,
      ...headers,
    },
  }).then((response) => mapResponseOrReject<T>(response));

export const post = async <T>(
  endpoint: string,
  body: any,
  headers: Map<string> = {},
  authorized?: boolean
): Promise<T> => call(endpoint, body, "POST", headers, authorized);

export const put = async <T>(
  endpoint: string,
  body: any,
  headers: Map<string> = {},
  authorized?: boolean
): Promise<T> => call(endpoint, body, "PUT", headers, authorized);

export const get = async <T>(
  endpoint: string,
  params?: Map<any>,
  baseUrl: string = API_URL,
  authorized: boolean = true,
  headers: Map<string> = {}
): Promise<T> =>
  fetch(`${baseUrl}${endpoint}${stringify(params)}`, {
    method: "GET",
    headers: {
      ...(authorized ? await createAuthHeaders() : {}),
      ...headers,
    },
  }).then((response) => mapResponseOrReject<T>(response));

export const patch = async <T>(
  endpoint: string,
  body: any,
  headers: Map<string> = {},
  authorized?: boolean
): Promise<T> => call(endpoint, body, "PATCH", headers, authorized);

// Ugh, delete keyword is reserved
export const remove = async <T>(
  endpoint: string,
  body?: any, // this should be never, but we have remove calls with body :/
  headers: Map<string> = {},
  authorized: boolean = true
): Promise<T> => call(endpoint, body, "DELETE", headers, authorized);
