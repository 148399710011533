import { ComponentProps, forwardRef } from "react";

import { styled } from "@mui/material/styles";
import MuiSelect, { selectClasses } from "@mui/material/Select";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

import { Colors, Helper, Inputs, Typography } from "modules/styles";
import { useStateDelayed } from "modules/hooks";

export const Select = forwardRef<any, ComponentProps<typeof StyledSelect>>(
  (props, ref) => {
    return (
      <StyledSelect
        size="small"
        displayEmpty
        {...props}
        MenuProps={{
          ...props.MenuProps,
          PaperProps: { sx: { marginTop: 1 } },
        }}
        ref={ref}
      />
    );
  }
);

export const BaseSelect = <T extends string | number>({
  options,
  value,
  onChange,
  placeholder,
  ...rest
}: {
  options: { label: string | React.ReactElement; value: T }[];
  value: T;
  onChange: (value: T) => void;
} & Omit<ComponentProps<typeof StyledSelect>, "onChange">) => {
  const [select, setSelect] = useStateDelayed(false);
  return (
    <Select
      {...rest}
      open={select}
      onOpen={setSelect(true)}
      onClose={setSelect(false)}
      value={value}
      onChange={(event) => onChange(event.target.value as T)}
      placeholder={placeholder}
    >
      {placeholder && (
        <StyledMenuItem disabled value={value.valueOf()}>
          {placeholder}
        </StyledMenuItem>
      )}
      {options.map(({ label, value }) => (
        <StyledMenuItem value={value} key={value}>
          {label}
        </StyledMenuItem>
      ))}
    </Select>
  );
};

type Props = {
  borderColor?: string;
};

const StyledSelect = styled(
  MuiSelect,
  Helper.shouldForwardProp<Props>(["borderColor"])
)<Props>(({ borderColor = Colors.accent }) => ({
  ...Inputs.light,
  [`& .${selectClasses.select}`]: {
    padding: 0,
    paddingRight: `18px !important`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldset: {
    border: "none !important",
  },
  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      boxShadow: `0 0 0 2px inset ${borderColor}, 0 0 0 1px #fff`,
    },
}));

const StyledMenuItem = styled(MenuItem)({
  ...Typography.regular,
  justifyContent: "space-between",
});
