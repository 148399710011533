import { useEffect, useMemo } from "react";
import { ReactFCC } from "types/react";

import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

const STORAGE_WRITE_THROTTLE = 1000;

/**
 * PersistQueryClientProvider doesn't allow us to invalidate
 * (re-fetch) queries after restoring them from storage. Storage
 * read promise is returned to early to work. We use an (I agree)
 * ugly way to refetch queries after restoring, but not on each
 * mount.
 */
const REFETCH_QUERIES_AFTER_RESTORING = 1000;

const ReactQueryPersistentClientProvider: ReactFCC<{
  organisationId: string;
}> = ({ children, organisationId }) => {
  const [persister, queryClient] = useMemo(() => {
    const persister = createSyncStoragePersister({
      storage: window.localStorage,
      throttleTime: STORAGE_WRITE_THROTTLE,
      key: organisationId,
    });
    const queryClient = new QueryClient();
    return [persister, queryClient];
  }, [organisationId]);

  useEffect(() => {
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, REFETCH_QUERIES_AFTER_RESTORING);
  }, []);

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      {children}
    </PersistQueryClientProvider>
  );
};

export { ReactQueryPersistentClientProvider };
