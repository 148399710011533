import { TextStyle, ViewStyle } from './d';

import * as Colors from './colors';
import * as Typography from './typography';
import * as Containers from './containers';
import * as Dimens from './dimens';

export const linkButtonView: ViewStyle = {
  backgroundColor: 'transparent'
};

export const linkButtonText: TextStyle = {
  ...Typography.body,
  color: Colors.accent
};

export const primaryButtonView: ViewStyle = {
  padding: 12,
  backgroundColor: Colors.accent
};

export const primaryButtonDisabledView: ViewStyle = {
  padding: 12,
  backgroundColor: Colors.disabledBackground
};

export const primaryButtonText: TextStyle = {
  ...Typography.body,
  ...Typography.center,
  fontSize: Typography.sizeMedium,
  color: Colors.primaryNegative
};

export const onboardingButtonView: ViewStyle = {
  ...Containers.shadowed,
  backgroundColor: Colors.primaryNegative,
  borderRadius: Dimens.large
};

export const onboardingButtonText: TextStyle = {
  ...Typography.body,
  fontSize: Typography.sizeMedium,
};
