import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

import { Helper } from "modules/styles";

type Props = { show: boolean; passPointerEvents?: boolean };

export const Opacity = styled(
  Box,
  Helper.shouldForwardProp<Props>(["show", "passPointerEvents"])
)(({ show, passPointerEvents = false }: Props) =>
  show
    ? {
        pointerEvents: passPointerEvents ? "auto" : "none",
        opacity: 0.4,
        height: "100%",
      }
    : { height: "100%" }
);
