import { alpha } from "@mui/system/colorManipulator";

import { ViewStyle } from "./d";

import * as Colors from "./colors";
import * as Dimens from "./dimens";
import * as Spacing from "./spacing";

export const base: ViewStyle = {
  flex: 1,
  padding: Spacing.large,
};

export const absolute: ViewStyle = {
  position: "absolute",
  top: 0,
  left: 0,
};

export const shadowCss = (
  elevation: number,
  direction: "top" | "bottom" | "left" | "right" = "bottom"
) => {
  if (elevation === 0) {
    return "none";
  }
  let shadowX = 0;
  let shadowY = 0;
  switch (direction) {
    case "bottom":
      shadowY = 2;
      break;
    case "top":
      shadowY = -2;
      break;
    case "left":
      shadowX = -2;
      break;
    case "right":
      shadowX = 2;
      break;
  }

  return `${alpha(Colors.primary, 0.25)} ${shadowX}px ${shadowY}px ${2 + elevation
    }.84px`;
};
export const shadowed: ViewStyle = {
  boxShadow: shadowCss(1),
};

export const getShadowed = (elevation: number): ViewStyle => ({
  boxShadow: shadowCss(elevation),
});

export const card: ViewStyle = {
  ...shadowed,
  borderRadius: Dimens.small,
};
