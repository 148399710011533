import { FC, PropsWithChildren, useEffect } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { Trans } from "react-i18next";

import moment from "moment";

import { styled } from "@mui/material/styles";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";

import { beginOfDay } from "poola-commons/utils";

import { useAuthenticatedUser } from "modules/context";
import { Colors, Typography } from "modules/styles";
import { daysListRoutes, LocationState } from "views/routesMap";
import { FlatHeader, Text } from "components/common";
import { View } from "components/common";

const ChatLayout: FC<
  PropsWithChildren<RouteComponentProps<{}, StaticContext, LocationState>>
> = ({ children, location, match }) => {
  const matchParams = match.params as { day: string; driverUid: string };
  const day = Number(matchParams.day);

  const {
    currentGroup: { timezone },
  } = useAuthenticatedUser();

  return (
    <View
      style={{
        height: "100vh",
      }}
    >
      <FlatHeader
        backRoute={location.state?.previousRoute ?? daysListRoutes.DAYS_LIST}
        backRouteIconColor={Colors.accent}
        center
        title={
          <Trans
            i18nKey={`view.messages.title-conversation`}
            values={{
              day: moment(beginOfDay(day, timezone)).format("MMMM Do YYYY"),
            }}
            components={{
              bold: <RideDate />,
            }}
          />
        }
      />
      {children}
    </View>
  );
};

const RideDate = styled(Text)({
  ...Typography.semiBold,
});

export { ChatLayout };
