import React from "react";
import { View, Text, ScrollView } from "components/common";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import {
  Colors,
  Containers,
  Inputs,
  Spacing,
  Typography,
} from "modules/styles";
import logo from "img/poola.png";

type Props = {
  title?: React.ReactNode;
};

export const PoolaLogoLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  const classes = useStyles();

  return (
    <ScrollView
      contentContainerStyle={{
        flex: 1,
      }}
      className={classes.container}
    >
      <View className={classes.header}>
        <img
          src={logo}
          style={{
            maxWidth: "80%",
            maxHeight: "70px",
          }}
        />
        {title &&
          (typeof title === "string" ? (
            <CarpoolingSite>{title}</CarpoolingSite>
          ) : (
            title
          ))}
      </View>

      <View
        style={{
          flexGrow: 3,
          justifyContent: "center",
          paddingLeft: "1px",
          paddingRight: "1px",
        }}
      >
        {children}
      </View>
    </ScrollView>
  );
};

const useStyles = makeStyles({
  container: {
    ...Containers.base,
    backgroundColor: Colors.primary,
    justifyContent: "space-around",
  },
  header: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    color: Colors.accent,
  },
});

const GradientText = styled(Text)(
  Typography.gradient(50, Colors.secondary, Colors.accent)
);

const CarpoolingSite = styled(GradientText)({
  marginTop: Spacing.large,
  fontSize: Typography.sizeRegular,
  textAlign: "center",
  ...Typography.semiBold,
});
