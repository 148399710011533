import { useState, useRef } from "react";

export type Action<T, R> = (t: T) => Promise<R>;

type ProgressState<E, R> = {
  inProgress: boolean;
  result: R | undefined;
  error: E | undefined;
  success: boolean | undefined;
};

const DEFAULT_SETTINGS = { swallowErrors: true, allowStale: false };

const useProgress = <T = void, R = unknown, E = any>(
  action: Action<T, R>,
  settings: { swallowErrors?: boolean; allowStale?: boolean } = {}
): [
  Action<T, R | void>,
  {
    result: R | undefined;
    success: boolean | undefined;
    inProgress: boolean;
    error: E | undefined;
  }
] => {
  const [state, setState] = useState<ProgressState<E, R>>({
    inProgress: false,
    result: undefined,
    success: undefined,
    error: undefined,
  });
  const recentTaskIdentifier = useRef<Object>();
  const { swallowErrors, allowStale } = { ...DEFAULT_SETTINGS, ...settings };
  return [
    (arg: T) => {
      const taskIdentifier = {};
      recentTaskIdentifier.current = taskIdentifier;
      setState({
        inProgress: true,
        result: allowStale ? state.result : undefined,
        success: undefined,
        error: undefined,
      });
      return action(arg)
        .then((result) => {
          if (recentTaskIdentifier.current === taskIdentifier) {
            setState({
              inProgress: false,
              success: true,
              error: undefined,
              result,
            });
            return result;
          }
        })
        .catch((error) => {
          if (recentTaskIdentifier.current === taskIdentifier) {
            setState({
              inProgress: false,
              success: false,
              result: undefined,
              error,
            });
            return swallowErrors ? Promise.resolve() : Promise.reject(error);
          }
        });
    },
    state,
  ];
};

export { useProgress };
