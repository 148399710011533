import React, { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useTable,
  usePagination,
  useSortBy,
  Column,
  useGlobalFilter,
  useFilters,
  FilterProps,
} from "react-table";

import isEmpty from "lodash/isEmpty";

import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableProps as MuiTableProps } from "@mui/material/Table";

import {
  RelativeTableBox,
  AbsoluteProgress,
  EmptyRow,
  FillMissingRows,
  StateTableView,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  type BaseTableTrans,
} from "./TableComponents";
import {
  EnhancedHeader,
  EnhancedHeaderProps,
} from "components/common/enhanced-table/EnhancedHeader";
import TextField from "@mui/material/TextField";

type TableProps<P extends object> = {
  columns: Column<P>[];
  data: P[];
  isLoading?: boolean;
  fixHeight?: boolean;
  size?: MuiTableProps["size"];
  autoResetSortBy?: boolean;
  scrollable?: boolean;
  autoResetPage?: boolean;
  tableLayout?: React.CSSProperties["tableLayout"];
  translations?: BaseTableTrans;
  initialPageSize?: number;
  withHeader?: ReactNode;
  hidePagination?: boolean;
  enhancedHeaderProps?: Pick<EnhancedHeaderProps, "csv" | "actions">;
};

const StickyTableBG = "#f0f0f0";

const BaseTable = <P extends object>({
  columns,
  data,
  size = "medium",
  fixHeight = true,
  isLoading = false,
  autoResetSortBy = true,
  scrollable = false,
  autoResetPage = true,
  tableLayout = "unset",
  translations,
  initialPageSize,
  withHeader,
  hidePagination,
  enhancedHeaderProps,
}: TableProps<P>) => {
  const { t: translate } = useTranslation(undefined, {
    keyPrefix: "component",
  });

  const defaultBaseTableTrans: BaseTableTrans = translate("table", {
    returnObjects: true,
  });

  const t = {
    ...defaultBaseTableTrans,
    ...translations,
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetSortBy,
      autoResetPage,
      initialState: initialPageSize
        ? {
            pageSize: initialPageSize,
            pageIndex: 0,
          }
        : undefined,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const someCanFilter = useMemo(() => {
    return headerGroups.some(({ headers }) =>
      headers.some((header) => header.canFilter && header.filter)
    );
  }, [headerGroups]);

  const stateTableText = isLoading
    ? t.labelDataLoading
    : isEmpty(data)
    ? t.labelNoData
    : null;

  return (
    <RelativeTableBox scrollable={scrollable}>
      {isLoading && <AbsoluteProgress />}
      {withHeader && (
        <EnhancedHeader
          filterProps={{
            globalFilter,
            setGlobalFilter,
            filterPlaceholder: t.filterPlaceholder,
          }}
          {...enhancedHeaderProps}
        />
      )}
      <TableContainer scrollable={scrollable}>
        <Table {...getTableProps()} size={size} stickyHeader={scrollable}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...column.getHeaderProps(
                        column.canSort
                          ? column.getSortByToggleProps()
                          : undefined
                      )}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            {someCanFilter &&
              headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        style={{ top: size === "small" ? 37 : 57 }}
                        {...column.getHeaderProps()}
                      >
                        {column.canFilter && column.filter
                          ? column.render("Filter")
                          : null}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableHead>
          <TableBody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell
                      {...{
                        ...cell.column.extraCellProps,
                        ...cell?.getCellProps(),
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {fixHeight && (
              <FillMissingRows
                rowsCount={pageSize - page.length}
                EmptyRow={EmptyRow}
              />
            )}
          </TableBody>
        </Table>
        {!!stateTableText && (
          <StateTableView stateText={stateTableText} isOverlay={fixHeight} />
        )}
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: t.allRowsPerPageOption!, value: page.length },
          ]}
          labelRowsPerPage={t.labelRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            translate("table.labelDisplayedRows", { from, to, count })
          }
          getItemAriaLabel={() => ""}
          count={rows.length}
          component={"div"}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            native: true,
          }}
          onPageChange={(_event, pageNumber) => gotoPage(pageNumber)}
          onRowsPerPageChange={({ target: { value } }) => {
            setPageSize(Number(value));
          }}
        />
      )}
    </RelativeTableBox>
  );
};

// Define a default UI for filtering
const DefaultColumnFilter = <T extends object>({
  column: { filterValue, preFilteredRows, setFilter },
}: FilterProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: "component.table" });
  const count = preFilteredRows.length;

  return (
    <TextField
      value={filterValue || ""}
      placeholder={t("filterRecordsPlaceholder", { count })}
      variant="standard"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export { BaseTable };
