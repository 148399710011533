import React, { CSSProperties, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { formatTime } from "modules/date";
import { Touchable } from "./Touchable";
import { View } from "./View";
import { Text } from "./Text";
import DateTimePicker from "./DateTimePicker";

type Props = {
  time: Date;
  onTimeChanged: (time: Date) => void;
  max?: Date | undefined;
  min?: Date | undefined;
  disabled?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  viewInputStyle?: CSSProperties;
  textInputStyle?: CSSProperties;
  mode?: "time" | "date" | "datetime";
  color: CSSProperties["color"];
  render?: (time: Date) => React.ReactNode;
};

const InputDateTimePicker: FunctionComponent<Props> = ({
  time,
  onTimeChanged,
  max,
  min,
  confirmLabel,
  cancelLabel,
  textInputStyle,
  viewInputStyle,
  disabled,
  color,
  mode = "time",
  render,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "component.time-picker",
  });

  const _renderDateTime = () => {
    if (render) {
      return <Touchable style={textInputStyle}>{render(time)}</Touchable>;
    }

    return <Text style={textInputStyle}>{formatTime(time)}</Text>;
  };

  return (
    <View style={viewInputStyle}>
      {_renderDateTime()}

      <DateTimePicker
        time={time}
        mode={mode}
        max={max}
        min={min}
        disabled={disabled}
        cancelLabel={cancelLabel || t("cancel-label")}
        confirmLabel={confirmLabel || t("confirm-label")}
        color={color}
        onTimeChanged={onTimeChanged}
      />
    </View>
  );
};

export { InputDateTimePicker };
