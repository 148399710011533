import { CSSProperties, FunctionComponent } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

import { Colors } from "modules/styles";

type Props = {
  size?: CircularProgressProps["size"];
  color?: string;
  style?: CSSProperties;
};

const Spinner: FunctionComponent<Props> = ({
  size = "large",
  color = Colors.accent,
  style,
}) => (
  <Container style={style}>
    <CircularProgress size={size} color={"primary"} />
  </Container>
);

const Container = styled(Box)(({ theme }) => ({
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
}));

export { Spinner };
