import { PushNotifications } from "@capacitor/push-notifications";

import type { PostNotificationTokenRequest } from "poola-commons/types";

import { post } from "./../api";

export const registerUserToken = (request: PostNotificationTokenRequest) => {
  const url = `/notifications/token`;
  return post(url, request);
};

export const registerPushNotifications = async (): Promise<void> => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    throw new Error("User denied permissions!");
  }

  return PushNotifications.register();
};

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.debug("delivered notifications", notificationList);
};
