import { ViewStyle } from "./d";

const base = {
  duration: 350,
  useNativeDriver: true,
};

export const fadeIn = {
  ...base,
  toValue: 1,
};

export const fadeOut = {
  ...base,
  toValue: 0,
};

/**
 *
 * @param colorFrom : color from which the animation starts;
 * @param colorTo : color to which the animation ends;
 * @param speed : speed of the animation in seconds, default is 2.2s;
 */
export const backgroundColorPulsationAnimation = (
  colorFrom: string,
  colorTo: string,
  speed: number = 2.2
): ViewStyle => ({
  animation: `backgroundPulsation ${speed}s infinite`,
  keyframes: {
    "@keyframes backgroundPulsation": {
      "0%": { backgroundColor: colorFrom },
      "50%": { backgroundColor: colorTo },
      "100%": { backgroundColor: colorFrom },
    },
  },
});

/**
 *
 * @param color : main color of the pulse;
 * @param speed : speed of the pulse in seconds, default is 2s;
 * @param spread : spread of the pulse, default is 10px;
 */
export const pulseShadowAnimation = (
  color: string,
  speed: number = 2,
  spread: number = 10
): ViewStyle => ({
  animation: `pulseShadow ${speed}s infinite`,
  keyframes: {
    "@keyframes pulseShadow": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: `0 0 0 0 ${color}`,
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: `0 0 0 ${spread}px transparent`,
      },

      "100%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 transparent",
      },
    },
  },
});
