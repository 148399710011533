import "leaflet/dist/leaflet.css";
import React, { FC, forwardRef } from "react";
import {
  TileLayer,
  ScaleControl,
  MapContainer,
  MapContainerProps,
  useMapEvents,
} from "react-leaflet";
import { Map, LeafletEventHandlerFnMap } from "leaflet";

import PlaceIcon from "@mui/icons-material/Place";
import { styled } from "@mui/material/styles";

import { Coordinate } from "poola-commons/types";

import { View } from "components/common";
import { Dimens } from "modules/styles";
import { rowGap } from "modules/styles/helper";

type MapEvents = {
  onClick?: (coordinate: Coordinate, map: Map) => void;
  onMoveEnd?: (
    data: {
      center: Coordinate;
      // bounds: [Coordinate]
    },
    map: Map
  ) => void;
};

type Props = {
  focus?: Coordinate;
  zoom?: number;
  locationPin?: boolean;
  overlay?: React.ReactNode;
  children?: React.ReactNode;
} & MapContainerProps &
  MapEvents;

const MapView = forwardRef<Map, Props>(
  (
    {
      children,
      focus,
      locationPin,
      zoom,
      overlay,
      onClick,
      onMoveEnd,
      style,
      className,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <MapContainer
            center={focus}
            zoom={zoom}
            style={{
              flex: 1,
              zIndex: 0,
              ...style,
            }}
            ref={ref}
            className={className}
            {...rest}
          >
            <MapEventsHandler onClick={onClick} onMoveEnd={onMoveEnd} />
            <TileLayer url="https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png@2x" />
            {locationPin && (
              <LocationPinView>
                <PlaceIcon
                  fontSize={"large"}
                  color={"secondary"}
                  sx={{ transform: "translateY(-33%)" }}
                />
              </LocationPinView>
            )}
            {children}
            <ScaleControl />
          </MapContainer>
          {overlay}
        </div>
      </>
    );
  }
);

const LocationPinView = styled(View)({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  pointerEvents: "none",
});

const MapEventsHandler: FC<MapEvents> = ({ onClick, onMoveEnd }) => {
  const listeners: LeafletEventHandlerFnMap = {};
  if (onClick) {
    listeners.click = ({ latlng: { lat, lng } }) => {
      onClick([lat, lng], map);
    };
  }
  if (onMoveEnd) {
    listeners.moveend = (e) => {
      const { lat, lng } = e.target.getCenter();
      //   e.target.getBounds();
      onMoveEnd({ center: [lat, lng] }, map);
    };
  }

  const map = useMapEvents(listeners);

  return null;
};

const MapActionsContainer = styled(View)(({ theme }) => ({
  zIndex: 1000,
  flexDirection: "row",
  ...rowGap(theme.spacing(1)),
  alignItems: "end",
  position: "absolute",
  left: Dimens.medium,
  right: Dimens.medium,
  bottom: Dimens.medium,
  pointerEvents: "auto",
}));

const MapHeaderContainer = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  pointerEvents: "auto",
  zIndex: 1001,
});

const MapOverlay: FC<{
  header: React.ReactNode;
  actions: React.ReactNode;
}> = ({ header, actions }) => {
  return (
    <>
      <MapHeaderContainer>{header}</MapHeaderContainer>
      <MapActionsContainer>{actions}</MapActionsContainer>
    </>
  );
};

export { MapView, MapOverlay };
