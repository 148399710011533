const CURRENT_GROUP_ID_KEY = "current-group";

export const useGroupId = () => {
  return window.localStorage.getItem(CURRENT_GROUP_ID_KEY);
};

export const updateGroupId = (groupId: string) => {
  window.localStorage.setItem(CURRENT_GROUP_ID_KEY, groupId);
  window.location.reload();
};
