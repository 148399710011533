import { CSSProperties, FunctionComponent } from "react";

import Avatar from "@mui/material/Avatar";

import SvgIcon from "@mui/material/SvgIcon";
import PersonIcon from "@mui/icons-material/Person";
import { makeStyles } from "@mui/styles";

import { Colors, Containers } from "modules/styles";
import { View } from "components/common/View";

type Props = {
  photoUrl?: string;
  size: number;
  style?: CSSProperties;
  DefaultIcon?: typeof SvgIcon;
};

const CustomAvatar: FunctionComponent<Props> = ({
  photoUrl,
  size,
  style,
  DefaultIcon = PersonIcon,
}) => {
  const classes = useClasses();

  return (
    <View
      className={classes.container}
      style={{ width: size, height: size, borderRadius: size / 2, ...style }}
    >
      {photoUrl && (
        <img
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
            objectFit: "cover",
          }}
          src={photoUrl}
          //   source={{
          //     uri: photoUrl,
          //     priority: FastImage.priority.normal,
          //   }}
          //   resizeMode={FastImage.resizeMode.cover}
        />
      )}

      {!photoUrl && (
        <DefaultIcon
          sx={{
            color: Colors.primaryLight40,
            width: 0.75 * size,
            height: 0.75 * size,
          }}
        />
      )}
    </View>
  );
};

const useClasses = makeStyles({
  container: {
    ...Containers.shadowed,
    backgroundColor: Colors.primaryNegative,
    justifyContent: "center",
    alignItems: "center",
  },
});

export { Avatar, CustomAvatar };
