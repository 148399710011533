import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Capacitor } from "@capacitor/core";

import { rgbToHex } from "@mui/system/colorManipulator";

import { Colors } from "modules/styles";
import { updateStatusBar } from "capacitor/StatusBar";

const useChangeStatusBarStyle = (hexColor: string) => {
  return useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      updateStatusBar(hexColor);
    }
  }, [hexColor]);
};

export const StatusBarContext = createContext<{
  color: string;
  setColor: (hexColor: string) => void;
}>({
  color: Colors.primary,
  setColor: () => {},
});

const StatusBarContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [color, setColor] = useState(Colors.primary);
  useChangeStatusBarStyle(color);
  return (
    <StatusBarContext.Provider value={{ color, setColor }}>
      {children}
    </StatusBarContext.Provider>
  );
};

enum ColorFormat {
  RGB,
  HEX,
}

const useStatusBar = (color: string) => {
  const { setColor } = useContext(StatusBarContext);
  const format = color[0] === "#" ? ColorFormat.HEX : ColorFormat.RGB;

  useEffect(() => {
    setColor(format === ColorFormat.HEX ? color : rgbToHex(color));
  }, [color]);
};

export { useStatusBar, StatusBarContextProvider };
