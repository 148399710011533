import { TextStyle } from "./d";

import * as Colors from "./colors";
import * as Dimens from "./dimens";
import * as Spacing from "./spacing";
import * as Typography from "./typography";

export const base: TextStyle = {
  ...Typography.body,
  fontSize: Typography.sizeMedium,
  color: Colors.primary,
  marginTop: Spacing.small,
  marginBottom: Spacing.small,
  padding: Spacing.extraSmall,
};

export const dark: TextStyle = {
  ...base,
  borderRadius: Dimens.large,
  borderBottomRightRadius: 0,
  paddingTop: Spacing.small,
  paddingBottom: Spacing.small,
  paddingRight: Spacing.large,
  paddingLeft: Spacing.large,
  height: 40,
};

export const light: TextStyle = {
  ...base,
  ...Typography.body,
  backgroundColor: "whitesmoke",
  color: Colors.primary,
  borderRadius: Dimens.large,
  borderBottomRightRadius: 0,
  paddingTop: Spacing.small,
  paddingBottom: Spacing.small,
  paddingRight: Spacing.large,
  paddingLeft: Spacing.large,
  height: 40,
};
