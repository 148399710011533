import isEmpty from "lodash/isEmpty";

import {
  GetSiteRequest,
  GetSiteResponse,
  CreateGroupRequest,
  CreateSiteResponse,
} from "poola-commons/types";

import { post } from "services/api";
import { initializeApp } from "services/firebase/firebase";
import { logInWithLink, logOut } from "..";
import { setStoredSite } from "./storage";

const getSite = (request: GetSiteRequest): Promise<GetSiteResponse> => {
  return post("/site", request, {}, false);
};

const createSelfSignup = (request: {
  email: string;
  groupId: string;
  emailLink: string;
}) => {
  // @ts-ignore
  initializeApp(undefined, undefined);
  return logInWithLink(request)
    .then(() =>
      post("/users-new/self-signup/create", { groupId: request.groupId })
    )
    .then(() => logOut());
};

const createSite = (
  request: CreateGroupRequest
): Promise<CreateSiteResponse> => {
  return post("/group/create", request);
};

const reQuerySite = (path: string) => {
  return getSite({ path }).then((site) => {
    if (isEmpty(site)) {
      return Promise.reject();
    } else {
      setStoredSite(site);
      return site;
    }
  });
};

export { getSite, createSite, createSelfSignup, reQuerySite };
