import { FC } from "react";

import isString from "lodash/isString";

import MuiTooltip, {
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

export type TooltipProps = MuiTooltipProps;

export const Tooltip: FC<MuiTooltipProps> = ({
  children,
  title,
  ...tooltipProps
}) => {
  return (
    <StyledTooltip
      title={
        isString(title) ? (
          <TooltipContent>
            <Box padding={2}>{title}</Box>
          </TooltipContent>
        ) : (
          <TooltipContent>{title}</TooltipContent>
        )
      }
      {...tooltipProps}
    >
      {children}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "unset",
    borderRadius: "unset",
    padding: 0,
    margin: `0 !important`,
  },
}));

const TooltipContent = styled("div")(({ theme }) => ({
  backgroundImage: theme.palette.background.default,
}));
