import { combineLatest } from "rxjs";
import { map as rxMap } from "rxjs/operators";

import uniqBy from "lodash/uniqBy";
import sum from "lodash/sum";

import { RideWithJoiners } from "poola-commons";
import { getChannel } from "poola-commons/utils";

import { multicastUnread$ } from "services/messages/service";
import { useObservable } from "modules/hooks/useSubscription";

export const useGetMessagesFromRides = (
  rides: RideWithJoiners[],
  uid?: string
) => {
  return useObservable(
    combineLatest(
      uniqBy(rides, getChannel).map((ride) =>
        multicastUnread$(getChannel(ride), uid ?? ride.uid)
      )
    ).pipe(rxMap(sum)),
    rides
  );
};
