export const error = "rgb(239, 83, 80)";

export const success = "green";

export const primary = "rgb(42, 69, 84)"; // dont change rgb color model (we use it in NoAuthLayout -> statusBar)

export const primaryLight = "rgba(42, 69, 84, 0.7)";

export const primaryLight40 = "rgba(128, 128, 128, 0.4)";

export const primary10 = "rgba(42, 69, 84, 0.1)";

export const secondary = "rgb(133, 230, 123)";

export const secondary40 = "rgba(133, 230, 123, 0.4)";

export const tertiary = "rgb(0, 195, 255)";

export const tertiary40 = "rgba(0, 195, 255, 0.4)";

export const quaternary = "rgb(75, 136, 255)";

export const quaternary60 = "rgba(75, 136, 255, 0.6)";

export const quaternary20 = "rgba(75, 136, 255, 0.2)";

export const accent = "rgb(0, 180, 150)";

export const accent60 = "rgba(30, 180, 150, 0.6)";

export const accent20 = "rgba(30, 180, 150, 0.2)";

export const accentDangerous = "red";

export const disabled = "rgb(42, 69, 84)";

export const disabledBackground = "lightgray";

export const divider = "#ededed";

export const selection = "#f5f5f5";

export const primaryNegative = "rgb(250, 250, 250)";

export const primaryNegative85 = "rgba(250, 250, 250, 0.85)";

export const background = "#f5f5f5"; // dont change hex color model (we use it in NoAuthLayout -> statusBar)

export const white = "rgb(255, 255, 255)";

export const white25 = "rgba(255, 255, 255, 0.25)";

export const black25 = "rgba(0, 0, 0, 0.25)";

export const black = "rgb(0, 0, 0)";

export const routeAlternatives = [
  "rgba(179, 204, 255, 0.8)",
  "rgba(128, 171, 255, 0.8)",
  "rgba(75, 136, 255, 0.8)",
  "rgba(26, 103, 255, 0.8)",
  "rgba(0, 78, 230, 0.8)",
];
