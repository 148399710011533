import { styled } from "@mui/material/styles";

import { Colors, Typography } from "modules/styles";

export const OrderIcon = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ active }) => ({
  width: 12,
  height: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  fontSize: Typography.sizeExtraSmall,
  color: active ? Colors.accent : Colors.primaryLight40,
  border: `1px solid ${active ? Colors.accent : Colors.primaryLight40}`
}));
