import firebase from "firebase";

import { FirebaseUser } from "d";
import { translate } from 'i18n';

const toDashErrorKey = (str: string): string =>
  str.replace(/\s+/g, '-').toLowerCase();

export const mapErrorToMessage = (error: any): string => {
  console.log({error})
  if (typeof error === 'string') {
    return error;
  }

  if (error.code) {
    return translate(`error.${error.code}`);
  }

  if (error.message) {
    return translate(
      `error.${toDashErrorKey(error.message)}`,
      {
        error: error.message,
        defaults: [{ scope: 'error.unknown' }]
      }
    );
  }

  return translate('error.unknown', { error });
};

export const mapFirebaseUserToUser = (user: firebase.User): FirebaseUser => ({
  name: user.displayName || 'Unknown name',
  email: user.email || 'unknown@email.com',
  uid: user.uid
});
