import { ComponentType, useContext } from "react";
import { Redirect } from "react-router-dom";

import { UserContext, assertAuthenticated } from "modules/context";
import { AuthenticatedUser } from "d";
import { routesMap } from "views/routesMap";

export type WithAuthenticatedUserProps = AuthenticatedUser;

const withAuthenticatedUser =
  <P extends object>(
    Component: ComponentType<P & WithAuthenticatedUserProps>
  ) =>
  (props: P) => {
    const { user } = useContext(UserContext);

    if (user == undefined) {
      return <Redirect to={routesMap.LOGIN} />;
    }

    if (!user.currentGroup) {
      return <Redirect to={routesMap.SITE_NAME} />;
    }

    if (user.currentGroup.habits == undefined) {
      return <Redirect to={routesMap.ONBOARDING} />;
    }

    const newProps: P & WithAuthenticatedUserProps = {
      ...props,
      ...assertAuthenticated(user),
    };

    return <Component {...newProps} />;
  };

const withRenderIfAuthenticated =
  <P extends object>(
    Component: ComponentType<P & WithAuthenticatedUserProps>
  ) =>
  (props: P) => {
    const { user } = useContext(UserContext);

    if (user?.currentGroup?.habits !== undefined) {
      const newProps: P & WithAuthenticatedUserProps = {
        ...props,
        ...assertAuthenticated(user),
      };

      return <Component {...newProps} />;
    }
    return null;
  };

export { withAuthenticatedUser, withRenderIfAuthenticated };
