import React, { FC, PropsWithChildren, useContext } from "react";

import { is } from "poola-commons/utils";

import { Auth, User, AuthenticatedUser } from "d";
import {
  getGroupFromStorageOrDefault,
  toUserMeta,
  useGetUserGroupSettings,
  useMe,
} from "services/auth";
import { useAppPushNotifications } from "services/notifications/useAppPushNotifications";
import { useGroupId } from "./group";

const UserContext = React.createContext<Auth>({
  user: undefined,
  authenticated: false,
  isIndeterminate: true,
});

const UserContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ data: dbUser }, isIndeterminate] = useMe();

  const groupId = useGroupId();
  const currentGroup = getGroupFromStorageOrDefault(groupId, dbUser?.groups);

  const { data: dbUserGroupSettings } = useGetUserGroupSettings({
    groupId: currentGroup?.id,
    uid: dbUser?.uid,
  });

  const user =
    dbUser && dbUserGroupSettings && toUserMeta(dbUser, dbUserGroupSettings);

  // TODO: this should not be in the user context, move it from here in a future PR
  useAppPushNotifications(user?.firebaseUser?.uid);

  return (
    <UserContext.Provider
      value={{ user, authenticated: !!user, isIndeterminate }}
    >
      {children}
    </UserContext.Provider>
  );
};

// TODO: Need to find a better name. Help needed from the reviewer
const assertAuthenticated = (user?: User): AuthenticatedUser => {
  if (user && is(user.userMeta) && is(user.currentGroup) && is(user.groups)) {
    return user as AuthenticatedUser;
  } else {
    throw new Error("Fully authenticated user with groups is expected");
  }
};

const useAuthenticatedUser = (): AuthenticatedUser => {
  const { user, authenticated } = useContext(UserContext);

  if (authenticated) {
    return user as AuthenticatedUser;
  }

  throw new Error(
    "useAuthenticatedUser must be used for authenticated user only"
  );
};

export { assertAuthenticated, useAuthenticatedUser, UserContextProvider };

export default UserContext;
