import React, { CSSProperties, FunctionComponent } from "react";

import { makeStyles } from "@mui/styles";

import { Colors, Typography } from "modules/styles";
import { View } from "./View";
import { Text } from "./Text";

type Props = {
  name: string;
  size: number;
  style?: CSSProperties;
  textStyle?: CSSProperties;
};

const NameCircle: FunctionComponent<Props> = ({
  name,
  size,
  style,
  textStyle,
}) => {
  const styles = useStyles();
  return (
    <View
      className={styles.container}
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        ...style,
      }}
    >
      <Text
        className={styles.text}
        style={{ fontSize: size / 2, ...textStyle }}
      >
        {name.substr(0, 1)}
      </Text>
    </View>
  );
};

const useStyles = makeStyles({
  container: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white25,
  },
  text: {
    ...Typography.header,
    fontSize: Typography.sizeLarge,
    color: Colors.primaryLight,
  },
});

export { NameCircle };
