import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { WatcherOptions } from "@capacitor-community/background-geolocation";

import { Observable, of, timer } from "rxjs";
import {
  catchError,
  map,
  mergeMapTo,
  share,
  startWith,
  throttleTime,
} from "rxjs/operators";

import type {
  Coordinate,
  RealtimeLocation,
  RealtimeLocationWithoutTime,
} from "poola-commons/types";
import { millisPerMinute, millisPerSecond, now } from "poola-commons/utils";

import {
  addWatcher,
  removeWatcher,
  type Location,
} from "capacitor/BackgroundGeolocation";
import { minutesToMillis, secondsToMillis } from "modules/date";

// TODO: Copy-paste from https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
enum PositionError {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
  POOLA_ARBITRARY_INITIAL_TIMEOUT = 4,
}

const RETRY_DELAY_SECONDS = 3;
const THROTTLE_LOCATION_SECONDS = 5;
const WAIT_FOR_FIRST_EMISSION_SECONDS = 20;

const getRetryDelay = (error: PositionError): number | undefined => {
  if (
    error === PositionError.POSITION_UNAVAILABLE ||
    error === PositionError.TIMEOUT
  ) {
    return secondsToMillis(RETRY_DELAY_SECONDS);
  } else if (error === PositionError.POOLA_ARBITRARY_INITIAL_TIMEOUT) {
    return 0;
  } else {
    return undefined;
  }
};
export const watchPosition$ = (
  options?: Pick<WatcherOptions, "backgroundMessage" | "backgroundTitle"> & {
    permissionsPrompt?: string;
  }
): Observable<{
  location?: RealtimeLocationWithoutTime;
  error?: unknown;
}> =>
  new Observable<RealtimeLocationWithoutTime>((observer) => {
    const noInitialUpdateHandler = setTimeout(() => {
      observer.error(PositionError.POOLA_ARBITRARY_INITIAL_TIMEOUT);
    }, WAIT_FOR_FIRST_EMISSION_SECONDS * millisPerSecond);
    if (Capacitor.isNativePlatform()) {
      const watcherId = addWatcher(
        (position) => {
          clearTimeout(noInitialUpdateHandler);
          observer.next(nativeToRealtimeLocation(position));
        },
        (e) => {
          clearTimeout(noInitialUpdateHandler);
          observer.error(e);
        },
        options
      );
      return () => {
        clearTimeout(noInitialUpdateHandler);
        removeWatcher(watcherId);
      };
    } else if (navigator.geolocation) {
      const watcherId = navigator.geolocation.watchPosition(
        (position) => observer.next(webToRealtimeLocation(position)),
        (e) => observer.error(e.code),
        {
          timeout: minutesToMillis(1),
          maximumAge: secondsToMillis(10),
          enableHighAccuracy: true,
        }
      );
      return () => navigator.geolocation.clearWatch(watcherId);
    } else {
      observer.error("Geolocation not supported");
    }
  }).pipe(
    throttleTime(secondsToMillis(THROTTLE_LOCATION_SECONDS)),
    map((location) => ({ location })),
    catchError((error, caught) => {
      const retryDelay = getRetryDelay(error);
      return retryDelay !== undefined
        ? timer(retryDelay).pipe(mergeMapTo(caught), startWith({ error }))
        : of({ error });
    }),
    share()
  );

export const getCurrentLocation = async (): Promise<Coordinate> => {
  const timeout = secondsToMillis(7);

  if (Capacitor.isNativePlatform()) {
    return Geolocation.getCurrentPosition().then(
      ({ coords: { latitude, longitude } }) => [latitude, longitude]
    );
  }
  if (navigator.geolocation) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve([latitude, longitude]);
        },
        (error) => {
          reject(error);
        },
        { timeout }
      )
    );
  }
  return Promise.reject();
};

const nativeToRealtimeLocation = ({
  latitude,
  longitude,
  bearing,
  accuracy,
}: Location): RealtimeLocationWithoutTime => ({
  location: [latitude, longitude],
  bearing: bearing ?? undefined,
  radius: accuracy ?? undefined,
});

const webToRealtimeLocation = ({
  coords: { latitude, longitude, heading, accuracy },
}: GeolocationPosition): RealtimeLocationWithoutTime => ({
  location: [latitude, longitude],
  bearing: heading ?? undefined,
  radius: accuracy ?? undefined,
});

export const isFreshLocation = (location: RealtimeLocation) => {
  return now() - location.timestamp < 20 * millisPerMinute;
};
