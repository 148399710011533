import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import TypographyMui from "@mui/material/Typography";
import CircularProgressMui from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { Colors, Typography, Spacing } from "modules/styles";
import { getStoredSite } from "services/auth/site";

import logo from "img/poola.png";

const AuthLoading: FunctionComponent<{ texts?: string[] }> = ({ texts }) => {
  const { t } = useTranslation(undefined, { keyPrefix: "view.auth.session" });
  const site = getStoredSite();

  return (
    <Container>
      <LogoContainer>
        <img
          src={site?.logoUrl || logo}
          style={{
            maxWidth: "80%",
            maxHeight: "70px",
          }}
        />
      </LogoContainer>
      <SpinnerContainer>
        <CircularProgressMui />
        {texts ? (
          texts.map((text) => <Header>{text}</Header>)
        ) : (
          <Header>{t("header")}</Header>
        )}
      </SpinnerContainer>
    </Container>
  );
};

const LogoContainer = styled("div")({
  display: "flex",
  flexGrow: 1,
  minWidth: 0,
  justifyContent: "center",
  alignItems: "center",
});

const SpinnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  minWidth: 0,
  alignItems: "center",
});

const Header = styled(TypographyMui)({
  ...Typography.body,
  fontSize: Typography.sizeMedium,
  color: Colors.accent,
  marginTop: Spacing.medium,
});

const Container = styled(Box)({
  width: "100vw",
  height: "100vh",
  position: "absolute",
  flex: 1,
  zIndex: 10000,
  flexDirection: "column",
  display: "flex",
  backgroundColor: Colors.background,
});

export default AuthLoading;
