import { Observable, from } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import firebase from "firebase";

import { Credentials, CustomTokenRequest } from "poola-commons/types";

export { isValidPassword } from "./passwordRequirements";
import { getFirebaseApp } from "services/firebase/firebase";
import { rethrowMappedError } from "services/rx";
import { authStateChanged$ } from "./user";
import { mapErrorToMessage } from "services/mappers";
import { authenticatePoolaStaff, getImpersonateToken } from "./service";

export * from "./user";

export type ImpersonateCredentials = Credentials & CustomTokenRequest;
export type ImpersonateLoginCredentials = Omit<
  ImpersonateCredentials,
  "tenantId"
>;

export const logInImpersonate = ({
  username,
  password,
  uid,
  tenantId,
}: ImpersonateCredentials) =>
  authenticatePoolaStaff({ username, password })
    .then(() => getImpersonateToken({ uid, tenantId }))
    .then(({ customToken }) =>
      getFirebaseApp().auth().signInWithCustomToken(customToken)
    )
    .then(() => {})
    .catch((error) => {
      throw mapErrorToMessage(error);
    });

export const logIn = ({ username, password }: Credentials): Promise<void> => {
  return getFirebaseApp()
    .auth()
    .signInWithEmailAndPassword(username, password)
    .then(() => {})
    .catch((error) => {
      throw mapErrorToMessage(error);
    });
};

export const logInWithLink = ({
  email,
  emailLink,
}: {
  email: string;
  emailLink: string;
}): Promise<void> => {
  return getFirebaseApp()
    .auth()
    .signInWithEmailLink(email, emailLink)
    .then(() => {})
    .catch((error) => {
      throw mapErrorToMessage(error);
    });
};

export const signUp = ({ username, password }: Credentials): Promise<void> => {
  const auth = getFirebaseApp().auth();
  return auth
    .createUserWithEmailAndPassword(username, password)
    .catch((error) => {
      throw mapErrorToMessage(error);
    })
    .then(() => logIn({ username, password }));
};

export const logOut = (): Observable<void> =>
  from(getFirebaseApp().auth().signOut());

export const changePassword = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}): Observable<void> => {
  return authStateChanged$.pipe(
    switchMap((user) => {
      if (user && user.email) {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          oldPassword
        );
        return from(
          user
            .reauthenticateWithCredential(credential)
            .then(() => user.updatePassword(newPassword))
        );
      }
      return Observable.throw(new Error("Not authenticated"));
    })
  );
};

export const resetPassword = (email: string): Observable<void> =>
  from(getFirebaseApp().auth().sendPasswordResetEmail(email)).pipe(
    catchError(rethrowMappedError)
  ); // TODO it would be awesome to handle deeplink in the app
